<template>
  <av-card class="card-thumbnail" :class="[`card-thumbnail-${size}`]">
    <completed-overlay v-if="isCompleted && !isExtraSmall" />
    <card-bookmark-btn
      v-if="!isExtraSmall"
      v-on="$listeners"
      :is-disabled="isActive"
      :title="title"
      :is-bookmarked="isBookmarked" />
    <slot name="thumbnail"></slot>
    <div class="card-content">
      <card-audiences
        v-if="!isExtraSmall"
        :audiences="audiences"
        :size="size" />
      <div
        :aria-label="`Title ${title}`"
        data-testid="card-thumbnail-title"
        class="card-title">
        {{ title }}
      </div>
      <div v-if="!isExtraSmall">
        <slot name="tasks"></slot>
      </div>
    </div>
    <div v-if="!isExtraSmall" class="card-footer">
      <slot name="footer"></slot>
    </div>
  </av-card>
</template>

<script>
import CardAudiences from './Audiences.vue';
import CardBookmarkBtn from './BookmarkBtn';
import CompletedOverlay from './CompletedOverlay';
import { mapState } from 'vuex';
import Size from '../size';
import useProgressTasks from '@/main/composables/useProgressTasks';

export default {
  name: 'card-thumbnail',
  props: {
    id: { type: Number, required: true },
    size: { type: String, required: true },
    progress: { type: Number, default: 0 },
    isBookmarked: { type: Boolean, default: false },
    audiences: { type: Array, required: true },
    title: { type: String, required: true },
    isActive: { type: Boolean, default: false }
  },
  setup({ id }) {
    const { isCompleted } = useProgressTasks(id);
    return { isCompleted };
  },
  computed: {
    ...mapState('profile', ['bookmarks']),
    isMobile: vm => !vm.$vuetify.breakpoint.mdAndUp,
    isExtraSmall: vm => vm.size === Size.XS,
    titleMaxLines: vm => (vm.size === Size.LG && !vm.isMobile ? 4 : 2)
  },
  components: {
    CardAudiences,
    CardBookmarkBtn,
    CompletedOverlay
  }
};
</script>

<style scoped lang="scss">
@import '~@/common/assets/stylesheets/utils/z-index';
@import '~@/common/assets/stylesheets/utils/line-clamp';

@mixin apply-font($font-size, $line-height, $font-weight: 600) {
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

.card-thumbnail {
  color: var(--v-white-base);

  .completed-overlay {
    position: absolute;
    inset: 0;
    z-index: z-index(content) - 1;
  }

  .bookmark-btn {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
  }

  .card-content {
    @include apply-font(0.75rem, 0.875rem);

    position: absolute;
    inset: 0;
    padding: 1.3rem 0 0 1.3rem;

    .card-title {
      @include apply-font(1rem, 1.125rem);
      @include line-clamp(v-bind(titleMaxLines), 50%);

      margin-bottom: 0.5rem;
    }
  }

  .card-footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &-xs {
    height: 4.625rem;

    :deep(.thumbnail) {
      height: 100%;
    }

    .card-content {
      padding: 1rem 0.5rem 0.375rem;

      .card-title {
        @include apply-font(0.4rem, 0.5rem);
      }
    }
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    .card-content {
      @include apply-font(0.75rem, 0.875rem, 400);

      .card-title {
        @include apply-font(1rem, 1.125rem);
      }
    }

    &-lg {
      .card-content {
        @include apply-font(1rem, 1.125rem, 400);

        .card-title {
          @include apply-font(1.75rem, 1.875rem);
        }
      }
    }

    &-md {
      .card-content {
        @include apply-font(1rem, 1.125rem, 400);

        .card-title {
          @include apply-font(1.375rem, 1.625rem);
        }
      }
    }
  }
}
</style>
