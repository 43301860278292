<template>
  <primary-logo
    :width="mdAndUpBreakpoint ? 160 : 120"
    role="img"
    title="Avanti logo"
    color="white" />
</template>

<script>
export default {
  name: 'navbar-logo',
  computed: {
    mdAndUpBreakpoint: vm => vm.$vuetify.breakpoint.mdAndUp
  }
};
</script>
