<template>
  <div>
    <upgrade-dialog
      v-if="isDemoUser"
      v-model="isDialogActive"
      @close="toggleDialog"
      variant="livestreams" />
    <featured-livestream
      @toggle-dialog="toggleDialog"
      :livestream="featuredItem"
      :loading="loading" />
    <upcoming-livestreams v-if="upcomingItems.length" :items="upcomingItems" />
    <on-demand-recordings
      v-if="pastLivestreams.length"
      :items="pastLivestreams" />
  </div>
</template>

<script>
import { isFuture, isPast } from 'date-fns';
import { mapActions, mapGetters } from 'vuex';
import FeaturedLivestream from './Featured';
import OnDemandRecordings from './OnDemandRecordings.vue';
import { ResourceType } from '@/main/models/catalog-item';
import UpcomingLivestreams from './Upcoming';

export default {
  name: 'livestream-dashboard',
  data: () => ({
    livestreams: [],
    loading: true,
    isDialogActive: false
  }),
  computed: {
    ...mapGetters('auth', ['isDemoUser']),
    notEndedLivestreams: ({ livestreams }) =>
      livestreams
        .filter(it => isFuture(it.endDate))
        .sort((a, b) => a.startDate - b.startDate),
    pastLivestreams: ({ livestreams }) =>
      livestreams
        .filter(it => isPast(it.endDate))
        .sort((a, b) => b.startDate - a.startDate),
    featuredItem() {
      const { livestreams, notEndedLivestreams, pastLivestreams } = this;
      if (!livestreams.length) return;
      return notEndedLivestreams[0] || pastLivestreams[0];
    },
    upcomingItems: ({ notEndedLivestreams }) => notEndedLivestreams.slice(1)
  },
  methods: {
    ...mapActions('catalogItems', { fetchLivestreams: 'fetch' }),
    fetch() {
      const params = {
        type: { eq: ResourceType.LIVESTREAM }
      };
      return this.fetchLivestreams({ params })
        .then(({ items }) => items)
        .finally(() => {
          this.loading = false;
        });
    },
    toggleDialog() {
      this.isDialogActive = !this.isDialogActive;
    }
  },
  async created() {
    this.livestreams = await this.fetch();
  },
  components: {
    FeaturedLivestream,
    UpcomingLivestreams,
    OnDemandRecordings
  }
};
</script>
