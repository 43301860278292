<template>
  <base-navbar>
    <div class="preview-navbar">
      <navbar-logo />
      <av-btn
        :href="authoringLearningObjectLink"
        :icon="smAndDownBreakpoint"
        aria-label="Back to edit"
        variant="text"
        color="white">
        <av-icon size="22" name="arrow-left" icon-style="solid" />
        <span v-if="!smAndDownBreakpoint" class="ml-2 text-capitalize">
          Back to Edit
        </span>
      </av-btn>
    </div>
  </base-navbar>
</template>

<script>
import BaseNavbar from './Base';
import NavbarLogo from './Logo';

export default {
  name: 'navbar-learning-object-preview',
  computed: {
    smAndDownBreakpoint: vm => vm.$vuetify.breakpoint.smAndDown,
    isLearningObjectPreviewPage: vm =>
      vm.$route.name === 'learningObjectPreview',
    authoringLearningObjectLink() {
      const { origin } = window.location;
      const { id } = this.$route.params;
      return new URL(`admin/learning-objects/${id}`, origin).href;
    }
  },
  components: { BaseNavbar, NavbarLogo }
};
</script>

<style scoped>
.preview-navbar {
  display: flex;
  justify-content: space-between;
}
</style>
