import { isAfter, isBefore } from 'date-fns';

export function isLive(startDate, endDate, now = new Date()) {
  return isBefore(startDate, now) && isAfter(endDate, now);
}

export function isStartingSoon(startDate, now = new Date()) {
  const oneHourInMiliseconds = 60 * 60 * 1000;
  if (isBefore(startDate, now)) return false;
  return Math.abs(startDate - now) < oneHourInMiliseconds;
}

export function isUpcoming(startDate, now = new Date()) {
  return isAfter(startDate, now);
}

export function hasEnded(endDate, now = new Date()) {
  return isBefore(endDate, now);
}

export function parseLivestreams(livestreams) {
  return livestreams.map(parseLivestream);
}

export function parseLivestream(livestream) {
  const { createdAt, startDate, endDate, ...data } = livestream;
  return {
    startDate: new Date(startDate),
    endDate: new Date(endDate),
    ...data
  };
}
