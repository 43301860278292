export const ThumbnailColor = {
  DARK_LIGHT_BLUE: 'dark-light-blue',
  PURPLE_BLUE: 'purple-blue',
  GREEN_BLUE: 'green-blue',
  CYAN_BLUE: 'cyan-blue',
  GREY_BLUE: 'grey-blue'
};

export const validateThumbnailColor = color => {
  return Object.values(ThumbnailColor).includes(color);
};
