<template>
  <section>
    <h3 :class="[isMobile ? 'text-h5' : 'text-body-bold', 'mb-3 px-4 px-md-8']">
      Live & Upcoming
    </h3>
    <av-swiper :items="items" class="pb-2 pb-md-0">
      <template #default="{ item }">
        <livestream-card :livestream="item" />
      </template>
    </av-swiper>
  </section>
</template>

<script>
import AvSwiper from '@/common/components/base/Swiper';
import LivestreamCard from '@/main/components/common/cards/LivestreamCard';

export default {
  name: 'upcoming-livestreams',
  props: {
    items: { type: Array, default: () => [] }
  },
  computed: {
    isMobile: vm => !vm.$vuetify.breakpoint.mdAndUp
  },
  components: { AvSwiper, LivestreamCard }
};
</script>

<style lang="scss" scoped>
:deep(.av-swiper) .slide {
  &:first-child {
    margin-left: 1rem;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      margin-left: 2rem;
    }
  }

  &:last-child {
    margin-right: 1rem;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      margin-right: 2rem;
    }
  }
}
</style>
