<template>
  <svg
    width="32"
    height="32"
    viewBox="-2 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Union">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0346 2H24.234C24.7863 2 25.234 2.44771 25.234 3V23.7548H2V3C2 2.44772 2.44772 2 3 2H6.66539V18.5825C6.66539 18.9784 6.899 19.3371 7.26116 19.4971C7.62331 19.6572 8.04579 19.5885 8.33858 19.322L11.35 16.5805L14.3614 19.322C14.6542 19.5885 15.0767 19.6572 15.4388 19.4971C15.801 19.3371 16.0346 18.9784 16.0346 18.5825V2ZM2 25.7548V29C2 29.5523 2.44771 30 3 30H24.234C24.7863 30 25.234 29.5523 25.234 29V25.7548H2ZM0 3C0 1.34315 1.34315 0 3 0H24.234C25.8909 0 27.234 1.34315 27.234 3V29C27.234 30.6569 25.8909 32 24.234 32H3C1.34314 32 0 30.6569 0 29V3ZM8.66539 2.811V16.3198L10.6768 14.4887C11.0584 14.1414 11.6416 14.1414 12.0232 14.4887L14.0346 16.3198V2.811H8.66539Z" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0346 2H24.234C24.7863 2 25.234 2.44771 25.234 3V23.7548H2V3C2 2.44772 2.44772 2 3 2H6.66539V18.5825C6.66539 18.9784 6.899 19.3371 7.26116 19.4971C7.62331 19.6572 8.04579 19.5885 8.33858 19.322L11.35 16.5805L14.3614 19.322C14.6542 19.5885 15.0767 19.6572 15.4388 19.4971C15.801 19.3371 16.0346 18.9784 16.0346 18.5825V2ZM2 25.7548V29C2 29.5523 2.44771 30 3 30H24.234C24.7863 30 25.234 29.5523 25.234 29V25.7548H2ZM0 3C0 1.34315 1.34315 0 3 0H24.234C25.8909 0 27.234 1.34315 27.234 3V29C27.234 30.6569 25.8909 32 24.234 32H3C1.34314 32 0 30.6569 0 29V3ZM8.66539 2.811V16.3198L10.6768 14.4887C11.0584 14.1414 11.6416 14.1414 12.0232 14.4887L14.0346 16.3198V2.811H8.66539Z"
        fill="url(#paint0_linear_4267_8081)"
        style="mix-blend-mode: overlay" />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_4267_8081"
        x1="6.53617"
        y1="10.24"
        x2="22.9878"
        y2="20.8888"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#C27AEE" />
        <stop offset="1" stop-color="#63D5EA" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'av-saved-gradient-icon'
};
</script>
