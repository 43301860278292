<template>
  <div class="live-now-label d-flex align-center px-2 py-1 error">
    <av-icon name="livestream" color="white" size="22" />
    <span
      :class="isMobile ? 'text-pill' : 'text-h5'"
      class="label ml-2 white--text text-uppercase">
      Live Now
    </span>
  </div>
</template>

<script>
export default {
  name: 'live-now-label',
  computed: {
    isMobile: vm => vm.$vuetify.breakpoint.smAndDown
  }
};
</script>

<style lang="scss" scoped>
.live-now-label {
  width: fit-content;
  border-radius: 3px;

  .label {
    white-space: nowrap;
  }
}
</style>
