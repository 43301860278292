<template>
  <v-btn
    v-on="$listeners"
    v-bind="attributes"
    :class="`av-btn--${variant}`"
    rounded
    depressed
    class="av-btn">
    <av-icon v-if="prependIcon" v-bind="iconOptions.prepend" />
    <slot></slot>
    <av-icon v-if="appendIcon" v-bind="iconOptions.append" />
  </v-btn>
</template>

<script>
import { Color, Size, Variant } from './config';
import find from 'lodash/find';
import pick from 'lodash/pick';
import { VBtn } from 'vuetify/lib';

const validate = (val, allowedMap) => Object.values(allowedMap).includes(val);

export default {
  name: 'av-btn',
  props: {
    variant: {
      type: String,
      default: Variant.PRIMARY,
      validator: value => validate(value, Variant)
    },
    color: {
      type: String,
      default: Color.PRIMARY,
      validator: value => validate(value, Color)
    },
    size: {
      type: String,
      default: Size.LARGE,
      validator: value => validate(value, Size)
    },
    icon: { type: Boolean, default: false },
    iconSize: { type: String, default: '14' },
    appendIcon: { type: String, default: null },
    prependIcon: { type: String, default: null },
    appendIconStyle: { type: String, default: 'solid' },
    prependIconStyle: { type: String, default: 'solid' }
  },
  computed: {
    attributes() {
      const { icon, color, variant, internalSize, $attrs } = this;
      const attrs = pick($attrs, [
        'to',
        'block',
        'disabled',
        'href',
        'target',
        'loading'
      ]);
      const outlined = variant === Variant.SECONDARY;
      const text = variant === Variant.TEXT;
      const dark = color !== Color.WHITE && !attrs.disabled;
      return { ...attrs, ...internalSize, outlined, text, icon, color, dark };
    },
    internalSize() {
      const size = find(Size, it => it === this.size);
      if (!size || size === Size.MEDIUM) return;
      return { [size]: true };
    },
    internalIconSize() {
      if (this.size === Size.X_SMALL || this.size === Size.SMALL) return 10;
      return this.iconSize;
    },
    iconOptions: vm => ({
      prepend: {
        name: vm.prependIcon,
        iconStyle: vm.prependIconStyle,
        size: vm.internalIconSize,
        left: true
      },
      append: {
        name: vm.appendIcon,
        iconStyle: vm.appendIconStyle,
        size: vm.internalIconSize,
        right: true
      }
    })
  },
  components: { VBtn }
};
</script>

<style lang="scss" scoped>
$sizes: (
  'x-small': (
    'font-size': 0.75rem,
    'line-height': 0.875rem
  ),
  'small': (
    'font-size': 0.875rem,
    'line-height': 1rem
  ),
  'default': (
    'font-size': 1rem,
    'line-height': 1.1875rem
  ),
  'large': (
    'font-size': 1rem,
    'line-height': 1.1875rem
  ),
  'x-large': (
    'font-size': 1.125rem,
    'line-height': 1.3125rem
  )
);

.av-btn {
  border-radius: 100px;

  &:not(.v-btn--icon) {
    width: fit-content !important;
  }

  :deep(.v-btn__content) {
    font-weight: bold;
    letter-spacing: 0;
    text-transform: none;
  }

  @each $name, $size in $sizes {
    &.v-size--#{$name} {
      font-size: map-get($size, 'font-size');
      line-height: map-get($size, 'line-height');
    }
  }

  &--secondary {
    border-width: 2px;
  }

  &--link.av-btn {
    height: 1rem;
    padding: 0;
    border-radius: 0;
    background-color: transparent !important;

    :deep(.v-btn__content) {
      color: var(--v-primary-lighten2);
      font-size: 0.875rem;
      font-weight: 400;
    }

    &:focus-visible {
      outline: $av-focus-outline;
    }
  }

  &--primary.white :deep(.v-btn__content) {
    color: var(--v-primary-base);
  }
}
</style>
