<template>
  <v-sheet
    v-if="isBoxVariant"
    :color="boxColor"
    width="76"
    height="76"
    class="av-icon-box d-flex justify-center align-center">
    <v-icon v-bind="attributes" class="av-icon">{{ iconKey }}</v-icon>
  </v-sheet>
  <v-icon v-else v-bind="attributes" class="av-icon">{{ iconKey }}</v-icon>
</template>

<script>
import { VIcon, VSheet } from 'vuetify/lib';
import pick from 'lodash/pick';
import { toRef } from 'vue';
import useFontawesomeIcon from '@/common/composables/useFontawesomeIcon';

const Variant = {
  REGULAR: 'regular',
  BOX: 'box'
};

const variantValidator = val => Object.values(Variant).includes(val);

export default {
  name: 'av-icon',
  inheritAttrs: false,
  props: {
    name: { type: String, required: true },
    iconStyle: { type: String, default: null },
    variant: {
      type: String,
      default: Variant.REGULAR,
      validator: variantValidator
    },
    boxColor: { type: String, default: 'primary lighten-5' }
  },
  setup(props) {
    const iconStyle = toRef(props, 'iconStyle');
    const { faStyleKey } = useFontawesomeIcon(null, iconStyle);
    return { faStyleKey };
  },
  computed: {
    isBoxVariant: vm => vm.variant === Variant.BOX,
    attributes: vm => pick(vm.$attrs, ['color', 'size', 'right', 'left']),
    iconKey() {
      const key = `av-${[this.name, this.iconStyle]
        .filter(Boolean)
        .join('-')}-icon`;
      const icon = this.$vuetify.icons.values[key];
      if (icon) return `$${key}`;
      if (!this.faStyleKey) return `$vuetify.icons.${this.name}`;
      return `$vuetify.icons.${this.faStyleKey}-${this.name}`;
    }
  },
  methods: { pick },
  components: { VIcon, VSheet }
};
</script>

<style lang="scss" scoped>
.av-icon-box {
  border-radius: $border-radius-xs;
}
</style>
