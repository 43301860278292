<template>
  <div :class="{ 'av-aspect-ratio': !dropAspectRatio }" class="thumbnail">
    <slot name="content"></slot>
    <div
      :class="[color, { colorize: colorizePerson }]"
      class="person-container">
      <v-lazy :options="observerOptions">
        <av-img
          v-if="imgSrc"
          :src="imgSrc"
          :transition="internalTransition"
          position="bottom"
          alt="Learning object thumbnail"
          contain
          class="person" />
      </v-lazy>
    </div>
  </div>
</template>

<script>
import {
  ThumbnailColor,
  validateThumbnailColor
} from '@/common/models/learning-object';
import AvImg from '@/common/components/base/Img';
import { VLazy } from 'vuetify/lib';

export default {
  name: 'learning-object-thumbnail',
  props: {
    imgSrc: { type: String, default: '' },
    transition: { type: Boolean, default: true },
    dropAspectRatio: { type: Boolean, default: false },
    colorizePerson: { type: Boolean, default: false },
    color: {
      type: String,
      default: ThumbnailColor.DARK_LIGHT_BLUE,
      validator: validateThumbnailColor
    }
  },
  data: () => ({
    observerOptions: { rootMargin: '250px' }
  }),
  computed: {
    internalTransition: vm => vm.transition && 'fade-transition'
  },
  components: { AvImg, VLazy }
};
</script>

<style lang="scss" scoped>
@import '~@/common/assets/stylesheets/thumbnail-gradients';
@import '~@/common/assets/stylesheets/utils/z-index';

$background-image: 'https://avanti-storage-prod-20240822143136704900000001.s3.amazonaws.com/thumbnails/avanti-background.png';

@mixin position-absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.thumbnail {
  position: relative;
  width: 100%;
  background-image: url($background-image);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  @extend %thumbnail-gradients-background;

  &.av-aspect-ratio {
    aspect-ratio: $av-aspect-ratio;
  }

  &:not(.av-aspect-ratio) {
    min-height: 10.5rem;
  }

  .person-container {
    @include position-absolute;

    overflow: hidden;

    &::before {
      content: '';
      opacity: 0.6;

      @include position-absolute;
    }

    &.colorize::before {
      z-index: z-index(content) - 1;
    }

    .person {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 60%;
      height: 90%;
    }
  }
}
</style>
