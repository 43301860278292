import mapValues from 'lodash/mapValues';
import { PROGRESS_COMPLETION_THRESHOLD } from '@/common/models/progress';

// TODO: discuss about changing the progress DTO to not include isVideoPlayed
// or removing the progress controller and doing it through interactions
export const progressTasks = state => {
  return mapValues(state.progress, tasks => ({
    areReproduciblesDownloaded: tasks?.areReproduciblesDownloaded,
    isCatalogItemRated: tasks?.isCatalogItemRated,
    isRubricScoreSaved: tasks?.isRubricScoreSaved,
    isStrategyApplied: tasks?.isStrategyApplied,
    isVideoCompleted: tasks?.videoProgress >= PROGRESS_COMPLETION_THRESHOLD
  }));
};
