<template>
  <learning-object-preview-navbar v-if="isLearningObjectPreviewPage" />
  <base-navbar v-else>
    <div class="main-navbar">
      <div v-show="!showFullWidthSearch" class="main-navbar--left">
        <v-app-bar-nav-icon
          @click.native="$emit('update:isSidebarVisible', !isSidebarVisible)"
          aria-label="Sidebar - menu">
          <av-icon v-bind="sidebarIcon" />
        </v-app-bar-nav-icon>
        <router-link
          :to="{ name: 'dashboard' }"
          aria-label="Navigate to homepage"
          data-testid="home"
          class="d-flex">
          <navbar-logo />
        </router-link>
      </div>
      <div class="main-navbar--center">
        <search-bar
          v-show="!xsBreakpoint || showFullWidthSearch"
          ref="searchBar"
          @click:append="closeSearch"
          :append-icon="searchIcon"
          append-icon-style="solid"
          class="search-bar" />
      </div>
      <div v-show="!showFullWidthSearch" class="main-navbar--right">
        <av-btn
          v-if="xsBreakpoint"
          @click="openSearch"
          variant="text"
          color="white"
          size="medium"
          aria-label="Toggle search"
          icon>
          <av-icon name="magnifying-glass" icon-style="solid" />
        </av-btn>
        <navbar-menu />
      </div>
    </div>
  </base-navbar>
</template>

<script>
import BaseNavbar from './Base';
import LearningObjectPreviewNavbar from './LearningObjectPreview';
import NavbarLogo from './Logo';
import NavbarMenu from './Menu';
import SearchBar from '../Search';
import { VAppBarNavIcon } from 'vuetify/lib';

export default {
  name: 'main-navbar',
  props: {
    isSidebarVisible: { type: Boolean, default: false }
  },
  data: () => ({ showSearch: false }),
  computed: {
    xsBreakpoint: vm => vm.$vuetify.breakpoint.xs,
    isLearningObjectPreviewPage: vm =>
      vm.$route.name === 'learningObjectPreview',
    showFullWidthSearch: vm => vm.xsBreakpoint && vm.showSearch,
    searchIcon: vm => (vm.showFullWidthSearch ? 'xmark' : 'magnifying-glass'),
    sidebarIcon() {
      return this.isSidebarVisible
        ? { name: 'close' }
        : { name: 'bars', iconStyle: 'solid' };
    }
  },
  methods: {
    closeSearch() {
      if (this.showFullWidthSearch) this.showSearch = false;
    },
    openSearch() {
      this.showSearch = true;
      this.$nextTick().then(() => this.$refs.searchBar.focus());
    }
  },
  components: {
    BaseNavbar,
    LearningObjectPreviewNavbar,
    NavbarLogo,
    NavbarMenu,
    SearchBar,
    VAppBarNavIcon
  }
};
</script>

<style lang="scss" scoped>
.main-navbar {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 'left center right';
  column-gap: 0.5rem;

  & &--left {
    grid-area: left;
    display: flex;
  }

  & &--center {
    grid-area: center;
    display: flex;
    justify-self: center;
  }

  & &--right {
    grid-area: right;
    display: flex;
    justify-self: end;
  }

  .search-bar {
    width: 20.3125rem;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      width: 26.75rem;
    }
  }
}
</style>
