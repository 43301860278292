import audiences from './modules/audiences';
import auth from '@/common/store/modules/auth';
import catalogItems from './modules/catalog-items';
import featureBanner from './modules/feature-banner';
import learnerProgress from './modules/learner-progress';
import nasot from './modules/nasot';
import payment from './modules/payment';
import profile from './modules/profile';
import request from '@/common/api/request';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const isProduction = process.env.NODE_ENV === 'production';

const store = new Vuex.Store({
  modules: {
    auth,
    catalogItems,
    nasot,
    audiences,
    payment,
    profile,
    learnerProgress,
    featureBanner
  },
  strict: !isProduction
});

request.auth.on('error', () => store.dispatch('auth/logout'));

export default store;
