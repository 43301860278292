<template>
  <svg
    width="75"
    height="75"
    viewBox="0 0 75 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 19.3257C15 16.5913 17.1875 14.3257 20 14.3257H50C52.7344 14.3257 55 16.5913 55 19.3257V44.3257C55 47.1382 52.7344 49.3257 50 49.3257H20C17.1875 49.3257 15 47.1382 15 44.3257V19.3257ZM20 19.3257V24.3257H25V19.3257H20ZM50 19.3257H30V24.3257H50V19.3257ZM20 29.3257V34.3257H25V29.3257H20ZM50 29.3257H30V34.3257H50V29.3257ZM20 39.3257V44.3257H25V39.3257H20ZM50 39.3257H30V44.3257H50V39.3257Z"
      fill="white" />
    <rect
      x="1.5"
      y="2.32568"
      width="67"
      height="59"
      rx="12.5"
      stroke="white"
      stroke-width="3" />
  </svg>
</template>

<script>
export default {
  name: 'av-playlist-icon'
};
</script>
