<template>
  <av-menu :items="items" :min-width="200">
    <template #activator="{ on, attrs }">
      <av-btn
        v-on="on"
        v-bind="attrs"
        variant="text"
        color="white"
        size="medium"
        aria-label="Account menu"
        icon>
        <av-icon name="circle-user" icon-style="regular" />
      </av-btn>
    </template>
  </av-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'navbar-menu',
  computed: {
    ...mapGetters('auth', ['isDemoUser']),
    items() {
      return [
        !this.isDemoUser && { label: 'My Account', name: 'profile' },
        { label: 'Log Out', action: () => this.logout() }
      ].filter(Boolean);
    }
  },
  methods: mapActions('auth', ['logout'])
};
</script>
