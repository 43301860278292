<template>
  <v-overlay opacity="0.9">
    <slot></slot>
  </v-overlay>
</template>

<script>
import { VOverlay } from 'vuetify/lib';

export default {
  name: 'av-overlay',
  components: { VOverlay }
};
</script>
