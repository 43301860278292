import cuid from 'cuid';
import { extractData } from '@/common/api/helpers';
import get from 'lodash/get';
import HttpStatus from 'http-status';
import request from '@/common/api/request';
import urljoin from 'url-join';

const urls = {
  base: 'customers',
  current: 'customers/current',
  registration: 'customers/registration',
  resource: id => urljoin(urls.base, id),
  paymentGatewayToken: () => urljoin(urls.base, 'payment-gateway-tokens'),
  currentSubscriptions: () => urljoin(urls.current, 'subscriptions'),
  currentTransactions: () => urljoin(urls.current, 'transactions'),
  currentCustomerSubscription: subscriptionId =>
    urljoin(urls.currentSubscriptions(), String(subscriptionId)),
  subscriptionPaymentMethod: subscriptionId =>
    urljoin(urls.currentCustomerSubscription(subscriptionId), 'payment-method')
};

function getCustomer(id) {
  return request.get(urls.resource(id)).then(extractData);
}

function register(paymentData) {
  const requestId = cuid();
  const headers = { 'idempotency-key': requestId };
  return request
    .createRetryRequest({
      retries: 4,
      // TODO: See if the exponential delay function suits our needs or we need to
      // search for another one
      retryDelay: retryCount => Math.pow(2, retryCount) * 1000,
      retryCondition(err) {
        const status = get(err, 'response.status', 0);
        const isRequestLocked = status === HttpStatus.UNPROCESSABLE_ENTITY;
        return request.isNetworkError(err) || isRequestLocked;
      }
    })
    .post(urls.registration, paymentData, { headers });
}

function getPaymentGatewayToken() {
  return request.get(urls.paymentGatewayToken()).then(extractData);
}

function getSubscriptions({ include } = {}) {
  const params = { include };
  return request.get(urls.currentSubscriptions(), { params }).then(extractData);
}

function getTransactions() {
  return request.get(urls.currentTransactions()).then(extractData);
}

function cancelSubscription(subscriptionId) {
  return request
    .delete(urls.currentCustomerSubscription(subscriptionId))
    .then(extractData);
}

function reactivateSubscription(subscriptionId) {
  return request
    .put(urls.currentCustomerSubscription(subscriptionId))
    .then(extractData);
}

function getSubscriptionPaymentMethod(subscriptionId) {
  return request
    .get(urls.subscriptionPaymentMethod(subscriptionId))
    .then(extractData);
}

function updateSubscriptionPaymentMethod(subscriptionId, paymentData) {
  return request
    .put(urls.subscriptionPaymentMethod(subscriptionId), paymentData)
    .then(extractData);
}

export default {
  get: getCustomer,
  register,
  getPaymentGatewayToken,
  getSubscriptions,
  cancelSubscription,
  reactivateSubscription,
  getTransactions,
  getSubscriptionPaymentMethod,
  updateSubscriptionPaymentMethod
};
