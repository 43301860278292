<template>
  <svg viewBox="0 0 37 41" fill="currentColor">
    <path
      d="M18.4056 20.8598C23.9788 20.8598 28.4957 16.3429 28.4957 10.7697C28.4957 5.19656 23.9788 0.679688 18.4056 0.679688C12.8324 0.679688 8.31558 5.19656 8.31558 10.7697C8.31558 16.3429 12.8324 20.8598 18.4056 20.8598ZM25.4687 23.3823H24.1522C22.4022 24.1863 20.4552 24.6435 18.4056 24.6435C16.3561 24.6435 14.4169 24.1863 12.659 23.3823H11.3426C5.49352 23.3823 0.748047 28.1278 0.748047 33.9768V37.2561C0.748047 39.345 2.44286 41.0399 4.53181 41.0399H32.2794C34.3684 41.0399 36.0632 39.345 36.0632 37.2561V33.9768C36.0632 28.1278 31.3177 23.3823 25.4687 23.3823Z"></path>
  </svg>
</template>

<script>
export default { name: 'av-user-outline-icon' };
</script>
