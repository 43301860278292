var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-checkbox',_vm._b({staticClass:"av-checkbox",attrs:{"ripple":false,"on-icon":_vm.circleCheckIcon,"off-icon":_vm.circleIcon,"indeterminate-icon":_vm.circleMinusIcon},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._t("label")]},proxy:true}],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},'v-checkbox',
    _vm.pick(_vm.$attrs, [
      'label',
      'value',
      'error-messages',
      'multiple',
      'readonly',
      'disabled',
      'hide-details'
    ])
  ,false))
}
var staticRenderFns = []

export { render, staticRenderFns }