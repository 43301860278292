<template>
  <av-dialog
    v-model="show"
    @close="close"
    :width="width"
    header-content-position="center"
    close-btn-class="d-none">
    <template #header>
      <h1 class="pt-2 pt-md-0 text-h4">{{ heading }}</h1>
    </template>
    <template #content>
      <p class="pt-7 px-4 mb-0 body-1 grey--text text--lighten-3 text-center">
        {{ message }}
      </p>
    </template>
    <template #actions>
      <form
        @submit.prevent="executeAction"
        class="pa-4 d-flex align-center justify-center">
        <av-btn @click="close" variant="secondary" class="mr-2 flex-grow-1">
          {{ cancelBtnText }}
        </av-btn>
        <av-btn
          :disabled="isLoading"
          type="submit"
          variant="primary"
          class="flex-grow-1">
          {{ confirmBtnText }}
        </av-btn>
      </form>
    </template>
  </av-dialog>
</template>

<script>
export default {
  name: 'av-confirmation-dialog',
  props: {
    visible: { type: Boolean, default: false },
    heading: { type: String, default: '' },
    message: { type: String, default: 'Are you sure?' },
    cancelBtnText: { type: String, default: 'No' },
    confirmBtnText: { type: String, default: 'Yes' },
    action: { type: Function, default: () => true }
  },
  data: () => ({ isLoading: false }),
  computed: {
    show: {
      get: vm => vm.visible,
      set(value) {
        if (!value) this.close();
      }
    },
    width: vm => (vm.$vuetify.breakpoint.smAndUp ? 500 : 360)
  },
  methods: {
    close() {
      this.$emit('update:visible', false);
    },
    executeAction() {
      this.isLoading = true;
      return Promise.resolve(this.action())
        .then(() => {
          this.close();
          this.$emit('confirmed');
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
