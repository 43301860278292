import { computed } from 'vue';
import { useGetters } from '@/main/composables/useVuex';

export const DEFAULT_TASKS = {
  areReproduciblesDownloaded: false,
  isCatalogItemRated: false,
  isRubricScoreSaved: false,
  isStrategyApplied: false,
  isVideoCompleted: false
};

const useProgressTasks = learningObjectId => {
  const { progressTasks } = useGetters('learnerProgress', ['progressTasks']);

  const tasks = computed(() => {
    return progressTasks.value[learningObjectId] || DEFAULT_TASKS;
  });
  const tasksCount = computed(() => {
    return Object.keys(tasks.value).length;
  });
  const completedTasksCount = computed(() => {
    return Object.values(tasks.value).filter(Boolean).length;
  });
  const isCompleted = computed(() => {
    return tasksCount.value === completedTasksCount.value;
  });

  return { tasks, tasksCount, completedTasksCount, isCompleted };
};

export default useProgressTasks;
