<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="currentColor">
    <path
      d="M31.9999 9.62503C31.9999 8.44847 31.1045 7.49454 30 7.49454H26.586C27.4695 6.56618 28.035 5.34917 28.035 3.85033C28.035 2.00479 27.04 0 24.247 0C20.572 0 17.502 4.16831 16.0595 6.50493C14.616 4.16884 11.452 0.000532585 7.7775 0.000532585C4.9845 0.000532585 3.9895 2.00533 3.9895 3.85087C3.9895 5.34913 4.5705 6.56671 5.472 7.49507H2C0.8955 7.49507 0 8.449 0 9.62557V16.0027H2.0175V29.8695C2.0175 31.0461 2.91297 32 4.0175 32H28.0075C29.112 32 30.0075 31.0461 30.0075 29.8695V16.0021H32V9.62503H31.9999ZM24.247 2.13103C25.4835 2.13103 26.035 2.68445 26.035 3.8509C26.035 6.21734 23.5555 7.49401 21.2235 7.49401H17.838C19.259 5.24581 21.76 2.13103 24.247 2.13103ZM7.77747 2.13106C10.265 2.13106 12.8604 5.24584 14.2814 7.49351H10.8949C8.56294 7.49351 5.98944 6.18379 5.98944 3.81734C5.98944 2.65143 6.54094 2.13106 7.77747 2.13106ZM30 13.8716H17V9.62503H30V13.8716ZM1.99997 9.62503H15V13.8716H1.99997V9.62503ZM4.01747 16.0021H15V29.869H4.01747V16.0021ZM28.008 29.869H17V16.0021H28.008V29.869Z" />
    <path
      d="M31.9999 9.62503C31.9999 8.44847 31.1045 7.49454 30 7.49454H26.586C27.4695 6.56618 28.035 5.34917 28.035 3.85033C28.035 2.00479 27.04 0 24.247 0C20.572 0 17.502 4.16831 16.0595 6.50493C14.616 4.16884 11.452 0.000532585 7.7775 0.000532585C4.9845 0.000532585 3.9895 2.00533 3.9895 3.85087C3.9895 5.34913 4.5705 6.56671 5.472 7.49507H2C0.8955 7.49507 0 8.449 0 9.62557V16.0027H2.0175V29.8695C2.0175 31.0461 2.91297 32 4.0175 32H28.0075C29.112 32 30.0075 31.0461 30.0075 29.8695V16.0021H32V9.62503H31.9999ZM24.247 2.13103C25.4835 2.13103 26.035 2.68445 26.035 3.8509C26.035 6.21734 23.5555 7.49401 21.2235 7.49401H17.838C19.259 5.24581 21.76 2.13103 24.247 2.13103ZM7.77747 2.13106C10.265 2.13106 12.8604 5.24584 14.2814 7.49351H10.8949C8.56294 7.49351 5.98944 6.18379 5.98944 3.81734C5.98944 2.65143 6.54094 2.13106 7.77747 2.13106ZM30 13.8716H17V9.62503H30V13.8716ZM1.99997 9.62503H15V13.8716H1.99997V9.62503ZM4.01747 16.0021H15V29.869H4.01747V16.0021ZM28.008 29.869H17V16.0021H28.008V29.869Z"
      fill="url(#paint0_linear_4268_8090)"
      style="mix-blend-mode: overlay" />
    <defs>
      <linearGradient
        id="paint0_linear_4268_8090"
        x1="7.68"
        y1="10.24"
        x2="25.0577"
        y2="23.4566"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#C27AEE" />
        <stop offset="1" stop-color="#63D5EA" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'av-gift-gradient-icon'
};
</script>
