import { computed } from 'vue';
import store from '@/main/store';
import yn from 'yn';

const {
  VUE_APP_FEATURE_BANNER_ENABLED,
  VUE_APP_FEATURE_BANNER_NAME,
  VUE_APP_FEATURE_BANNER_ROUTE,
  VUE_APP_FEATURE_BANNER_TEXT,
  VUE_APP_FEATURE_BANNER_REDIRECT_BUTTON_TEXT
} = process.env;

function useFeatureBanner() {
  const { profile } = store.state;

  const showBanner = computed(() => {
    const isEnabled = yn(VUE_APP_FEATURE_BANNER_ENABLED);
    const isVisible = store.getters['featureBanner/showBanner'];
    const isDemoUser = store.getters['auth/isDemoUser'];
    return isEnabled && isVisible && !isDemoUser;
  });
  const featureBannerProps = computed(() => ({
    bannerText: VUE_APP_FEATURE_BANNER_TEXT,
    redirectButtonText: VUE_APP_FEATURE_BANNER_REDIRECT_BUTTON_TEXT,
    featureRoute: VUE_APP_FEATURE_BANNER_ROUTE
  }));

  const initFeatureBannerState = () => {
    const isDemoUser = store.getters['auth/isDemoUser'];
    if (isDemoUser) return;
    store.dispatch('featureBanner/initBannerState', {
      featureName: VUE_APP_FEATURE_BANNER_NAME,
      id: profile.email
    });
  };

  return {
    showBanner,
    featureBannerProps,
    initFeatureBannerState
  };
}

export default useFeatureBanner;
