import { onBeforeUnmount, ref } from 'vue';
import { humanizeDuration } from '@/common/utils/formatters';

const getTimeLeft = endDate => {
  const diffInSeconds = (endDate - new Date()) / 1000;
  return humanizeDuration(diffInSeconds);
};

const useTimer = endDate => {
  const timeLeft = ref('');
  const counter = ref(null);

  timeLeft.value = getTimeLeft(endDate);
  counter.value = setInterval(() => {
    timeLeft.value = getTimeLeft(endDate);
  }, 1000);

  onBeforeUnmount(() => {
    clearInterval(counter.value);
  });

  return timeLeft;
};

export default useTimer;
