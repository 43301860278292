<template>
  <focus-loop :disabled="!isSidebarVisible" is-visible>
    <upgrade-dialog
      v-if="isDemoUser"
      v-model="isDialogActive"
      @close="toggleDialog"
      variant="community" />
    <navbar :is-sidebar-visible.sync="isSidebarVisible" />
    <sidebar
      @toggle-dialog="toggleDialog"
      :is-sidebar-visible.sync="isSidebarVisible" />
  </focus-loop>
</template>

<script>
import { mapGetters } from 'vuex';
import Navbar from '@/main/components/common/Navbar';
import Sidebar from '@/main/components/common/Sidebar';

export default {
  name: 'av-navigation',
  data: () => ({
    isSidebarVisible: false,
    isDialogActive: false
  }),
  computed: mapGetters('auth', ['isDemoUser']),
  methods: {
    toggleDialog() {
      this.isDialogActive = !this.isDialogActive;
    }
  },
  components: { Navbar, Sidebar }
};
</script>
