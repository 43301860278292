<template>
  <av-btn
    v-on="$listeners"
    v-bind="{ color, disabled }"
    :aria-label="label"
    variant="secondary"
    icon>
    <av-icon :icon-style="iconStyle" size="14" name="bookmark" />
  </av-btn>
</template>

<script>
export default {
  name: 'bookmark-btn',
  props: {
    isBookmarked: { type: Boolean, default: false },
    label: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    color: { type: String, default: 'primary' }
  },
  computed: {
    iconStyle: vm => (vm.isBookmarked ? 'solid' : 'regular')
  }
};
</script>
