import { createPaginationParams, extractData } from '@/common/api/helpers';
import request from '@/common/api/request';
import urljoin from 'url-join';

const urls = {
  base: '/catalog',
  search: () => urljoin(urls.base, 'search'),
  items: () => urljoin(urls.base, 'items'),
  item: id => urljoin(urls.items(), String(id)),
  audiences: () => urljoin(urls.base, 'audiences'),
  nasotItems: () => urljoin(urls.base, 'nasot/items'),
  video: learningObjectId => urljoin(urls.item(learningObjectId), 'video'),
  reproducibles: learningObjectId =>
    urljoin(urls.item(learningObjectId), 'reproducibles'),
  ratings: learningObjectId => urljoin(urls.item(learningObjectId), 'ratings'),
  rating: (learningObjectId, learnerId) =>
    urljoin(urls.ratings(learningObjectId), String(learnerId)),
  rubricScores: learningObjectId =>
    urljoin(urls.item(learningObjectId), 'rubric-scores'),
  strategyApplication: catalogItemId =>
    urljoin(urls.item(catalogItemId), 'strategy-application')
};

function fetch(params = {}) {
  return request
    .get(urls.items(), { params: createPaginationParams(params) })
    .then(extractData);
}

function get(publicId) {
  const url = urljoin(urls.items(), publicId);
  return request.get(url).then(extractData);
}

function fetchNasotItems() {
  return request.get(urls.nasotItems()).then(extractData);
}

function fetchAudiences() {
  return request.get(urls.audiences()).then(extractData);
}

function getSearchOptions(params = {}) {
  return request.get(urls.search(), { params }).then(extractData);
}

function saveRating(learningObjectId, { value }) {
  return request
    .post(urls.ratings(learningObjectId), { value })
    .then(extractData);
}

function getVideo(learningObjectId) {
  return request.get(urls.video(learningObjectId)).then(extractData);
}

function downloadReproducibles(learningObjectId, { archiveName }) {
  return request.get(urls.reproducibles(learningObjectId), {
    params: { archiveName },
    responseType: 'blob'
  });
}

function saveRubricScore(learningObjectId, { value }) {
  return request.put(urls.rubricScores(learningObjectId), { value });
}

function applyStrategy(catalogItemId) {
  return request.post(urls.strategyApplication(catalogItemId));
}

function unapplyStrategy(catalogItemId) {
  return request.delete(urls.strategyApplication(catalogItemId));
}

export default {
  urls,
  fetch,
  fetchNasotItems,
  fetchAudiences,
  get,
  getSearchOptions,
  getVideo,
  downloadReproducibles,
  saveRating,
  saveRubricScore,
  applyStrategy,
  unapplyStrategy
};
