<template>
  <div v-if="isInitialized" class="dashboard">
    <dashboard-hero :hero-content="featuredContent" :profile="profile" />
    <recommended-videos
      v-if="recommendedContent.length"
      :learning-objects="recommendedContent"
      class="dashboard-swiper mt-7 mt-md-11" />
    <upcoming-livestreams
      v-if="notEndedLivestreams.length"
      :items="notEndedLivestreams"
      class="mt-12" />
    <template v-for="({ title, items, seeAllRoute }, idx) in sections">
      <catalog-item-rails
        v-if="items.length"
        :key="`${title}-${idx}`"
        v-bind="{ seeAllRoute, title }"
        :catalog-items="items"
        :style="{ zIndex: sections.length - idx }"
        class="dashboard-swiper" />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CatalogItemRails from '@/main/components/common/CatalogItemRails';
import DashboardHero from './DashboardHero';
import { isFuture } from 'date-fns';
import { parseLivestreams } from '@/main/components/common/Livestreams/utils.js';
import RecommendedVideos from './RecommendedVideos';
import { ResourceType } from '@/main/models/catalog-item';
import Size from '@/main/components/common/cards/CatalogItemCard/size';
import UpcomingLivestreams from './UpcomingLivestreams';
import useProgressTasks from '@/main/composables/useProgressTasks';

export default {
  name: 'learner-dashboard',
  head: {
    title: { inner: 'Dashboard' }
  },
  data: () => ({
    isInitialized: false,
    featuredContent: null,
    livestreams: [],
    recommendedContent: [],
    newContent: [],
    inProgressContent: [],
    bookmarkedContent: [],
    topRatedContent: [],
    recommendedPlaylists: []
  }),
  computed: {
    ...mapState('profile', ['bookmarks']),
    ...mapState('learnerProgress', ['progress']),
    ...mapGetters('profile', ['audienceId', 'profile']),
    sections: vm => [
      {
        title: 'In Progress',
        items: vm.inProgressContent,
        seeAllRoute: { name: 'myLearning' }
      },
      {
        title: 'Most Popular',
        items: vm.topRatedContent,
        seeAllRoute: {
          name: 'catalog',
          query: { sortBy: 'topRated', sortAsc: 'false' }
        }
      },
      {
        title: 'Recently Added',
        items: vm.newContent,
        seeAllRoute: {
          name: 'catalog',
          query: { sortBy: 'createdAt', sortAsc: 'false' }
        }
      },
      {
        title: 'My Saved Strategies',
        items: vm.bookmarkedContent,
        seeAllRoute: {
          name: 'catalog',
          query: { bookmark: true }
        }
      },
      {
        title: 'Recommended playlists',
        items: vm.recommendedPlaylists,
        seeAllRoute: { name: 'catalog', query: { type: ResourceType.PLAYLIST } }
      }
    ],
    notEndedLivestreams: ({ livestreams }) =>
      livestreams
        .filter(it => isFuture(it.endDate))
        .sort((a, b) => a.startDate - b.startDate),
    playlistCardSize: () => Size.SM
  },
  methods: {
    ...mapActions('catalogItems', [
      'fetch',
      'fetchFeatured',
      'fetchRecommended',
      'fetchRecommendedPlaylists',
      'fetchNew',
      'fetchInProgress'
    ]),
    ...mapActions('learnerProgress', { fetchLearnerProgress: 'fetch' }),
    assignProgressToLearningObject(learningObject) {
      return {
        ...learningObject,
        progress: this.progress[learningObject.id]?.videoProgress
      };
    },
    assignProgressToPlaylist(playlist) {
      const updatedCatalogItems = playlist.catalogItems.map(catalogItem => {
        const { isCompleted } = useProgressTasks(catalogItem.id);
        return {
          ...catalogItem,
          isCompleted: isCompleted.value
        };
      });
      return {
        ...playlist,
        catalogItems: updatedCatalogItems
      };
    },
    async fetchBookmarkedContent() {
      if (!this.bookmarks.length) return [];

      const catalogItemIds = [...this.bookmarks]
        .sort((prev, next) => next.createdAt - prev.createdAt)
        .slice(0, 10)
        .map(it => it.catalogItemId);

      const { items } = await this.fetch({
        params: { id: { in: catalogItemIds } }
      });

      this.bookmarkedContent = catalogItemIds
        .reduce((acc, id) => {
          const catalogItem = items.find(item => item.id === id);
          return catalogItem ? acc.concat([catalogItem]) : acc;
        }, [])
        .map(this.assignProgressToLearningObject);
    },
    async fetchTopRatedContent() {
      const params = {
        audienceId: { eq: this.audienceId },
        type: { eq: ResourceType.LEARNING_OBJECT },
        limit: 10,
        sortBy: ['topRated']
      };
      const { items } = await this.fetch({ params });
      this.topRatedContent = items.map(this.assignProgressToLearningObject);
    },
    async fetchLivestreams() {
      const params = { type: { eq: ResourceType.LIVESTREAM } };
      const { items } = await this.fetch({ params });

      this.livestreams = parseLivestreams(items);
    },
    async fetchFeaturedContent() {
      const featuredContent = await this.fetchFeatured();
      this.featuredContent =
        this.assignProgressToLearningObject(featuredContent);
    },
    async fetchRecommendedContent() {
      const items = await this.fetchRecommended();
      this.recommendedContent = items.map(this.assignProgressToLearningObject);
    },
    async fetchRecommendedPlaylistContent() {
      const items = await this.fetchRecommendedPlaylists();
      this.recommendedPlaylists = items.map(this.assignProgressToPlaylist);
    },
    async fetchNewContent() {
      const items = await this.fetchNew();
      this.newContent = items.map(this.assignProgressToLearningObject);
    },
    async fetchInProgressContent() {
      const items = await this.fetchInProgress();
      this.inProgressContent = items.map(this.assignProgressToLearningObject);
    }
  },
  created() {
    this.fetchLearnerProgress().then(() => {
      Promise.allSettled([
        this.fetchFeaturedContent(),
        this.fetchRecommendedContent(),
        this.fetchLivestreams(),
        this.fetchNewContent(),
        this.fetchInProgressContent(),
        this.fetchBookmarkedContent(),
        this.fetchTopRatedContent(),
        this.fetchRecommendedPlaylistContent()
      ]).then(results => {
        if (results.some(it => it.status === 'rejected')) {
          this.$snackbar.error('Something went wrong!');
        }
        this.isInitialized = true;
      });
    });
  },
  components: {
    RecommendedVideos,
    DashboardHero,
    CatalogItemRails,
    UpcomingLivestreams
  }
};
</script>

<style lang="scss" scoped>
.dashboard {
  padding-bottom: 2rem;
  background-color: var(--v-grey-lighten6);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-bottom: 18rem;
  }

  &-swiper {
    position: relative;
    margin-top: 1.25rem;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      margin-top: 2.875rem;
    }
  }
}

.dashboard-swiper :deep(.av-swiper) .slide {
  &:first-child {
    margin-left: 1rem;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      margin-left: 2rem;
    }
  }

  &:last-child {
    margin-right: 1rem;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      margin-right: 2rem;
    }
  }
}
</style>
