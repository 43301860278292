<template>
  <div class="full-height d-flex justify-center align-center primary">
    <div class="d-flex flex-column align-center">
      <primary-logo
        :first-letter-color="$vuetify.theme.themes.light.secondary.base"
        color="white" />
      <av-circular-progress color="white" class="mt-5" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'av-loading'
};
</script>

<style lang="scss" scoped>
.full-height {
  height: 100vh;
}
</style>
