<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    stroke="currentColor">
    <path
      d="M16 1.22719L19.1537 11.0257C19.4017 11.7962 20.1059 12.3605 20.9487 12.3704L30.9363 12.4884L22.8829 18.7689C22.2357 19.2736 21.9838 20.1276 22.213 20.8967L25.1837 30.8623L17.1251 24.8685C16.4544 24.3696 15.5456 24.3696 14.8749 24.8685L6.81626 30.8623L9.78697 20.8967C10.0162 20.1276 9.76427 19.2736 9.11709 18.7689L1.06371 12.4884L11.0513 12.3704C11.8941 12.3605 12.5983 11.7962 12.8463 11.0257L16 1.22719Z"
      fill="none"
      stroke-width="2"
      stroke-linejoin="round" />
    <path
      d="M16 1.22719L19.1537 11.0257C19.4017 11.7962 20.1059 12.3605 20.9487 12.3704L30.9363 12.4884L22.8829 18.7689C22.2357 19.2736 21.9838 20.1276 22.213 20.8967L25.1837 30.8623L17.1251 24.8685C16.4544 24.3696 15.5456 24.3696 14.8749 24.8685L6.81626 30.8623L9.78697 20.8967C10.0162 20.1276 9.76427 19.2736 9.11709 18.7689L1.06371 12.4884L11.0513 12.3704C11.8941 12.3605 12.5983 11.7962 12.8463 11.0257L16 1.22719Z"
      stroke="url(#paint0_linear_4268_8098)"
      stroke-width="2"
      stroke-linejoin="round"
      fill="none"
      style="mix-blend-mode: overlay" />
    <defs>
      <linearGradient
        id="paint0_linear_4268_8098"
        x1="7.68"
        y1="10.24"
        x2="25.0577"
        y2="23.4566"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#C27AEE" />
        <stop offset="1" stop-color="#63D5EA" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'av-star-gradient-icon'
};
</script>
