<template>
  <v-progress-linear
    v-on="$listeners"
    v-bind="{ color, backgroundColor, height, rounded }"
    :value="$attrs.value"
    class="av-linear-progress" />
</template>

<script>
import { VProgressLinear } from 'vuetify/lib';

export default {
  name: 'av-linear-progress',
  model: { event: 'change' },
  props: {
    color: { type: String, default: 'secondary' },
    backgroundColor: { type: String, default: 'grey lighten-6' },
    height: { type: Number, default: 12 },
    rounded: { type: Boolean, default: false }
  },
  components: { VProgressLinear }
};
</script>
