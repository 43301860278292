<template>
  <div class="av-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'av-container'
};
</script>

<style lang="scss" scoped>
.av-container {
  max-width: map-get($container-max-widths, 'lg');
  margin: 0 auto;
  padding: 1.5rem;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 5rem 0;
  }
}
</style>
