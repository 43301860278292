import {
  alpha,
  confirmed,
  email,
  is,
  length,
  mimes,
  between as vvBetween,
  max as vvMax,
  min as vvMin,
  numeric as vvNumeric,
  required as vvRequired,
  size as vvSize
} from 'vee-validate/dist/rules';
import { isAfter, isFuture, isPast } from 'date-fns';
import { extend } from 'vee-validate';
import forEach from 'lodash/forEach';
import isURL from 'validator/lib/isURL';
import messages from 'vee-validate/dist/locale/en.json';
import organizationApi from '@/common/api/organization';
import pluralize from 'pluralize';
import prettyBytes from 'pretty-bytes';
import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';
import userApi from '@/common/api/user';

const nameFormat = {
  validate: value => {
    const hasValidUnicodeLetters = /^[\p{Letter}\s'-.]+$/u.test(value);
    const hasPunctuationStreak = /['-.]{2,}/.test(value);
    const hasValidBoundaries = !/^['-.].*|['.-]$/.test(value);
    return (
      hasValidUnicodeLetters && hasValidBoundaries && !hasPunctuationStreak
    );
  },
  message: 'The {_field_} field is not valid'
};

const url = {
  params: ['protocols', 'require_valid_protocol', 'require_protocol'],
  validate: (val, opts) => isURL(val, opts),
  message: 'The {_field_} is not a valid URL'
};

const alphanumerical = {
  validate: value => /\d/.test(value) && /[a-zA-Z]/.test(value),
  message:
    'The {_field_} field must contain at least 1 letter and 1 numeric value.'
};

const uniqueEmail = {
  params: ['userData'],
  validate: (email, { userData }) => {
    if (email === userData?.email) return true;
    return userApi.fetch({ params: { email } }).then(({ total }) => !total);
  },
  message: 'User already exists in Avanti'
};

const uniqueOrganizationName = {
  params: ['organizationData'],
  validate: (value, { organizationData }) => {
    if (value === organizationData?.name) return true;
    return organizationApi
      .checkByName(value)
      .then(() => false)
      .catch(() => true);
  },
  message: 'The {_field_} is not unique.'
};

export const between = {
  ...vvBetween,
  message: (name, { min, max }) => {
    if (min === max) return `The ${startCase(name)} must be equal ${min}`;
    return `The ${startCase(name)} must be a value between ${min} and ${max}`;
  }
};

const min = {
  ...vvMin,
  message: (name, { length }) =>
    `The ${startCase(name)} must have at least ${length} characters`
};

const max = {
  ...vvMax,
  message: (name, { length }) =>
    `The ${startCase(name)} must not exceed ${length} characters`
};

const numeric = {
  ...vvNumeric,
  message: name => `The ${startCase(name)} must be a number`
};

const size = {
  ...vvSize,
  message: (_name, { size: sizeInKb }) => {
    const sizeInBytes = sizeInKb * 1000;
    return `File size must be less than ${prettyBytes(sizeInBytes)}`;
  }
};

const required = {
  ...vvRequired,
  message: name => `The ${startCase(name)} field is required`
};

const minSelection = {
  params: ['min'],
  validate: (items, { min }) => Array.isArray(items) && items.length >= min,
  message: (_, { min }) =>
    `Please select at least ${min} ${pluralize('item', min)}`
};

const isAfterDate = {
  params: ['target'],
  validate: (value, { target }) => {
    const date = new Date(value);
    const dateToCompare = new Date(target);
    return isAfter(date, dateToCompare);
  },
  message: (_, { _field_, target }) => {
    return `${startCase(_field_)} must be after ${startCase(target)}`;
  }
};

const requiredIfDateInPast = {
  params: ['target'],
  validate: (value, { target }) => {
    if (!target) {
      return {
        valid: true,
        required: false
      };
    }
    const date = new Date(target);
    const isInPast = isPast(date);
    const isEmpty = !value;
    return {
      valid: !isEmpty || !isInPast,
      required: isInPast
    };
  },
  message: (_, { _field_, target }) => {
    return `${startCase(_field_)} is required if ${startCase(
      target
    )} is in the past.`;
  },
  computesRequired: true
};

const requiredIfDateInFuture = {
  params: ['target'],
  validate: (value, { target }) => {
    if (!target) {
      return {
        valid: true,
        required: false
      };
    }
    const date = new Date(target);
    const isInFuture = isFuture(date);
    const isEmpty = !value;
    return {
      valid: !isEmpty || !isInFuture,
      required: isInFuture
    };
  },
  message: (_, { _field_, target }) => {
    return `${startCase(_field_)} is required if ${startCase(
      target
    )} is in the future.`;
  },
  computesRequired: true
};

const allLosPublished = {
  validate: value => value.every(lo => lo.published),
  message:
    'All learning objects must be published or have a currently published version'
};

const rules = {
  allLosPublished,
  alpha,
  alphanumerical,
  between,
  confirmed,
  email,
  is,
  length,
  max,
  min,
  minSelection,
  mimes,
  nameFormat,
  numeric,
  required,
  size,
  url,
  uniqueEmail,
  uniqueOrganizationName,
  isAfterDate,
  requiredIfDateInPast,
  requiredIfDateInFuture
};

forEach(rules, (rule, name) =>
  extend(snakeCase(name), {
    message: messages[name],
    ...rule
  })
);
