<template>
  <v-list flat class="sidebar-list pr-0">
    <v-list-item
      v-for="{ name, label, action, icon } in items"
      :key="label"
      @click.native.capture="action && action($event)"
      :to="name ? { name } : undefined"
      :ripple="false"
      :class="smAndUpBreakpoint ? 'pl-10 mb-8' : 'pl-8 mb-4'"
      exact
      class="d-flex align-center">
      <v-list-item-icon class="my-0 mr-4 align-self-center">
        <av-icon
          v-bind="icon"
          :size="smAndUpBreakpoint ? '24' : '20'"
          color="black" />
      </v-list-item-icon>
      <v-list-item-content class="py-0">
        <v-list-item-title class="text-h4 grey--text" v-text="label" />
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  VList,
  VListItem,
  VListItemContent,
  VListItemIcon,
  VListItemTitle
} from 'vuetify/lib';

export default {
  name: 'sidebar-list',
  computed: {
    ...mapGetters('auth', ['isDemoUser']),
    smAndUpBreakpoint: vm => vm.$vuetify.breakpoint.smAndUp,
    items: vm =>
      [
        {
          name: 'dashboard',
          label: 'Dashboard',
          icon: { name: 'house-chimney', iconStyle: 'regular' }
        },
        {
          name: 'community',
          label: 'Community',
          action: event => {
            if (vm.isDemoUser) {
              event.preventDefault();
              vm.$emit('toggle-dialog');
            }
          },
          icon: { name: 'comments-question-check', iconStyle: 'regular' }
        },
        {
          name: 'livestreams',
          label: 'Livestreams',
          icon: { name: 'camera-web', iconStyle: 'regular' }
        },
        {
          name: 'catalog',
          label: 'Browse Catalog',
          icon: { name: 'table-list', iconStyle: 'regular' }
        },
        !vm.isDemoUser && {
          name: 'profile',
          label: 'My Account',
          icon: { name: 'gear', iconStyle: 'regular' }
        },
        {
          label: 'Log Out',
          action: _event => vm.logout(),
          icon: { name: 'arrow-right-from-bracket', iconStyle: 'regular' }
        }
      ].filter(Boolean)
  },
  methods: mapActions('auth', ['logout']),
  components: {
    VList,
    VListItem,
    VListItemContent,
    VListItemTitle,
    VListItemIcon
  }
};
</script>

<style lang="scss" scoped>
.sidebar-list .v-list-item {
  width: 100%;

  &:focus-visible,
  &:hover {
    background-color: var(--v-accent-secondary-lighten4);
  }

  &__icon .v-icon {
    margin-bottom: 0.125rem;
  }

  &__content {
    overflow: visible;
  }

  &--active {
    .v-list-item__title,
    .v-list-item__icon .v-icon {
      color: var(--v-primary-base) !important;
    }
  }

  &:last-child {
    position: absolute;
    bottom: 0.875rem;
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    height: 3.75rem;

    &:last-child {
      bottom: 0.5rem;
    }
  }
}
</style>
