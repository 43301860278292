if (!window.requestIdleCallback) {
  window.requestIdleCallback = (callback, options = {}) => {
    const relaxation = 1;
    const timeout = options.timeout || relaxation;
    const start = performance.now();
    return setTimeout(() => {
      /* eslint-disable node/no-callback-literal */
      callback({
        get didTimeout() {
          if (!options.timeout) return false;
          return getElapsedTime(start) - relaxation > timeout;
        },
        timeRemaining() {
          return Math.max(0, relaxation + getElapsedTime(start));
        }
      });
    }, relaxation);
  };
}

function getElapsedTime(start) {
  return performance.now() - start;
}
