<template>
  <div>
    {{ recordLength }}<br />
    {{ startDatelabel }}
  </div>
</template>

<script setup>
import { formatDate, humanizeDuration } from '@/common/utils/formatters';
import { computed } from 'vue';
import Size from '@/main/components/common/cards/CatalogItemCard/size';

const props = defineProps({
  size: { type: String, required: true },
  startDate: { type: Date, required: true },
  endDate: { type: Date, required: true }
});

const startDatelabel = computed(() => {
  if (props.size === Size.SM)
    return formatDate(props.startDate, 'MMMM D, YYYY');
  return `Recorded Live ${formatDate(props.startDate)}`;
});
const recordLength = computed(() => {
  const diffInSeconds = (props.endDate - props.startDate) / 1000;
  return humanizeDuration(diffInSeconds);
});
</script>
