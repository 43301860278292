<template>
  <div class="my-account pb-md-10">
    <av-tabs :tabs="tabs" class="my-account-tabs" centered nested-routes />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'my-account-container',
  head: {
    title: { inner: 'My Account' }
  },
  computed: {
    ...mapGetters('auth', ['hasFreeAccess']),
    tabs() {
      const account = {
        name: 'profile',
        label: 'Profile',
        prependIcon: 'circle-user',
        prependIconStyle: 'solid'
      };
      const myLearning = {
        name: 'myLearning',
        label: 'My Learning',
        prependIcon: 'user',
        prependIconStyle: 'solid'
      };
      const billing = {
        name: 'billing',
        label: 'Billing',
        prependIcon: 'credit-card',
        prependIconStyle: 'solid'
      };
      return [account, myLearning, !this.hasFreeAccess && billing].filter(
        Boolean
      );
    },
    mdAndUp: vm => vm.$vuetify.breakpoint.mdAndUp
  }
};
</script>

<style lang="scss" scoped>
.my-account {
  position: relative;
  height: calc(100vh - var(--navbar-height) - var(--footer-height));
  background-color: var(--v-white-base);

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    height: 100%;
    background-color: var(--v-grey-lighten6);
  }

  &-header {
    padding: 1rem;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding: 1.75rem 5.5rem 1.5rem;
    }
  }

  &-tabs {
    :deep(.av-tab) {
      @media #{map-get($display-breakpoints, 'md-and-up')} {
        width: auto !important;
        padding: 0.5rem 5.72rem !important;
      }
    }

    :deep(.tab-content-wrapper) {
      display: flex;
      flex-flow: column;
      background-color: var(--v-grey-lighten6);
      overflow: auto;
    }

    :deep(.tab-content) {
      display: flex;
      flex-direction: column;
      gap: 2.125rem;
      max-width: map-get($container-max-widths, 'xl');
      margin: 2.75rem 0;
      padding: 0 2rem;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        margin: 2.75rem auto auto;
      }
    }
  }
}
</style>
