<template>
  <svg :width="width" viewBox="0 0 697 209" role="image">
    <title>Avanti logo</title>
    <path
      :fill="color"
      d="M543.2,66.05h-39.07l-0.15-24.54h104.84v24.54h-39.03v98.4H543.2V66.05z"></path>
    <path
      :fill="color"
      d="M133.95,41.51h30.07l38.36,76.43l38.61-76.43h29.51l-67.81,128.23L133.95,41.51z"></path>
    <path
      :fill="color"
      d="M38.72,163.93h29.51l0.25-0.49c-8.28,0.01-18.79,0.02-29.51,0.03L38.72,163.93z"></path>
    <path
      :fill="color"
      d="M145.19,163.93h30.07l-0.25-0.46c-10.94-0.01-21.67-0.02-30.07-0.03L145.19,163.93z"></path>
    <path
      :fill="firstLetterFill"
      d="M126.73,103.48c-4.94,0-8.31,1.08-10.61,2.54l28.82,57.42c8.4,0.01,19.13,0.02,30.07,0.03l-32.14-60 C133.95,103.48,126.73,103.48,126.73,103.48z"></path>
    <path
      :fill="firstLetterFill"
      d="M119.98,96.48c0,0,8.9,0.01,19.17,0.02l-18.99-35.45c-0.04,0-0.06,0-0.06,0 c-10.51,0.13-10.35,7.86-10.35,7.86V93.3l2.69,5.36C115.66,96.32,119.98,96.48,119.98,96.48z"></path>
    <path
      :fill="firstLetterFill"
      d="M108.81,57.35c1.39-3.27,5.72-3.1,5.72-3.1s0.78,0,1.99,0l-7.71-14.39v16.12V57.35z"></path>
    <path
      :fill="firstLetterFill"
      d="M104.86,57.35v-1.37V38.83c0,0,0.01-0.06,0.01-0.15l-8.22,15.57c1.49,0,2.49-0.01,2.49-0.01 S103.48,54.08,104.86,57.35z"></path>
    <path
      :fill="firstLetterFill"
      d="M93.57,61.05c0,0-0.19,0-0.52,0L74.33,96.51c10.35-0.01,19.37-0.02,19.37-0.02s4.29-0.16,7.51,2.16 l2.72-5.39V68.91C103.93,68.91,104.08,61.19,93.57,61.05z"></path>
    <path
      :fill="firstLetterFill"
      d="M70.65,103.47l-31.69,60c10.73-0.01,21.23-0.02,29.51-0.03l29.02-57.45c-2.3-1.45-5.65-2.51-10.56-2.51 C86.94,103.48,79.65,103.48,70.65,103.47z"></path>
    <path
      :fill="color"
      d="M377.01,41.51h26.74l62.37,83.13V41.51h26.85v122.94h-26.45l-62.49-82.99v82.99h-27.01V41.51z"></path>
    <path :fill="color" d="M619.8,41.51h27.01v122.94H619.8V41.51z"></path>
    <polygon
      :fill="color"
      points="336.59,163.93 366.66,163.93 310.74,59.52 310.74,59.51 297.91,35.57 230.11,163.93 259.62,163.93 297.97,88.01 324.82,140.49"></polygon>
  </svg>
</template>

<script>
export default {
  name: 'primary-logo',
  props: {
    color: { type: String, default: '#263238' },
    firstLetterColor: { type: String, default: null },
    width: { type: Number, default: 200 }
  },
  computed: {
    firstLetterFill: vm => vm.firstLetterColor || vm.color
  }
};
</script>
