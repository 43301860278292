<template>
  <v-rating
    v-on="$listeners"
    :value="$attrs.value"
    :readonly="readonly"
    :length="5"
    :size="size"
    background-color="primary"
    full-icon="$vuetify.icons.fas-star"
    half-icon="$vuetify.icons.far-star"
    class="av-rating" />
</template>

<script>
import { VRating } from 'vuetify/lib';

export default {
  name: 'av-rating',
  props: {
    size: { type: Number, default: 32 },
    readonly: { type: Boolean, default: false }
  },
  components: { VRating }
};
</script>

<style lang="scss" scoped>
.av-rating :deep(.v-icon) {
  padding: 1.375rem;
}
</style>
