<template>
  <v-menu
    v-on="$listeners"
    :value="$attrs.value"
    :min-width="minWidth"
    transition="slide-y-transition"
    nudge-bottom="4"
    left
    offset-y
    attach>
    <template #activator="activatorProps">
      <slot name="activator" v-bind="activatorProps"></slot>
    </template>
    <v-list>
      <v-list-item
        v-for="{ name, label, action } in items"
        :key="label"
        @click="action && action()"
        :to="name ? { name } : undefined">
        <v-list-item-title>{{ label }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { VList, VListItem, VListItemTitle, VMenu } from 'vuetify/lib';

export default {
  name: 'av-menu',
  props: {
    items: { type: Array, required: true },
    minWidth: { type: Number, default: 130 }
  },
  components: { VMenu, VList, VListItem, VListItemTitle }
};
</script>

<style lang="scss" scoped>
@import '~@/common/stylesheets/dropdown-list';

.v-menu__content {
  @extend %dropdown-list;
}
</style>
