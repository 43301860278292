<template>
  <focus-loop :disabled="!$attrs.value" is-visible>
    <v-dialog
      v-on="$listeners"
      @click:outside="close"
      v-bind="pick($attrs, ['persistent', 'fullscreen', 'value', 'transition'])"
      :width="width"
      :class="{ 'align-bottom': alignBottom }"
      overlay-color="grey"
      overlay-opacity="0.8"
      attach
      class="av-dialog">
      <av-card :aria-labelledby="ariaLabelId" class="card">
        <div
          v-if="!hideHeader"
          :class="`justify-${headerContentPosition || 'space-between'}`"
          class="pt-1 pt-md-3 pl-1 pr-2 pb-0 d-flex align-center">
          <div :id="ariaLabelId">
            <slot name="header"></slot>
          </div>
          <av-btn
            v-if="showCloseBtn"
            @click="close"
            :class="closeBtnClass"
            variant="text"
            size="medium"
            aria-label="Close"
            icon
            class="close-btn">
            <av-icon name="close" color="grey lighten-1" />
          </av-btn>
        </div>
        <slot name="content"></slot>
        <slot name="actions"></slot>
      </av-card>
    </v-dialog>
  </focus-loop>
</template>

<script>
import cuid from 'cuid';
import pick from 'lodash/pick';
import { VDialog } from 'vuetify/lib';

export default {
  name: 'av-dialog',
  props: {
    alignBottom: { type: Boolean, default: false },
    width: { type: Number, default: 600 },
    closeBtnClass: { type: String, default: '' },
    showCloseBtn: { type: Boolean, default: true },
    headerContentPosition: {
      type: String,
      default: undefined,
      validator: val => ['center'].includes(val)
    },
    hideHeader: { type: Boolean, default: false }
  },
  computed: {
    ariaLabelId: () => `label-${cuid()}`
  },
  methods: {
    pick,
    close() {
      this.$emit('close');
    }
  },
  components: { VDialog }
};
</script>

<style lang="scss" scoped>
@import '~@/common/assets/stylesheets/utils/z-index';

:deep(.av-dialog) {
  @include z-index(modal);

  .v-dialog {
    margin: 0;
    border-radius: 0;
  }

  .v-dialog__content .card {
    background-color: var(--v-white-base);
    border-radius: inherit;

    .close-btn {
      @include z-index(modal);

      margin-right: 0.875rem;
    }
  }

  &.align-bottom .v-dialog__content {
    align-items: flex-end;
  }
}
</style>
