import { NasotItemType } from '@/common/models/nasot-item';

const CATEGORY = {
  type: NasotItemType.CATEGORY,
  rootLevel: true,
  label: 'Category',
  color: 'primary darken-4',
  subLevels: [NasotItemType.STRAND]
};

const STRAND = {
  type: NasotItemType.STRAND,
  label: 'Strand',
  color: 'primary lighten-1',
  subLevels: [NasotItemType.ELEMENT]
};

const ELEMENT = {
  type: NasotItemType.ELEMENT,
  label: 'Element',
  color: 'secondary darken-4',
  subLevels: [NasotItemType.STRATEGY]
};

const STRATEGY = {
  type: NasotItemType.STRATEGY,
  label: 'Strategy',
  color: 'secondary darken-3'
};

export default [CATEGORY, STRAND, ELEMENT, STRATEGY];
