<template>
  <svg
    fill="currentColor"
    stroke="currentColor"
    viewBox="0 0 50 37"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.0949 30.1957C13.8187 30.1854 13.5631 30.0458 13.4044 29.8196C8.64766 23.0415 8.8166 13.9673 13.8231 7.37129C13.9979 6.99522 14.4136 6.7969 14.8161 6.89679C15.2289 7.05398 15.511 7.43593 15.5389 7.87664C15.6124 8.33057 15.4464 8.79038 15.0982 9.09006C10.8483 14.7032 10.7161 22.4201 14.7691 28.1758C15.035 28.5533 15.1026 29.0366 14.9513 29.4729C14.8455 29.8769 14.4944 30.1678 14.0787 30.1972L14.0949 30.1957Z"
      stroke-linejoin="round"></path>
    <path
      d="M7.39846 34.3481C7.10171 34.3451 6.82701 34.1938 6.66394 33.9456C0.235437 24.5379 0.474887 12.0907 7.26037 2.93716C7.444 2.60075 7.82154 2.42006 8.19761 2.48617C8.58103 2.58606 8.87337 2.8975 8.94829 3.28679C9.04818 3.69224 8.93801 4.1212 8.65448 4.4297C2.51832 12.7106 2.30237 23.9708 8.11534 32.4809C8.37095 32.8335 8.42971 33.2904 8.27106 33.6958C8.14472 34.0778 7.79215 34.3393 7.38964 34.3481H7.39846Z"
      stroke-linejoin="round"></path>
    <path
      d="M36.9274 30.0446C36.6776 30.0344 36.4455 29.9154 36.2927 29.717C35.9108 29.222 35.8946 28.5359 36.2545 28.0232C40.3061 22.2676 40.1754 14.5537 35.9313 8.93899C35.6052 8.63343 35.5215 7.74466 35.5215 7.74466C35.5215 7.74466 35.8226 6.94697 36.2178 6.78391C36.5998 6.67079 37.0081 6.83973 37.1991 7.18936C42.1938 13.7912 42.3525 22.864 37.5928 29.6377C37.4459 29.8698 37.2006 30.0197 36.9274 30.0446Z"
      stroke-linejoin="round"></path>
    <path
      d="M43.6265 34.201C43.3871 34.2084 43.1564 34.1129 42.9919 33.9395C42.7906 33.7368 42.6702 33.4665 42.6526 33.1815C42.6276 32.8921 42.7054 32.6027 42.8714 32.3647C48.6932 23.8576 48.4876 12.5945 42.3573 4.30617C42.0738 3.99767 41.9636 3.56871 42.0635 3.16179C42.1384 2.7725 42.4307 2.46106 42.8142 2.36117C43.1917 2.29506 43.5678 2.47575 43.7514 2.81216C50.5354 11.9672 50.7749 24.4143 44.3478 33.8235C44.1774 34.06 43.9042 34.1981 43.6133 34.1966L43.6265 34.201Z"
      stroke-linejoin="round"></path>
    <path
      d="M32.3036 17.2264C32.5938 17.3907 32.7969 17.637 32.9129 17.9107C33.029 18.2118 33.029 18.5129 32.9129 18.7866C32.7969 19.0876 32.5938 19.3066 32.3036 19.4708L22.0893 25.1639C21.7991 25.3281 21.4799 25.3828 21.1607 25.3281C20.8125 25.2734 20.5513 25.1365 20.3192 24.8902C20.0871 24.6712 20 24.3701 20 24.0417V12.6556C20 12.2998 20.1161 11.9987 20.3482 11.7797C20.5804 11.5608 20.8415 11.4239 21.1897 11.3692C21.5089 11.3144 21.7991 11.3692 22.0893 11.5334L32.3036 17.2264Z"></path>
  </svg>
</template>

<script>
export default {
  name: 'av-livestream-icon'
};
</script>
