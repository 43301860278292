var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-hover',{attrs:{"data-testid":_vm.$attrs['data-testid'],"disabled":_vm.isExpansionDisabled,"open-delay":"200"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('router-link',{ref:"card",staticClass:"catalog-item-card d-block",class:`catalog-item-card-${_vm.size}`,attrs:{"to":_vm.catalogItemRoute},nativeOn:{"focus":function($event){return _vm.expandCard.apply(null, arguments)},"blur":function($event){return _vm.collapseCard.apply(null, arguments)}}},[_c('card-thumbnail',_vm._b({staticClass:"card-main-thumbnail",on:{"create:bookmark":function($event){return _vm.createBookmark(_vm.id)},"delete:bookmark":function($event){return _vm.deleteBookmark(_vm.id)}},scopedSlots:_vm._u([{key:"thumbnail",fn:function(){return [_vm._t("thumbnail")]},proxy:true},{key:"tasks",fn:function(){return [_vm._t("tasks")]},proxy:true},{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true}],null,true)},'card-thumbnail',{
        id: _vm.id,
        size: _vm.size,
        title: _vm.title,
        isBookmarked: _vm.isBookmarked,
        audiences: _vm.audiences,
        isActive: _vm.isActive
      },false)),_c('transition',{attrs:{"name":"fade-and-scale"}},[(_vm.isActive)?_c('div',{staticClass:"card-hover"},[_c('card-thumbnail',_vm._b({attrs:{"tabindex":"-1"},on:{"create:bookmark":function($event){return _vm.createBookmark(_vm.id)},"delete:bookmark":function($event){return _vm.deleteBookmark(_vm.id)}},scopedSlots:_vm._u([{key:"thumbnail",fn:function(){return [_vm._t("thumbnail")]},proxy:true},{key:"tasks",fn:function(){return [_vm._t("tasks")]},proxy:true},{key:"footer",fn:function(){return [_vm._t("footer")]},proxy:true}],null,true)},'card-thumbnail',{
            id: _vm.id,
            publicId: _vm.publicId,
            size: _vm.size,
            title: _vm.title,
            isBookmarked: _vm.isBookmarked,
            audiences: _vm.audiences
          },false)),_c('expansion-panel',_vm._b({on:{"blur":_vm.collapseCard,"create:bookmark":function($event){return _vm.createBookmark(_vm.id)},"delete:bookmark":function($event){return _vm.deleteBookmark(_vm.id)}}},'expansion-panel',{
            publicId: _vm.publicId,
            title: _vm.title,
            nasotItems: _vm.nasotItems,
            progress: _vm.progress,
            isBookmarked: _vm.isBookmarked,
            primaryActionOptions: _vm.primaryActionOptions,
            description: _vm.expansionDescription
          },false))],1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }