<template>
  <div>
    <h2
      :class="{ 'text-center': breakpoint.mdAndUp }"
      class="mb-5 mb-md-11 px-4 text-h3">
      Recommended Strategies
    </h2>
    <av-swiper :items="learningObjects">
      <template #default="{ item }">
        <learning-object-card :learning-object="item" size="lg" />
      </template>
    </av-swiper>
  </div>
</template>

<script>
import AvSwiper from '@/common/components/base/Swiper';
import LearningObjectCard from '@/main/components/common/cards/LearningObjectCard';

export default {
  name: 'recommended-videos',
  props: {
    learningObjects: { type: Array, required: true }
  },
  computed: {
    breakpoint: vm => vm.$vuetify.breakpoint
  },
  components: {
    AvSwiper,
    LearningObjectCard
  }
};
</script>
