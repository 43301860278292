<template>
  <v-select
    v-on="$listeners"
    v-bind="
      pick($attrs, ['value', 'label', 'placeholder', 'disabled', 'rounded'])
    "
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :multiple="multiple"
    :clearable="clearable"
    :append-icon="internalAppendIcon"
    :menu-props="{ closeOnClick: true }"
    :error-messages="errorMessages"
    :class="{
      'error-visible': errorMessages.length,
      'has-label': !!$attrs.label
    }"
    clear-icon="$vuetify.icons.close"
    attach
    outlined
    persistent-placeholder
    class="av-select">
    <template v-if="multiple && selectAll" #prepend-item>
      <v-list-item
        @mousedown.prevent
        @click="toggleSelectAll"
        :input-value="true"
        class="primary--text">
        <v-list-item-action>
          <av-icon v-bind="selectAllIcon" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Select All</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-if="multiple" #item="{ item, on, attrs }">
      <v-list-item v-on="on" v-bind="attrs">
        <v-list-item-action>
          <av-icon
            v-if="attrs.inputValue"
            icon-style="solid"
            name="circle-check" />
          <av-icon v-else icon-style="light" name="circle" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ getItemText(item) }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-if="multiple" #selection="{ index }">
      <span v-if="index === 0">
        {{ selectionText }}
      </span>
    </template>
  </v-select>
</template>

<script>
import {
  VListItem,
  VListItemAction,
  VListItemContent,
  VListItemTitle,
  VSelect
} from 'vuetify/lib';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import pick from 'lodash/pick';
import pluralize from 'pluralize';

export default {
  name: 'av-select',
  props: {
    items: { type: Array, required: true },
    itemText: { type: String, default: 'name' },
    itemValue: { type: String, default: 'id' },
    multiple: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    selectAll: { type: Boolean, default: true },
    appendIcon: { type: String, default: '$vuetify.icons.fas-chevron-down' },
    errorMessages: { type: [Array, String], default: () => [] }
  },
  computed: {
    internalAppendIcon() {
      const { value } = this.$attrs;
      const isComplexType = isObject(value) || Array.isArray(value);
      const hasValue = isComplexType ? !isEmpty(value) : value;
      return this.clearable && hasValue ? '' : this.appendIcon;
    },
    selectedCount() {
      return get(this.$attrs.value, 'length');
    },
    allItemsSelected() {
      return this.items.length === this.selectedCount;
    },
    selectionText() {
      const { selectedCount } = this;
      return `${selectedCount} ${pluralize('option', selectedCount)} selected`;
    },
    selectAllIcon() {
      if (!this.selectedCount) return { iconStyle: 'light', name: 'circle' };
      if (this.allItemsSelected)
        return { iconStyle: 'solid', name: 'circle-check' };
      return { iconStyle: 'solid', name: 'circle-minus' };
    }
  },
  methods: {
    pick,
    toggleSelectAll() {
      if (this.allItemsSelected) return this.$emit('input', []);
      const values = this.items.map(this.getItemValue);
      return this.$emit('input', values);
    },
    getItemValue(item) {
      return isObject(item) ? item[this.itemValue] : item;
    },
    getItemText(item) {
      return isObject(item) ? item[this.itemText] : item;
    }
  },
  components: {
    VListItem,
    VListItemAction,
    VListItemContent,
    VListItemTitle,
    VSelect
  }
};
</script>

<style lang="scss" scoped>
@import '~@/common/stylesheets/dropdown-list';
@import './input';

.av-select.v-select :deep(.v-input__control) {
  @extend %av-input;

  .v-input__slot {
    margin-bottom: 0;

    .v-select__slot {
      position: unset;
    }

    .v-select__selection {
      color: var(--v-primary-base);
      font-weight: 700;
    }

    input,
    input::placeholder {
      font-size: 1rem;
      line-height: 1.125rem;
    }

    input::placeholder {
      color: var(--v-grey-lighten2);
    }
  }

  .v-input__append-inner {
    margin-right: 0.25rem;
  }

  .v-input__icon--append .v-icon {
    color: var(--v-grey-lighten3);
  }

  .v-input__icon--append svg {
    height: 1.125rem;
  }

  .v-input__icon--clear .v-icon {
    width: 1.375rem;
    color: var(--v-grey-base);
  }

  .v-input__icon--clear svg {
    height: 1.375rem;
  }

  .v-text-field__details {
    display: none;
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}

.av-select.v-select :deep(.v-menu__content) {
  @extend %dropdown-list;

  top: 3.25rem !important;
}

.av-select.v-select.v-text-field--rounded {
  border-radius: 34px;

  :deep(.v-input__slot) {
    min-height: 2.75rem;

    label {
      transform: translateY(-3rem);
    }

    .v-select__selections {
      padding: 0;
    }

    .v-input__append-inner {
      margin-top: 0.625rem;
    }

    .v-input__icon--clear {
      opacity: 1 !important;

      .v-icon {
        width: 1.25rem;
        font-size: 1.25rem;
      }
    }

    .v-input__icon--append .v-icon {
      font-size: 1rem;
    }
  }

  :deep(.v-menu__content) {
    top: 2.875rem !important;
    min-width: 12rem !important;
    max-width: 18.75rem !important;
  }
}

.av-select.v-input--is-dirty,
.av-select:hover {
  :deep(.v-input__control) .v-input__slot {
    background: var(--v-primary-lighten6);

    .v-input__icon--append .v-icon,
    .v-input__icon--clear .v-icon {
      color: var(--v-primary-base);
    }

    fieldset {
      border-color: var(--v-primary-base);
    }
  }
}

.av-select.v-select {
  $label-height: 1.375rem;

  &.error-visible :deep(.v-text-field__details) {
    display: block;
  }

  &.v-input--is-focused :deep(.v-input__slot) {
    label {
      color: var(--v-primary-lighten1) !important;
    }

    fieldset {
      border-color: var(--v-primary-base);
      box-shadow: 0 0 0.375rem rgba(0, 68, 124, 0.5);
    }

    .v-input__icon .v-icon {
      color: var(--v-primary-base);
    }
  }

  &.v-input--is-disabled :deep(.v-input__slot) {
    background: var(--v-grey-lighten6);

    label {
      color: var(--v-grey-lighten5);
    }

    .v-select__selection {
      color: var(--v-grey-lighten4);
    }

    fieldset {
      border-color: var(--v-grey-lighten5);
    }
  }

  &.error--text :deep(.v-input__slot) {
    fieldset {
      border-color: var(--v-error-base);
    }

    label {
      color: var(--v-grey-lighten2) !important;
    }

    .v-input__append-inner .v-icon {
      color: var(--v-grey-base) !important;
    }
  }

  &.has-label {
    padding-top: $label-height;

    :deep(.v-menu__content) {
      top: 3.25rem + $label-height !important;
    }

    &.v-text-field--rounded :deep(.v-menu__content) {
      top: 2.875rem + $label-height !important;
    }
  }

  &.v-text-field--enclosed :deep(.v-input__control) .v-input__slot {
    padding: 0 0.5rem 0 1.5rem;
  }
}
</style>
