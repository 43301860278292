<template>
  <v-navigation-drawer
    @input="$emit('update:isSidebarVisible', $event)"
    :value="isSidebarVisible"
    :width="smAndUpBreakpoint ? 316 : 264"
    fixed
    temporary
    class="av-sidebar"
    aria-label="Sidebar - content">
    <sidebar-list
      @toggle-dialog="$emit('toggle-dialog')"
      :class="smAndUpBreakpoint ? 'mt-10' : 'mt-7'"
      class="mr-0" />
  </v-navigation-drawer>
</template>

<script>
import SidebarList from './List';
import { VNavigationDrawer } from 'vuetify/lib';

export default {
  props: {
    isSidebarVisible: { type: Boolean, default: false }
  },
  computed: {
    smAndUpBreakpoint: vm => vm.$vuetify.breakpoint.smAndUp
  },
  components: { SidebarList, VNavigationDrawer }
};
</script>

<style lang="scss" scoped>
@import '~@/common/assets/stylesheets/utils/z-index';

.av-sidebar.v-navigation-drawer {
  @include z-index(sidebar);

  padding-top: 3.75rem;
  box-shadow: $elevation-20;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding-top: 5rem;
  }
}
</style>
