<template>
  <div class="metadata-info">
    <span class="text-body-bold">{{ strategyInfo }}</span>
    <span class="text-body-bold metadata-divider">|</span>
    <span class="playlist-duration">
      <av-icon
        name="clock"
        icon-style="regular"
        :size="iconSize"
        class="icon mr-2" />
      <span class="text-body-bold">{{ totalDuration }}</span>
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { humanizeDuration } from '@/common/utils/formatters';

const props = defineProps({
  strategyInfo: { type: String, required: true },
  playlistDuration: { type: Number, required: true },
  iconSize: { type: Number, required: true }
});
const totalDuration = computed(() => humanizeDuration(props.playlistDuration));
</script>

<style lang="scss" scoped>
.metadata-info {
  display: flex;

  span {
    color: var(--v-white-base);
  }

  .metadata-divider {
    opacity: 0.5;
    margin: 0 0.75rem;
  }

  .playlist-duration {
    display: flex;
    align-items: center;
  }
}
</style>
