<template>
  <v-sheet width="7.5rem" class="milestone-block">
    <span class="milestone-count">{{ count }}</span>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <span v-html="parsedHyphenatedText"></span>
  </v-sheet>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  count: { type: Number, required: true },
  text: { type: String, required: true }
});

const parsedHyphenatedText = computed(() => props.text.replace('-', '&#8209;'));
</script>

<style lang="scss" scoped>
.milestone-block {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  margin-top: 0.75rem;
  padding: 0.8rem 1rem;
  border-radius: 16px;
  color: var(--v-primary-base);
  font-size: 0.875rem;
  text-align: center;
  text-transform: capitalize;

  .milestone-count {
    display: block;
    font-size: 1.5rem;
    font-weight: 500;
  }
}
</style>
