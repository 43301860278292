<template>
  <div class="nasot">
    <span class="label font-weight-medium mr-1">{{ label }}:</span>
    <router-link
      @click.native.stop
      :to="catalogRoute"
      class="caption font-weight-bold text-break primary--text">
      <span>{{ name }}</span>
    </router-link>
  </div>
</template>

<script>
import { getNasotItemConfig } from '@/common/nasot-schema/config-utils';

export default {
  name: 'nasot-link',
  inheritAttrs: false,
  props: {
    id: { type: Number, required: true },
    type: { type: String, required: true },
    name: { type: String, required: true }
  },
  computed: {
    label: vm => getNasotItemConfig(vm.type)?.label,
    catalogRoute: vm => ({
      name: 'catalog',
      query: { search: true, nasotItems: [vm.id] }
    })
  }
};
</script>
