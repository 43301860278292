<template>
  <v-text-field
    v-mask="mask"
    v-on="$listeners"
    v-bind="
      pick($attrs, [
        'value',
        'name',
        'label',
        'placeholder',
        'type',
        'autocomplete',
        'persistent-hint',
        'disabled',
        'readonly',
        'autofocus'
      ])
    "
    :hint="hint"
    :error-messages="errorMessages"
    :append-icon="innerIcon"
    :class="{ 'details-visible': errorMessages.length || hint }"
    outlined
    persistent-placeholder
    class="av-text-field" />
</template>

<script>
import { computed, toRefs } from 'vue';
import pick from 'lodash/pick';
import useFontawesomeIcon from '@/common/composables/useFontawesomeIcon';
import { VTextField } from 'vuetify/lib';

export default {
  name: 'av-text-field',
  inheritAttrs: false,
  props: {
    mask: { type: String, default: undefined },
    appendIcon: { type: String, default: undefined },
    appendIconStyle: { type: String, default: 'solid' },
    errorMessages: { type: [Array, String], default: () => [] },
    hint: { type: String, default: '' }
  },
  setup(props) {
    const { appendIcon, appendIconStyle, errorMessages } = toRefs(props);
    const hasErrors = computed(() => errorMessages.value.length);
    const { innerIcon } = useFontawesomeIcon(
      appendIcon,
      appendIconStyle,
      hasErrors
    );
    return { innerIcon };
  },
  methods: { pick },
  components: { VTextField }
};
</script>

<style lang="scss" scoped>
@import './input';

.av-text-field :deep(.v-input__control) {
  @extend %av-input;

  .v-input__slot {
    min-height: 3.125rem;
    margin-bottom: 0;
    background: var(--v-white-base);

    .v-text-field__slot {
      position: unset;
    }

    input {
      background-clip: text;

      &::placeholder {
        color: var(--v-grey-lighten4);
      }
    }

    .v-input__append-inner {
      margin-top: 0.875rem;
    }

    .v-input__icon svg {
      height: 1.25rem;
      color: var(--v-grey-lighten5) !important;
    }
  }

  .v-text-field__details {
    display: none;
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
}

.av-text-field {
  padding-top: 1.375rem;

  &.details-visible :deep(.v-text-field__details) {
    display: block;
  }

  &.v-input--is-focused :deep(.v-input__slot) {
    @extend %av-input-focus;
  }

  &.error--text :deep(.v-input__slot) {
    @extend %av-input-error-text;
  }

  :deep(.v-input__control) .v-input__slot:hover {
    background: var(--v-primary-lighten6);
  }

  &.v-input--is-disabled :deep(.v-input__control) .v-input__slot {
    @extend %av-input-disabled;
  }
}
</style>
