import { computed } from 'vue';
import store from '@/main/store';

export function useState(namespace, state) {
  return computed(() => store.state[namespace][state]);
}

export function useGetters(namespace, getters) {
  return getters.reduce(
    (obj, getter) => ({
      ...obj,
      [getter]: computed(() => store.getters[`${namespace}/${getter}`])
    }),
    {}
  );
}

export function useActions(namespace, actions) {
  return actions.reduce(
    (obj, action) => ({
      ...obj,
      [action]: async (...args) =>
        store.dispatch(`${namespace}/${action}`, ...args)
    }),
    {}
  );
}
