import LocalStorageWithExpiry from '@/common/utils/local-storage-with-expiry';

const localStorageWithExpiry = new LocalStorageWithExpiry();

const THIRTY_DAYS = 30 * 24 * 60 * 60 * 1000;
const FEATURE_BANNER_TTL =
  process.env.VUE_APP_FEATURE_BANNER_TTL || THIRTY_DAYS;

const getBannerStateKey = (featureName, id) =>
  `${featureName}-banner-state-${id}`;

export const initBannerState = ({ commit }, { featureName, id }) => {
  const bannerStateKey = getBannerStateKey(featureName, id);
  const bannerState = localStorageWithExpiry.getItem(bannerStateKey);
  if (bannerState) {
    commit('set', { ...bannerState, bannerStateKey });
    return;
  }
  localStorageWithExpiry.setItem(
    bannerStateKey,
    { hidden: false },
    Number(FEATURE_BANNER_TTL)
  );
  commit('set', { hidden: false, bannerStateKey });
};

export const hideBanner = ({ commit, state }) => {
  const bannerState = { hidden: true };
  localStorageWithExpiry.updateItem(state.bannerStateKey, bannerState);
  commit('set', { ...state, ...bannerState });
};
