<template>
  <section>
    <header class="d-flex justify-space-between mb-3 px-4 px-md-8">
      <h3 :class="[!isMobile ? 'text-body-bold' : 'text-h5', 'mr-2']">
        {{ title }}
      </h3>
      <router-link
        v-if="seeAllRoute"
        :to="seeAllRoute"
        class="flex-shrink-0 text-decoration-none primary--text text-button-text">
        See All >>
      </router-link>
    </header>
    <av-swiper :items="catalogItems" :data-testid="`${title}-swiper-items`">
      <template #default="{ item }">
        <component :is="getCardComponent(item)" v-bind="getCardProps(item)" />
      </template>
    </av-swiper>
  </section>
</template>

<script setup>
import AvSwiper from '@/common/components/base/Swiper';
import { computed } from 'vue';
import LearningObjectCard from '@/main/components/common/cards/LearningObjectCard';
import LivestreamCard from '@/main/components/common/cards/LivestreamCard';
import PlaylistCard from '@/main/components/common/cards/PlaylistCard';
import { ResourceType } from '@/main/models/catalog-item';
import { useVuetify } from '@/main/composables/useVuetify';

const props = defineProps({
  title: { type: String, required: true },
  seeAllRoute: { type: Object, default: null },
  catalogItems: { type: Array, required: true },
  size: { type: String, default: 'sm' }
});

const vuetify = useVuetify();
const isMobile = computed(() => !vuetify.breakpoint.mdAndUp);

const cardProps = {
  size: props.size,
  adjustHoverDirection: true
};
const cardComponents = {
  [ResourceType.LEARNING_OBJECT]: {
    card: LearningObjectCard,
    props: learningObject => ({ learningObject, ...cardProps })
  },
  [ResourceType.LIVESTREAM]: {
    card: LivestreamCard,
    props: livestream => ({ livestream, ...cardProps })
  },
  [ResourceType.PLAYLIST]: {
    card: PlaylistCard,
    props: playlist => ({ playlist, ...cardProps })
  }
};
const getCardComponent = item => {
  return cardComponents[item.resourceType].card;
};

const getCardProps = item => {
  return cardComponents[item.resourceType].props(item);
};
</script>
