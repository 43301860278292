<template>
  <router-link
    :to="{ name: 'dashboard' }"
    :class="[smAndUpBreakpoint ? 'ml-6' : 'ml-2']"
    aria-label="Go to homepage"
    class="logo-container">
    <primary-logo
      :width="smAndUpBreakpoint ? 128 : 112"
      role="img"
      title="Avanti logo"
      color="white" />
  </router-link>
</template>

<script>
export default {
  name: 'footer-logo',
  computed: {
    smAndUpBreakpoint: vm => vm.$vuetify.breakpoint.smAndUp
  }
};
</script>

<style lang="scss" scoped>
.logo-container {
  height: 2rem;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    height: 2.375rem;
  }
}
</style>
