<template>
  <div class="thumbnail" :class="`thumbnail-${size}`">
    <div class="catalog-item-image">
      <av-icon :size="iconSize" :name="icon" color="white" />
      <span class="catalog-item-label white--text text-uppercase">
        {{ label }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import Size from '@/main/components/common/cards/CatalogItemCard/size';

const IconSizes = {
  [Size.LG]: 90,
  [Size.MD]: 75,
  [Size.SM]: 60,
  [Size.XS]: 30
};

const props = defineProps({
  size: { type: String, default: Size.SM },
  label: { type: String, required: true },
  icon: { type: String, required: true }
});

const iconSize = computed(() => IconSizes[props.size]);
</script>

<style lang="scss" scoped>
@mixin apply-font($font-size, $line-height, $font-weight: 600) {
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
}

.thumbnail {
  position: relative;
  aspect-ratio: $av-aspect-ratio;

  .catalog-item-image {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 60%;
    height: 100%;
  }

  .catalog-item-label {
    @include apply-font(0.75rem, 0.75rem);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      @include apply-font(1.5rem, 1.75rem, 700);
    }
  }

  &-md {
    .catalog-item-label {
      @include apply-font(0.75rem, 0.75rem);

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        @include apply-font(1.25rem, 1.5rem, 700);
      }
    }
  }

  &-sm {
    .catalog-item-label {
      @include apply-font(0.75rem, 0.75rem);

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        @include apply-font(1rem, 1.25rem, 700);
      }
    }
  }

  &-xs {
    .catalog-item-label {
      @include apply-font(0.75rem, 0.75rem);

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        @include apply-font(0.5rem, 0.5rem, 700);
      }
    }
  }
}
</style>
