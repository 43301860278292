<template>
  <details
    ref="details"
    :open="internalIsOpen"
    :style="theme"
    :class="{ group: isGroup, closing: isClosing }"
    class="accordion">
    <v-hover v-slot="{ hover }">
      <summary
        ref="summary"
        @click.prevent="toggle"
        class="summary d-flex align-center">
        <span class="summary-content pa-4 d-flex align-center">
          <av-icon
            :size="12"
            name="angle-right"
            icon-style="solid"
            class="caret mr-2" />
          <slot
            name="summary"
            v-bind="{ hover, open: internalIsOpen && !isClosing }"></slot>
        </span>
      </summary>
    </v-hover>
    <div ref="content" class="content"><slot></slot></div>
  </details>
</template>

<script>
import { isGroupKey } from './internals';

const animation = {
  enter: {
    duration: 300,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  },
  leave: {
    duration: 250,
    easing: 'cubic-bezier(0.4, 0, 1, 1)'
  }
};

const Color = {
  PRIMARY: 'primary'
};

const colorValidator = value => {
  return [Color.PRIMARY].includes(value);
};

const ColorConfig = {
  [Color.PRIMARY]: {
    color: 'var(--v-primary-base)',
    backgroundColor: 'var(--v-primary-lighten6)'
  }
};

export default {
  name: 'av-controlled-accordion',
  inject: {
    isGroup: { from: isGroupKey }
  },
  props: {
    isOpen: { type: Boolean, default: false },
    color: {
      type: String,
      default: Color.PRIMARY,
      validator: colorValidator
    }
  },
  data: vm => ({
    internalIsOpen: vm.isOpen,
    animation: null,
    isClosing: false,
    isOpening: false
  }),
  computed: {
    theme() {
      const toMs = value => `${value}ms`;
      const colorConfig = ColorConfig[this.color];
      return {
        '--ease-in': animation.enter.easing,
        '--ease-out': animation.leave.easing,
        '--enter-duration': toMs(animation.enter.duration),
        '--leave-duration': toMs(animation.leave.duration),
        '--active-color': colorConfig.color,
        '--active-bg-color': colorConfig.backgroundColor
      };
    }
  },
  methods: {
    toggle() {
      if (this.isClosing || !this.isOpen) return this.$emit('open');
      if (this.isOpening || this.isOpen) return this.$emit('close');
    },
    open() {
      this.internalIsOpen = true;
      this.$refs.details.style.height = `${this.$refs.details.offsetHeight}px`;
      window.requestAnimationFrame(() => this.animateOpening());
    },
    close() {
      this.isClosing = true;
      if (this.animation) this.animation.cancel();
      this.animation = this.$refs.details.animate(
        {
          height: this.getAnimationHeightValues({ isOpen: false })
        },
        animation.leave
      );
      this.animation.onfinish = () => this.reset({ isOpen: false });
      this.animation.oncancel = () => {
        this.isClosing = false;
      };
    },
    animateOpening() {
      this.isOpening = true;
      if (this.animation) this.animation.cancel();
      this.animation = this.$refs.details.animate(
        {
          height: this.getAnimationHeightValues({ isOpen: true })
        },
        animation.enter
      );
      this.animation.onfinish = () => this.reset({ isOpen: true });
      this.animation.oncancel = () => {
        this.isOpening = false;
      };
    },
    getAnimationHeightValues({ isOpen }) {
      const toPx = value => `${value}px`;
      const start = this.$refs.details.offsetHeight;
      let end = this.$refs.summary.offsetHeight;
      if (isOpen) end = end + this.$refs.content.offsetHeight;
      return [start, end].map(toPx);
    },
    reset({ isOpen }) {
      this.internalIsOpen = isOpen;
      this.animation = null;
      this.isClosing = false;
      this.isOpening = false;
      this.$refs.details.style.height = '';
    }
  },
  watch: {
    isOpen: {
      handler(value) {
        if (value) return this.open();
        this.close();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/common/assets/stylesheets/utils/z-index';

$shadow: 0 -1px 1px rgba(0, 0, 0, 0.25);
$bottom-border-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
$shadow-active: inset 0 -2px 0 var(--active-color);
$enter-transition-speed: var(--enter-duration) var(--ease-in);
$leave-transition-speed: var(--leave-duration) var(--ease-in);

@mixin full-size-pseudo {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.accordion {
  position: relative;
  overflow: hidden;

  &::before {
    @include full-size-pseudo;

    top: 1px;
    height: calc(100% - 2px);
    opacity: 1;
    box-shadow: $shadow;
    z-index: z-index(content) - 1;
    transition: opacity $enter-transition-speed;
  }

  &[open]:not(.closing)::before {
    opacity: 0;
    transition: opacity $leave-transition-speed;
  }

  &:last-of-type::before {
    box-shadow: $bottom-border-shadow, $shadow;
  }
}

.caret {
  transform: rotate(0deg);
  transition: color $enter-transition-speed, transform $enter-transition-speed;

  .summary:hover &,
  .summary:focus & {
    transform: scale(1.33, 1.33);
    color: var(--active-color);
    transition: color $enter-transition-speed, transform $leave-transition-speed;
  }

  .accordion[open]:not(.closing) & {
    transform: rotate(90deg);
    color: var(--active-color);
    transition: color $enter-transition-speed, transform $leave-transition-speed;
  }
}

.summary {
  position: relative;
  list-style: none;

  &::-webkit-details-marker {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &::before {
    @include full-size-pseudo;

    background: var(--active-bg-color);
    opacity: 0;
    transition: opacity $enter-transition-speed;
  }

  &:hover::before,
  &:focus::before,
  .accordion[open]:not(.closing) &::before {
    opacity: 1;
    transition: opacity $leave-transition-speed;
  }

  &::after {
    @include full-size-pseudo;

    top: unset;
    bottom: 0;
    height: 2px;
    box-shadow: $shadow-active;
    opacity: 0;
    transition: opacity $enter-transition-speed;
  }

  .accordion[open]:not(.closing) &::after {
    opacity: 1;
    transition: opacity $leave-transition-speed;
  }

  &-content {
    @include z-index(content);

    width: 100%;
    height: 100%;
    transition: color $enter-transition-speed;
  }

  &:hover &-content,
  &:focus &-content,
  .accordion[open]:not(.closing) &-content {
    color: var(--active-color);
    transition: color $leave-transition-speed;
  }
}

.content {
  @include z-index(content);

  position: relative;
  background: var(--active-bg-color);
}
</style>
