<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.631 41.019">
    <path
      d="M28.16 29.863V3.853A3.382 3.382 0 0 0 24.778.471H5.619A5.147 5.147 0 0 0 .471 5.618l.276 27.22Z"
      fill="#fff"></path>
    <path
      d="M.747 33.314a.472.472 0 0 1-.471-.466L0 5.623A5.626 5.626 0 0 1 5.619 0h19.159a3.858 3.858 0 0 1 3.854 3.853v26.01a.471.471 0 0 1-.42.469L.8 33.311H.749M5.619.942A4.682 4.682 0 0 0 .942 5.619l.271 26.7 26.476-2.878V3.853A2.914 2.914 0 0 0 24.778.942ZM28.16 29.864Z"
      fill="currentColor"></path>
    <path
      d="M28.16 35.822H3.727a2.98 2.98 0 1 1 0-5.959H28.16a9.979 9.979 0 0 0-.558 1.346 5.33 5.33 0 0 0-.405 1.53c-.086 1.134.574 2.067.963 3.083"
      fill="#fff"></path>
    <path
      d="M28.16 36.293H3.727a3.451 3.451 0 0 1 0-6.9H28.16a.471.471 0 0 1 .351.786c-.063.125-.2.47-.4 1.029l-.065.175a4.9 4.9 0 0 0-.374 1.394 4.161 4.161 0 0 0 .566 2.04c.126.27.256.55.366.839a.471.471 0 0 1-.44.64M3.727 30.334a2.508 2.508 0 1 0 0 5.016h23.717l-.064-.139a4.939 4.939 0 0 1-.652-2.509 5.744 5.744 0 0 1 .436-1.667l.058-.157c.077-.209.144-.39.2-.546Zm24.435 0Z"
      fill="currentColor"></path>
    <path fill="#fff" d="M24.053 32.843H5.908"></path>
    <path
      d="M24.053 33.314H5.907a.471.471 0 1 1 0-.942h18.146a.471.471 0 1 1 0 .942"
      fill="currentColor"></path>
    <path
      d="m10.092 40.454-1.072-.839a.433.433 0 0 0-.535 0l-1.072.839a.433.433 0 0 1-.7-.341V33.28a.433.433 0 0 1 .433-.433h3.213a.433.433 0 0 1 .434.433v6.837a.433.433 0 0 1-.7.341"
      fill="#fff"></path>
    <path
      d="M10.358 41.019a.9.9 0 0 1-.556-.193l-1.049-.821-1.049.821a.9.9 0 0 1-1.462-.713v-6.837a.906.906 0 0 1 .9-.9h3.213a.906.906 0 0 1 .9.9v6.837a.908.908 0 0 1-.906.906m-3.174-7.7v6.721l1.011-.792a.9.9 0 0 1 1.116 0l1.011.791v-6.721Z"
      fill="currentColor"></path>
  </svg>
</template>

<script>
export default { name: 'av-book-outline-icon' };
</script>
