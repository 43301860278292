<template>
  <div class="d-flex flex-column">
    <slot v-for="item in items" name="item" :item="item"></slot>
  </div>
</template>

<script>
import { isGroupKey } from './internals';

export default {
  name: 'av-accordion-group',
  provide: { [isGroupKey]: true },
  props: { items: { type: Array, default: () => [] } }
};
</script>
