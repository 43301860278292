<template>
  <v-divider
    :vertical="vertical"
    :style="{ width, borderWidth: `${borderWidth}px` }"
    class="av-divider" />
</template>

<script>
import { VDivider } from 'vuetify/lib';

export default {
  name: 'av-divider',
  props: {
    width: { type: String, default: '100%' },
    borderWidth: { type: Number, default: 3 },
    vertical: { type: Boolean, default: false }
  },
  components: { VDivider }
};
</script>

<style lang="scss" scoped>
.av-divider {
  border-radius: 5px;
  border-color: var(--v-secondary-base);
}
</style>
