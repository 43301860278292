import _truncate from 'lodash/truncate';
import fecha from 'fecha';
import formatDuration from 'format-duration';
import isNaN from 'lodash/isNaN';

const DEFAULT_DATE_FORMAT = 'MM/DD/YY';

const isObject = arg => arg !== null && typeof arg === 'object';

export function formatDate(value, dateFormat = DEFAULT_DATE_FORMAT) {
  return value && fecha.format(new Date(value), dateFormat);
}

export function truncate(value, config) {
  config = isObject(config) ? config : { length: config };
  return value && _truncate(value, config);
}

export function humanizeDuration(seconds, leading = true) {
  const ms = seconds * 1000;
  return formatDuration(isNaN(ms) ? 0 : ms, { leading });
}

export const convertHyperlinkMarkdownToHTML = markdown => {
  return markdown.replace(
    /\[([^\]]+)]\(([^)]+)\)/g,
    '<a href="$2" target="_blank" class="markdown-link">$1</a>'
  );
};
