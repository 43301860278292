<template>
  <div class="completed-overlay">
    <div class="card-overlay"></div>
    <av-icon
      name="check"
      icon-style="solid"
      aria-label="Completed"
      size="40"
      class="icon circle" />
  </div>
</template>

<script>
export default {
  name: 'completed-overlay'
};
</script>

<style lang="scss" scoped>
@import '~@/common/stylesheets/border-radius';
@import '~@/common/assets/stylesheets/utils/z-index';

.completed-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .card-overlay {
    position: absolute;
    inset: 0;
    background-color: var(--v-white-base);
    opacity: 0.4;
    z-index: z-index(content) - 1;
  }

  .icon {
    padding: 2.2rem;
    color: var(--v-white-base);
    background-color: var(--v-primary-base);
  }
}
</style>
