import { ACTIONS } from '@/main/models/achievements';

const THRESHOLD_DAILY = 7;
const THRESHOLD_WEEKLY = 4;
const THRESHOLD_MONTHLY = 3;

export const BADGE_NAME = 'STREAK';

export const TIME_PERIOD = {
  DAY: {
    name: 'day',
    value: 'DAY'
  },
  WEEK: {
    name: 'week',
    value: 'WEEK'
  },
  MONTH: {
    name: 'month',
    value: 'MONTH'
  },
  get values() {
    return [this.DAY, this.WEEK, this.MONTH];
  }
};

export const STREAK_THRESHOLDS = {
  [TIME_PERIOD.DAY.value]: THRESHOLD_DAILY,
  [TIME_PERIOD.WEEK.value]: THRESHOLD_WEEKLY,
  [TIME_PERIOD.MONTH.value]: THRESHOLD_MONTHLY
};

export const MILESTONES_MAPPINGS = {
  strategiesApplied: {
    position: 1,
    resource: 'Strategy',
    action: ACTIONS.APPLIED
  },
  strategiesWatched: {
    position: 2,
    resource: 'Strategy',
    action: ACTIONS.WATCHED
  },
  strategiesInProgress: {
    position: 3,
    resource: 'Strategy',
    action: ACTIONS.IN_PROGRESS
  }
};

export const StreakMessages = {
  beforeThreshold: {
    title: 'Hip Hip, Hooray!',
    message: `You're on a roll! Remember to come back to keep your streak up!`
  },
  afterThreshold: {
    title: 'Keep it up!',
    message: `You're making great progress! Remember to come back to keep your streak up.`
  }
};
