<template>
  <div ref="swiper" class="av-swiper swiper">
    <div class="swiper-wrapper">
      <div v-for="item in items" :key="item[itemId]" class="swiper-slide slide">
        <slot v-bind="{ item }"></slot>
      </div>
    </div>
    <div class="swiper-button swiper-button-prev">
      <av-icon class="icon" name="chevron-left" icon-style="solid" />
    </div>
    <div class="swiper-button swiper-button-next">
      <av-icon class="icon" name="chevron-right" icon-style="solid" />
    </div>
  </div>
</template>

<script>
import { A11y, Navigation } from 'swiper/modules';
import Swiper from 'swiper';

export default {
  name: 'av-swiper',
  props: {
    items: { type: Array, required: true },
    itemId: { type: String, default: 'id' }
  },
  computed: {
    isMobile: vm => vm.$vuetify.breakpoint.smAndDown,
    swiperOptions: vm => ({
      spaceBetween: 32,
      slidesPerView: 'auto',
      allowTouchMove: vm.isMobile,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      modules: [A11y, Navigation]
    })
  },
  mounted() {
    return new Swiper(this.$refs.swiper, this.swiperOptions);
  }
};
</script>

<style lang="scss">
@import 'swiper/scss';
@import 'swiper/scss/navigation';
</style>

<style lang="scss" scoped>
@import '~@/common/assets/stylesheets/utils/z-index';

.av-swiper {
  @include z-index(swiper);

  position: relative;

  :deep(.swiper-wrapper) {
    box-sizing: border-box;
  }

  .slide {
    width: fit-content;
  }

  .swiper-button {
    visibility: hidden;

    &::after {
      display: none;
    }

    :deep(.icon) svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .av-swiper {
    overflow: visible;
  }

  .swiper-button {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4%;
    height: 100%;
    margin-top: 0;
    cursor: pointer;

    .icon {
      color: var(--v-white-base);
    }

    &-prev {
      left: 0;
    }

    &-next {
      right: 0;
    }

    &:hover,
    &:focus {
      background: rgba(33, 33, 33, 0.5);

      .icon {
        transform: scale(1.25);
      }
    }
  }

  .av-swiper:hover,
  .av-swiper:focus {
    .swiper-button {
      visibility: visible;
    }

    .swiper-button.swiper-button-disabled {
      visibility: hidden;
    }
  }
}
</style>
