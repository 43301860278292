<template>
  <v-app id="app" :style="globalStyleProperties" class="system-font-stack">
    <av-loading v-if="isLoading" />
    <v-main v-else class="main">
      <router-view class="view" />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { initAnalyticsMixin } from '@/main/analytics';
import pick from 'lodash/pick';

const trackedUserProperties = [
  'email',
  'fullName',
  'country',
  'state',
  'verified',
  'completedOnboarding',
  'experienceLevel',
  'organization',
  'hasFreeAccess',
  'isOrganizationMember',
  'subscriptionStart',
  'subscriptionEnd'
];
const appId = process.env.VUE_APP_ANALYTICS_ID;
const satismeterWriteKey = process.env.VUE_APP_SATISMETER_WRITE_KEY;

export default {
  name: 'app',
  provide() {
    const appSettings = {};
    const props = ['navbarHeight', 'footerHeight'];
    props.forEach(prop => {
      Object.defineProperty(appSettings, prop, {
        enumerable: true,
        get: () => this[prop]
      });
    });
    return { appSettings };
  },
  mixins: [initAnalyticsMixin(appId, satismeterWriteKey)],
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['isDemoUser']),
    ...mapGetters('profile', ['profile']),
    isLoading: vm => vm.$auth.loading,
    navbarHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? 80 : 60;
    },
    footerHeight() {
      return this.$vuetify.breakpoint.smAndUp ? 80 : 48;
    },
    upgradeBannerHeight() {
      if (this.$vuetify.breakpoint.lgAndUp) return 84;
      if (this.$vuetify.breakpoint.md) return 100;
      return 120;
    },
    trackedUserProperties({ user, profile }) {
      const userProperties = pick(
        { ...user, ...profile },
        trackedUserProperties
      );
      userProperties.isDemoUser = this.isDemoUser;
      return userProperties;
    },
    globalStyleProperties() {
      return {
        '--navbar-height': `${this.navbarHeight}px`,
        '--footer-height': `${this.footerHeight}px`,
        '--upgrade-banner-height': `${this.upgradeBannerHeight}px`
      };
    }
  },
  watch: {
    user: {
      immediate: true,
      handler(value) {
        if (!appId) return;
        if (!value) return this.heapResetIdentity();
        this.satismeterUser(value);
        this.heapIdentifyUser(value);
      }
    }
  }
};
</script>

<style lang="scss">
@import './stylesheets/main';

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: thin;
  scrollbar-color: var(--v-primary-base) var(--v-grey-lighten5);

  ::-webkit-scrollbar {
    width: 0.3125rem;
    background-color: var(--v-grey-lighten5);
  }

  ::-webkit-scrollbar-thumb {
    background: var(--v-primary-base);
    border-radius: $border-radius-xs;
  }

  .main {
    padding-top: 0 !important;
  }

  .main .v-main__wrap {
    display: flex;
    flex-flow: column nowrap;

    a {
      color: var(--v-primary-lighten2);
      text-decoration: none;
    }
  }
}
</style>
