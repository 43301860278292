import { render, staticRenderFns } from "./BadgeIcon.vue?vue&type=template&id=2cbd52fa&scoped=true&"
var script = {}
import style0 from "./BadgeIcon.vue?vue&type=style&index=0&id=2cbd52fa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cbd52fa",
  null
  
)

export default component.exports