import orderBy from 'lodash/orderBy';

export const activeSubscription = state =>
  state.subscriptions.find(it => it.isActive);

export const latestSubscription = state =>
  orderBy(state.subscriptions, 'createdAt', 'desc')[0];

export const isTrialActive = (_, getters) =>
  !!getters.activeSubscription?.isTrialActive;
