<template>
  <div class="search-bar">
    <v-autocomplete
      ref="searchInput"
      v-model="selected"
      @input="openResults"
      @click:append="$emit('click:append')"
      :items="options"
      :search-input.sync="query"
      :disabled="isNavigating"
      :append-icon="innerIcon"
      data-testid="catalog-search"
      item-text="title"
      item-value="query"
      placeholder="Search for topics or titles"
      auto-select-first
      no-filter
      attach
      rounded
      flat
      hide-details
      hide-no-data
      background-color="white"
      color="primary">
      <template #item="{ item }">
        <div>
          <div class="search-title">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <span v-html="parseTitleMatch(item.title)"></span>
            <span v-if="item.titleAppend"> {{ item.titleAppend }}</span>
          </div>
          <div v-if="item.subtitle" class="search-subtitle">
            {{ item.subtitle }}
          </div>
        </div>
      </template>
      <template #append v-if="isLoading">
        <div class="v-input__icon">
          <av-circular-progress size="15" width="2" />
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import catalogApi from '@/main/api/catalog';
import debounce from 'lodash/debounce';
import escapeStringRegexp from 'escape-string-regexp';
import find from 'lodash/find';
import { mapGetters } from 'vuex';
import memoize from 'lodash/memoize';
import pMinDelay from 'p-min-delay';
import { ResourceType } from '@/main/models/catalog-search-option';
import { toRefs } from 'vue';
import useFontawesomeIcon from '@/common/composables/useFontawesomeIcon';

export default {
  name: 'catalog-search',
  props: {
    appendIcon: { type: String, default: null },
    appendIconStyle: { type: String, default: 'solid' }
  },
  setup(props) {
    const { appendIcon, appendIconStyle } = toRefs(props);
    const { innerIcon } = useFontawesomeIcon(appendIcon, appendIconStyle);
    return { innerIcon };
  },
  data: () => ({
    selected: null,
    isNavigating: false,
    isLoading: false,
    query: '',
    searchOptions: []
  }),
  computed: {
    ...mapGetters('nasot', ['nasotItems']),
    noQuery: ({ query }) => !query || query.length < 2,
    options() {
      if (this.noQuery) return [];
      const allResultsOption = {
        title: `${this.query}`,
        titleAppend: '[All results]',
        query: { fuzzy: this.query }
      };
      const searchOptions = this.searchOptions.map(it => ({
        id: it.resourceId,
        title: it.name,
        subtitle: this.getSubtitle(it.nasotParentId),
        query:
          it.resourceType === ResourceType.NASOT_ITEM
            ? { nasotItems: it.resourceId }
            : { title: it.name }
      }));
      return [allResultsOption, ...searchOptions];
    }
  },
  methods: {
    focus() {
      return this.$refs.searchInput.$refs.input.focus();
    },
    fetch: memoize(function (fuzzy) {
      return catalogApi.getSearchOptions({ name: { fuzzy } });
    }),
    fetchSearchOptions: debounce(
      function (query) {
        this.isLoading = true;
        return pMinDelay(this.fetch(query), 700)
          .then(({ items }) => {
            this.searchOptions = items;
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      500,
      { maxWait: 1000 }
    ),
    getSubtitle(nasotItemId) {
      const { name } = find(this.nasotItems, { id: nasotItemId }) || {};
      return name && `in ${name}`;
    },
    // TODO: Extract catalog query/navigation to contain logic in one place
    openResults(searchQuery) {
      this.isNavigating = true;
      const { $route } = this;
      const query = { search: true, ...searchQuery };
      if ($route.name === 'catalog') {
        query.audience = $route.query.audience;
        query.sortBy = $route.query.sortBy;
        query.sortAsc = $route.query.sortAsc;
      }
      return this.$router
        .push({ name: 'catalog', query })
        .catch(() => {})
        .finally(() => {
          this.query = '';
          this.selected = null;
          this.isNavigating = false;
        });
    },
    parseTitleMatch(title) {
      const query = escapeStringRegexp(this.query);
      return query
        .trim()
        .split(' ')
        .filter(Boolean)
        .reduce(wrapMatchingSubstring, title);
    }
  },
  watch: {
    query(val = '') {
      if (this.noQuery) {
        this.searchOptions = [];
        return;
      }
      this.fetchSearchOptions(val);
    }
  }
};

function wrapMatchingSubstring(str, term) {
  return str.replace(
    new RegExp(`${term}(?!m?a?r?k?>)`, 'gi'),
    match => `<mark>${match}</mark>`
  );
}
</script>

<style lang="scss" scoped>
.search-bar {
  min-width: 20.3125rem;

  :deep(input) {
    color: var(--v-grey-base);
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  :deep(input::placeholder) {
    opacity: 1;
    color: var(--v-grey-lighten4);
  }

  :deep(.v-input__slot) {
    height: 2.625rem;
    border-radius: 25px;
  }

  :deep(.v-select) .v-input__icon--append .v-icon {
    transform: none;
  }

  :deep(.v-select) .v-input__icon--append svg {
    height: 0.9375rem;
    color: var(--v-grey-lighten5) !important;
  }

  :deep(.v-menu__content) {
    max-height: none !important;
    margin-top: 0.1875rem;
    border-radius: 12px;
  }

  :deep(.v-list) {
    max-height: calc(100vh - 4rem) !important;
    padding: 0;
    overflow-y: auto;
  }

  :deep(.v-list-item) {
    padding: 1rem 0.875rem;

    .search-title {
      font-size: 1rem;
      font-weight: normal;
      line-height: 1.125rem;
    }

    mark {
      color: var(--v-grey-base);
      font-weight: 700;
      background-color: inherit;
    }

    .search-subtitle {
      color: var(--v-grey-lighten4);
      font-size: 0.875rem;
      font-weight: bold;
      line-height: 1rem;
    }

    &::before,
    &::after {
      content: none;
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--v-grey-lighten4);
    }

    &.v-list-item--highlighted {
      background: var(--v-primary-lighten5);

      .search-title,
      mark {
        color: var(--v-primary-base);
      }

      .search-subtitle {
        color: var(--v-primary-lighten2);
      }
    }

    .v-ripple__container {
      display: none;
    }
  }
}
</style>
