export function initAnalyticsMixin(appId, writeKey) {
  if (!appId) return {};
  return {
    head: {
      script: [
        {
          type: 'text/javascript',
          inner: getSatismeterCode(),
          async: true,
          body: false
        },
        {
          type: 'text/javascript',
          inner: getHeapCode(appId),
          async: false,
          body: false
        }
      ]
    },
    methods: {
      satismeterUser(user) {
        window.satismeter({
          writeKey,
          userId: user.id,
          traits: {
            name: user.fullName,
            email: user.email
          }
        });
      },
      heapIdentifyUser(user) {
        this.$nextTick(() => window.heap.identify(user.id));
      },
      heapResetIdentity() {
        this.$nextTick(() => window.heap.resetIdentity());
      }
    }
  };
}

function getSatismeterCode() {
  return `window.satismeter = window.satismeter || function() {(window.satismeter.q = window.satismeter.q || []).push(arguments);};window.satismeter.l = 1 * new Date();var script = document.createElement("script");var parent = document.getElementsByTagName("script")[0].parentNode;script.async = 1;script.src = "https://app.satismeter.com/js";parent.appendChild(script);`;
}

function getHeapCode(id) {
  return `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])}; heap.load("${id}");`;
}
