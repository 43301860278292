import { NasotItemType } from '@/common/models/nasot-item';
import { toTreeFormat } from '@/common/nasot-schema/structure-utils';

export const nasotItems = state => state.items;

export const elements = (_, { nasotItems }) => {
  return nasotItems.filter(it => it.type === NasotItemType.ELEMENT);
};

export const sortedNasotTree = (_, { nasotItems }) => {
  return toTreeFormat(nasotItems);
};

export const sortedStrands = (_, { sortedNasotTree }) => {
  const hasElements = nasotItem => !!nasotItem.children?.length;
  return sortedNasotTree.reduce((acc, { children }) => {
    const strands = children?.filter(hasElements) || [];
    return [...acc, ...strands];
  }, []);
};
