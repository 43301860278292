<template>
  <div class="mt-7 d-flex flex-md-row flex-column">
    <div class="d-flex">
      <av-btn
        :to="autoPlayVideoRoute"
        :class="isMobile ? 'flex-grow-1 text-h4' : 'flex-grow-0'"
        color="white">
        <av-icon
          :size="iconSize"
          name="triangle"
          icon-style="solid"
          class="fa-rotate-90 mr-2" />
        {{ videoButtonText }}
      </av-btn>
    </div>
    <div class="d-flex">
      <av-btn
        :to="learningObjectRoute"
        :class="isMobile ? 'flex-grow-1 text-h4 mt-6' : 'flex-grow-0 ml-5'"
        color="white"
        variant="secondary">
        More Info
      </av-btn>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  videoButtonText: { type: String, required: true },
  learningObject: { type: Object, required: true },
  isMobile: { type: Boolean, default: false },
  iconSize: { type: Number, required: true }
});

const autoPlayVideoRoute = computed(() => ({
  name: 'learningObject',
  params: { publicId: props.learningObject.publicId },
  query: {
    playVideo: true,
    ...(props.learningObject.progress && {
      progress: props.learningObject.progress
    })
  }
}));
const learningObjectRoute = computed(() => ({
  name: 'learningObject',
  params: { publicId: props.learningObject.publicId }
}));
</script>
