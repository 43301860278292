<template>
  <!-- TODO: consolidate all non theme colors -->
  <av-chip color="#d9342b" text-color="white" :icon="icon" :size="ChipSize.SM">
    Starts in {{ startsIn }} minutes
  </av-chip>
</template>

<script setup>
import { Size as ChipSize } from '@/common/components/base/Chip.vue';
import { computed } from 'vue';
import { millisecondsToMinutes } from 'date-fns';

const props = defineProps({
  startDate: { type: Date, required: true },
  now: { type: Date, default: () => new Date() }
});

const startsIn = computed(() => {
  const diffInMilliseconds = props.startDate - props.now;
  return millisecondsToMinutes(diffInMilliseconds) + 1;
});

const icon = { name: 'video', style: 'regular', size: 12 };
</script>
