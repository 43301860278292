<template>
  <div>
    <div class="progress-label" :class="[`progress-label-${size}`]">
      <av-icon
        v-if="!isCompleted && !isSmall"
        name="clock"
        icon-style="regular"
        :size="iconSize"
        class="icon mr-2" />
      <av-icon
        v-if="isCompleted"
        name="circle-check"
        icon-style="solid"
        :size="iconSize"
        class="icon mr-2" />
      <span v-if="!isStarted">{{ humanizedDuration }}</span>
      <span v-else-if="!isCompleted">{{ minutesLeft }}m left</span>
      <span v-else>Watched</span>
    </div>
    <av-linear-progress
      :value="isCompleted ? 100 : progress"
      :background-color="$vuetify.theme.themes.light.primary.lighten5"
      :height="6"
      :aria-label="progressLabel"
      color="primary" />
  </div>
</template>

<script>
import { humanizeDuration } from '@/common/utils/formatters';
import { PROGRESS_COMPLETION_THRESHOLD } from '@/common/models/progress';
import Size from '@/main/components/common/cards/CatalogItemCard/size';

export default {
  name: 'learning-object-card-progress',
  props: {
    progress: { type: Number, required: true },
    duration: { type: Number, required: true },
    size: { type: String, required: true }
  },
  computed: {
    isMobile: vm => !vm.$vuetify.breakpoint.mdAndUp,
    iconSize: vm => (vm.isMobile ? 14 : 16),
    isSmall: vm => vm.size === Size.SM,
    isStarted: vm => vm.progress > 0,
    isCompleted: vm => vm.progress >= PROGRESS_COMPLETION_THRESHOLD,
    progressLabel: vm => `${vm.progress}% of the video watched`,
    humanizedDuration: vm =>
      vm.duration ? humanizeDuration(vm.duration, false) : '--/--',
    minutesLeft() {
      const secondsLeft = this.duration - (this.duration * this.progress) / 100;
      return Math.ceil(secondsLeft / 60);
    }
  }
};
</script>

<style lang="scss" scoped>
.progress-label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  margin-left: 1.3rem;

  .icon {
    color: var(--v-white-base);
  }

  span {
    font-size: 0.875rem;
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    &-lg span,
    &-md span {
      font-size: 1rem;
    }
  }
}
</style>
