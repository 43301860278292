<template>
  <div class="mb-2">
    <av-chip
      v-for="{ name, color } in visibleAudiences"
      :key="name"
      class="mr-1"
      :color="color || whiteTransparent"
      text-color="white"
      :size="chipSize.SM">
      {{ name }}
    </av-chip>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <av-chip
          v-if="hiddenAudiencesCount"
          v-on="on"
          v-bind="attrs"
          :color="whiteTransparent"
          text-color="white"
          :size="chipSize.SM">
          +{{ hiddenAudiencesCount }}
        </av-chip>
      </template>
      <span>{{ hiddenAudencesLabel }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { Size as ChipSize } from '@/common/components/base/Chip';
import Size from '../size';

const WHITE_TRANSPARENT = '#ffffff33';
const ALL_AUDIENCES = { name: 'All Audiences' };

export default {
  name: 'card-audiences',
  props: {
    size: { type: String, required: true },
    audiences: { type: Array, required: true }
  },
  computed: {
    isMobile: vm => !vm.$vuetify.breakpoint.mdAndUp,
    chipSize: () => ChipSize,
    whiteTransparent: () => WHITE_TRANSPARENT,
    visibleAudiencesLimit: ({ size, isMobile }) =>
      size === Size.SM || isMobile ? 1 : 2,
    visibleAudiences: ({ audiences, visibleAudiencesLimit }) => {
      // TODO: discuss what's the best audience to show first;
      // should the current user audience be a priority to display
      // or do we want to show other audiences first
      // and the current one in the +N tooltip
      return audiences.length
        ? audiences.slice(0, visibleAudiencesLimit)
        : [ALL_AUDIENCES];
    },
    hiddenAudiencesCount: ({ audiences, visibleAudiences }) => {
      return audiences.length && audiences.length - visibleAudiences.length;
    },
    hiddenAudiences: vm => vm.audiences.slice(-vm.hiddenAudiencesCount),
    hiddenAudencesLabel: ({ hiddenAudiences }) =>
      hiddenAudiences.map(it => it.name).join(', ')
  }
};
</script>
