<template>
  <av-dialog
    v-on="$listeners"
    :value="isActive"
    :width="width"
    :show-close-btn="false">
    <template #content>
      <div class="px-6 pt-4">
        <h1 class="upgrade-dialog-title">{{ text.header }}</h1>
        <p class="mt-2">{{ text.body }}</p>
      </div>
    </template>
    <template #actions>
      <div
        :class="{ 'flex-column align-center flex-column-reverse': isMobile }"
        class="pa-4 d-flex justify-center">
        <av-btn
          @click="$emit('close')"
          :class="{ 'mr-0 mt-2': isMobile }"
          variant="text"
          class="mr-2">
          Keep Browsing
        </av-btn>
        <av-btn @click="loginDemoUser" color="secondary">
          Get Full Access
        </av-btn>
      </div>
    </template>
  </av-dialog>
</template>

<script>
import { mapActions } from 'vuex';

const UPGRADE_DIALOG_TEXT = {
  limit: {
    header: 'It’s time to upgrade',
    body: 'You’ve reached the limit for Avanti Reveal access. To continue your learning, you’ll need to verify your account by starting a 7-day free trial.'
  },
  community: {
    header: 'Upgrade to visit the community',
    body: 'Access to the the community requires a verified account. Would you like to sign up now?'
  },
  livestreams: {
    header: 'Upgrade to watch livestreams',
    body: 'Access to livestreams requires a verified account. Would you like to sign up now?'
  },
  learning: {
    header: 'Upgrade to visit learning activity',
    body: 'To view your learning activity, you’ll need to verify your account by starting a 7-day free trial.'
  }
};

export default {
  name: 'upgrade-dialog',
  model: { prop: 'isActive' },
  props: {
    isActive: { type: Boolean, required: true },
    variant: {
      type: String,
      default: 'limit',
      validator: val =>
        ['limit', 'community', 'livestreams', 'learning'].includes(val)
    }
  },
  computed: {
    text: vm => UPGRADE_DIALOG_TEXT[vm.variant],
    isMobile: vm => !vm.$vuetify.breakpoint.mdAndUp,
    width: vm => (vm.isMobile ? 260 : 500)
  },
  methods: mapActions('auth', ['loginDemoUser'])
};
</script>

<style lang="scss" scoped>
.upgrade-dialog-title {
  font-size: 1.5rem;
}
</style>
