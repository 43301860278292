<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="currentColor">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.48571 15.5429C5.24323 15.5429 5.01068 15.6392 4.83922 15.8106C4.66775 15.9821 4.57143 16.2147 4.57143 16.4571V30.1714H1.82857V0H0V31.0857C0 31.3282 0.0963264 31.5608 0.267788 31.7322C0.43925 31.9037 0.671802 32 0.914286 32H32V30.1714H30.1714V5.48571C30.1714 5.24323 30.0751 5.01068 29.9036 4.83922C29.7322 4.66775 29.4996 4.57143 29.2571 4.57143H23.7714C23.5289 4.57143 23.2964 4.66775 23.1249 4.83922C22.9535 5.01068 22.8571 5.24323 22.8571 5.48571V30.1714H21.0286V12.8C21.0286 12.5575 20.9322 12.325 20.7608 12.1535C20.5893 11.982 20.3568 11.8857 20.1143 11.8857H14.6286C14.3861 11.8857 14.1535 11.982 13.9821 12.1535C13.8106 12.325 13.7143 12.5575 13.7143 12.8V30.1714H11.8857V16.4571C11.8857 16.2147 11.7894 15.9821 11.6179 15.8106C11.4465 15.6392 11.2139 15.5429 10.9714 15.5429H5.48571ZM24.6857 30.1714H28.3429V17.3714H24.6857V30.1714ZM28.3429 15.5429H24.6857V6.4H28.3429V15.5429ZM15.5429 21.0286V30.1714H19.2V21.0286H15.5429ZM19.2 19.2H15.5429V13.7143H19.2V19.2ZM6.4 24.6857V30.1714H10.0571V24.6857H6.4ZM10.0571 22.8571H6.4V17.3714H10.0571V22.8571Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.48571 15.5429C5.24323 15.5429 5.01068 15.6392 4.83922 15.8106C4.66775 15.9821 4.57143 16.2147 4.57143 16.4571V30.1714H1.82857V0H0V31.0857C0 31.3282 0.0963264 31.5608 0.267788 31.7322C0.43925 31.9037 0.671802 32 0.914286 32H32V30.1714H30.1714V5.48571C30.1714 5.24323 30.0751 5.01068 29.9036 4.83922C29.7322 4.66775 29.4996 4.57143 29.2571 4.57143H23.7714C23.5289 4.57143 23.2964 4.66775 23.1249 4.83922C22.9535 5.01068 22.8571 5.24323 22.8571 5.48571V30.1714H21.0286V12.8C21.0286 12.5575 20.9322 12.325 20.7608 12.1535C20.5893 11.982 20.3568 11.8857 20.1143 11.8857H14.6286C14.3861 11.8857 14.1535 11.982 13.9821 12.1535C13.8106 12.325 13.7143 12.5575 13.7143 12.8V30.1714H11.8857V16.4571C11.8857 16.2147 11.7894 15.9821 11.6179 15.8106C11.4465 15.6392 11.2139 15.5429 10.9714 15.5429H5.48571ZM24.6857 30.1714H28.3429V17.3714H24.6857V30.1714ZM28.3429 15.5429H24.6857V6.4H28.3429V15.5429ZM15.5429 21.0286V30.1714H19.2V21.0286H15.5429ZM19.2 19.2H15.5429V13.7143H19.2V19.2ZM6.4 24.6857V30.1714H10.0571V24.6857H6.4ZM10.0571 22.8571H6.4V17.3714H10.0571V22.8571Z"
      fill="url(#paint0_linear_4267_8070)"
      style="mix-blend-mode: overlay" />
    <defs>
      <linearGradient
        id="paint0_linear_4267_8070"
        x1="7.68"
        y1="10.24"
        x2="25.0577"
        y2="23.4566"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#C27AEE" />
        <stop offset="1" stop-color="#63D5EA" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default { name: 'av-chart-gradient-icon' };
</script>
