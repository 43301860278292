<template>
  <div class="d-flex align-center">
    <div
      class="recording-info-section px-2 py-1 white text-caption-title error--text">
      Recorded Live {{ startDatelabel }}
    </div>
    <span
      :class="isMobile ? 'text-caption-title' : 'text-h5'"
      class="ml-2 white--text">
      {{ recordLength }}
    </span>
  </div>
</template>

<script>
import { formatDate, humanizeDuration } from '@/common/utils/formatters';

export default {
  name: 'recording-info-section',
  props: {
    startDate: { type: Date, required: true },
    endDate: { type: Date, required: true }
  },
  computed: {
    isMobile: vm => vm.$vuetify.breakpoint.smAndDown,
    startDatelabel() {
      return formatDate(this.startDate);
    },
    recordLength() {
      const diffInSeconds = (this.endDate - this.startDate) / 1000;
      return humanizeDuration(diffInSeconds);
    }
  }
};
</script>

<style lang="scss" scoped>
.recording-info-section {
  border-radius: 3px;
}
</style>
