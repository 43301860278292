<template>
  <v-hover
    v-model="isActive"
    :data-testid="$attrs['data-testid']"
    :disabled="isExpansionDisabled"
    open-delay="200">
    <router-link
      ref="card"
      @focus.native="expandCard"
      @blur.native="collapseCard"
      :to="catalogItemRoute"
      :class="`catalog-item-card-${size}`"
      class="catalog-item-card d-block">
      <card-thumbnail
        @create:bookmark="createBookmark(id)"
        @delete:bookmark="deleteBookmark(id)"
        v-bind="{
          id,
          size,
          title,
          isBookmarked,
          audiences,
          isActive
        }"
        class="card-main-thumbnail">
        <template #thumbnail><slot name="thumbnail"></slot></template>
        <template #tasks><slot name="tasks"></slot></template>
        <template #footer><slot name="footer"></slot></template>
      </card-thumbnail>
      <transition name="fade-and-scale">
        <div v-if="isActive" class="card-hover">
          <card-thumbnail
            @create:bookmark="createBookmark(id)"
            @delete:bookmark="deleteBookmark(id)"
            v-bind="{
              id,
              publicId,
              size,
              title,
              isBookmarked,
              audiences
            }"
            tabindex="-1">
            <template #thumbnail><slot name="thumbnail"></slot></template>
            <template #tasks><slot name="tasks"></slot></template>
            <template #footer><slot name="footer"></slot></template>
          </card-thumbnail>
          <expansion-panel
            @blur="collapseCard"
            @create:bookmark="createBookmark(id)"
            @delete:bookmark="deleteBookmark(id)"
            v-bind="{
              publicId,
              title,
              nasotItems,
              progress,
              isBookmarked,
              primaryActionOptions,
              description: expansionDescription
            }" />
        </div>
      </transition>
    </router-link>
  </v-hover>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CardThumbnail from './CardThumbnail';
import ExpansionPanel from './CardDetails/ExpansionPanel';
import { ResourceType } from '@/main/models/catalog-item';
import Size from './size';
import { VHover } from 'vuetify/lib';

const THRESHOLD = 50;
const LEAVE_ANIMATION_DURATION = 200; // 0.2s

const ResourceTypeRouteNames = {
  [ResourceType.LEARNING_OBJECT]: 'learningObject',
  [ResourceType.LIVESTREAM]: 'livestream',
  [ResourceType.PLAYLIST]: 'playlist'
};

export default {
  name: 'catalog-item-card',
  inheritAttrs: false,
  props: {
    id: { type: Number, required: true },
    publicId: { type: String, required: true },
    title: { type: String, required: true },
    shortDescription: { type: String, default: '' },
    description: { type: String, default: '' },
    nasotItems: { type: Array, default: () => [] },
    size: {
      type: String,
      default: Size.SM,
      validator: value => Object.values(Size).includes(value)
    },
    progress: { type: Number, default: 0 },
    adjustHoverDirection: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    audiences: { type: Array, required: true },
    resourceType: { type: String, required: true },
    primaryActionOptions: { type: Object, required: true }
  },
  data: () => ({ isActive: false }),
  computed: {
    ...mapState('profile', ['bookmarks']),
    isBookmarked: vm => vm.bookmarks.some(it => it.catalogItemId === vm.id),
    isMobile: vm => vm.$vuetify.breakpoint.smAndDown,
    isLarge: vm => vm.size === 'lg',
    isExpansionDisabled: vm => vm.isDisabled || vm.isMobile || vm.isLarge,
    expansionDescription: vm => vm.shortDescription || vm.description,
    catalogItemRoute() {
      const { id, publicId, resourceType } = this;
      const name = ResourceTypeRouteNames[resourceType];
      return { name, params: { id, publicId } };
    }
  },
  methods: {
    ...mapActions('profile', ['createBookmark', 'deleteBookmark']),
    expandCard() {
      if (this.isExpansionDisabled) return;
      this.isActive = true;
    },
    collapseCard($event) {
      const target = $event.relatedTarget;
      if (!target) return;
      if (this.$refs.card.$el.contains(target)) return;
      this.isActive = false;
    }
  },
  watch: {
    isActive(value) {
      if (!this.adjustHoverDirection) return;
      const card = this.$refs.card;
      if (!value) {
        return setTimeout(() => {
          card.$el.classList.remove('open-left', 'open-right');
        }, LEAVE_ANIMATION_DURATION);
      }
      const screenWidth = window.innerWidth;
      const { left, right } = card.$el.getBoundingClientRect();
      if (left < THRESHOLD) return card.$el.classList.add('open-right');
      if (right > screenWidth - THRESHOLD) {
        return card.$el.classList.add('open-left');
      }
    }
  },
  components: { CardThumbnail, ExpansionPanel, VHover }
};
</script>

<style lang="scss" scoped>
// stylelint-disable no-descending-specificity
// stylelint-disable declaration-colon-newline-after
@import '~@/common/assets/stylesheets/utils/z-index';
@import './common.scss';

$animation-delay: 0.2s;

.catalog-item-card {
  position: relative;
  width: $card-sm;
  color: inherit !important;

  &:focus {
    outline: none;
  }

  &-xs {
    width: $card-xs;
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    &-md {
      width: $card-md;
      height: 14rem;
    }

    &-lg {
      width: $card-lg;
    }
  }

  .card-main-thumbnail {
    box-shadow: $elevation-15 !important;
  }

  .card-hover {
    @include z-index(content);

    box-shadow: $elevation-5;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    &:not(&.fade-and-scale-leave-active, &.fade-and-scale-enter-active) {
      transform: scale($scale-factor);
      opacity: 1;
    }
  }

  &.open-left .card-hover {
    transform-origin: right 70%;
  }

  &.open-right .card-hover {
    transform-origin: left 70%;
  }
}

.fade-and-scale-enter-active {
  opacity: 0;
  animation: scale-up 0.2s ease-out $animation-delay forwards,
    fade 0s ease-out $animation-delay forwards;

  .expansion-panel {
    opacity: 0;
    animation: fade 0.1s ease-out $animation-delay forwards;
  }
}

.fade-and-scale-leave-active {
  opacity: 1;
  animation: scale-up 0.2s ease-in reverse;
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes scale-up {
  from {
    transform: scale(1);
  }

  to {
    transform: scale($scale-factor);
  }
}
</style>
