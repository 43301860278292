<template>
  <div class="d-flex align-center flex-wrap">
    <live-now-label class="mr-3 mr-md-2" />
    <time-left-container v-bind="$attrs">
      <template #default="{ timeLeft }">
        <slot v-bind="{ timeLeft }"></slot>
      </template>
    </time-left-container>
  </div>
</template>

<script>
import LiveNowLabel from './Label.vue';
import TimeLeftContainer from './TimeLeftContainer.vue';

export default {
  name: 'live-now-section',
  inheritAttrs: false,
  components: { LiveNowLabel, TimeLeftContainer }
};
</script>
