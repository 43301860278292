<template>
  <av-btn
    @click.native.stop.prevent="update"
    :disabled="isDisabled"
    :aria-label="label"
    icon
    class="bookmark-btn">
    <av-icon
      v-bind="iconOptions"
      name="bookmark"
      variant="box"
      icon-style="solid"
      size="18" />
  </av-btn>
</template>

<script>
export default {
  name: 'card-bookmark-btn',
  inheritAttrs: false,
  props: {
    title: { type: String, required: true },
    isBookmarked: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false }
  },
  computed: {
    isMobile: vm => vm.$vuetify.breakpoint.smAndDown,
    label() {
      return this.isBookmarked
        ? `Remove ${this.title} from bookmarks`
        : `Bookmark ${this.title}`;
    },
    iconOptions() {
      const initial = { color: '#ffffffb3', boxColor: '#ffffff33' };
      const bookmarked = { color: 'primary', boxColor: 'white' };
      return this.isBookmarked ? bookmarked : initial;
    }
  },
  methods: {
    update() {
      this.$emit(this.isBookmarked ? 'delete:bookmark' : 'create:bookmark');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/common/assets/stylesheets/utils/z-index';

.bookmark-btn {
  z-index: z-index(content) - 1;

  :deep(.v-sheet) {
    border-radius: 50%;
    width: 46px !important;
    height: 46px !important;
  }

  &::before {
    background-color: white;
  }
}
</style>
