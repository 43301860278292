var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"expansionPanel",staticClass:"expansion-panel"},[_c('card-actions',_vm._g(_vm._b({},'card-actions',{
      publicId: _vm.publicId,
      progress: _vm.progress,
      isBookmarked: _vm.isBookmarked,
      title: _vm.title,
      primaryActionOptions: _vm.primaryActionOptions
    },false),_vm.$listeners)),_c('div',{staticClass:"description",domProps:{"innerHTML":_vm._s(_vm.markedDescription)},on:{"click":function($event){$event.stopPropagation();}}}),_c('div',{staticClass:"nasot-section"},[(_vm.strand)?_c('nasot-link',_vm._b({},'nasot-link',_vm.strand,false)):_vm._e(),(_vm.element)?_c('nasot-link',_vm._b({},'nasot-link',_vm.element,false)):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }