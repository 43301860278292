<template>
  <v-skeleton-loader
    v-bind="pick($attrs, ['height', 'width', 'type', 'tile'])"
    class="av-skeleton-loader" />
</template>

<script>
import pick from 'lodash/pick';
import { VSkeletonLoader } from 'vuetify/lib';

export default {
  name: 'av-skeleton-loader',
  methods: { pick },
  components: { VSkeletonLoader }
};
</script>

<style lang="scss" scoped>
.av-skeleton-loader :deep(.v-skeleton-loader__bone) {
  width: 100%;
  height: 100%;
}
</style>
