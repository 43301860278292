import api from '@/main/api/profile';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import pluralize from 'pluralize';

export const fetch = async ({ commit }) => {
  const profile = await api.get();
  commit('set', profile);
};

export const update = async ({ commit }, payload) => {
  const data = pick(payload, [
    'firstName',
    'lastName',
    'email',
    'country',
    'state',
    'experienceLevel',
    'audienceId',
    'interestIds',
    'completedOnboarding',
    'organization',
    'isOrganizationMember',
    'subscriptionStart',
    'subscriptionEnd'
  ]);
  const profile = await api.update(data);
  commit('set', profile);
};

export const createBookmark = async ({ commit }, catalogItemId) => {
  const bookmark = await api.createBookmark(catalogItemId);
  commit('createBookmark', bookmark);
};

export const deleteBookmark = async ({ commit }, catalogItemId) => {
  await api.deleteBookmark(catalogItemId);
  commit('deleteBookmark', catalogItemId);
};

export const fetchMilestones = async (_context, { mappings }) => {
  const [achievements, { catalogItemsInProgress }] = await Promise.all([
    await api.fetchAchievements(),
    await api.fetchInProgress()
  ]);
  const data = {
    ...achievements,
    strategiesInProgress: catalogItemsInProgress
  };
  return parseItems(data, mappings);
};

export const fetchAchievements = async (_context, { mappings }) => {
  const achievements = await api.fetchAchievements();
  return parseItems(achievements, mappings);
};

function parseItems(items, mappings) {
  const allowedItems = Object.keys(mappings);
  const filteredItems = pickBy(items, (_, key) => {
    return allowedItems.includes(key);
  });
  return mapAndSortItems(filteredItems, mappings);
}

function mapAndSortItems(items, mappings) {
  const mappedItems = Object.entries(items).map(([key, value]) => {
    const item = mappings[key];
    const name = getItemName(item, value);
    return { ...item, name, value };
  });
  return mappedItems.sort((a, b) => a.position - b.position);
}

function getItemName({ resource, action }, value) {
  return [pluralize(resource, value), action].join(' ');
}
