<template>
  <div>
    <h1 class="text-h3 mt-12 mt-md-0 mb-5 mb-md-7">
      {{ greetingsMgs }}
    </h1>
    <span class="continue-msg">Pick up where you left off:</span>
    <br />
    <span class="text-h3">
      {{ learningObject.title }}
    </span>
    <div class="d-flex body-1 mt-8 font-weight-bold">
      <v-avatar size="52" class="instructor-avatar">
        <v-img
          :src="learningObject.instructorImg.url"
          aspect-ratio="1"
          alt="Instructor image"
          contain />
      </v-avatar>
      <lo-info
        :learning-object="learningObject"
        :icon-size="size"
        :is-mobile="isMobile" />
    </div>
    <actions-footer
      :learning-object="learningObject"
      :is-mobile="isMobile"
      :icon-size="size"
      video-button-text="Watch Now" />
  </div>
</template>

<script setup>
import ActionsFooter from './ActionsFooter';
import { computed } from 'vue';
import LoInfo from './LearningObjectInfo';
import { useVuetify } from '@/main/composables/useVuetify.js';

const props = defineProps({
  learningObject: { type: Object, required: true },
  profile: { type: Object, required: true }
});

const vuetify = useVuetify();

const isMobile = computed(() => !vuetify.breakpoint.smAndUp);
const size = computed(() => (isMobile.value ? 14 : 16));
const greetingsMgs = computed(() => {
  const welcome = 'Welcome Back';
  const userName = props.profile.firstName?.trim();
  if (!userName) return `${welcome}!`;
  return `${welcome}, ${userName}!`;
});
</script>

<style lang="scss" scoped>
.continue-msg {
  color: var(--v-white-base);
  opacity: 60%;
  font-size: 1.3125rem;
}

.instructor-avatar {
  transform: scaleX(-1);
  background: linear-gradient(0.25turn, #8aa6c7, #597ba8);
}
</style>
