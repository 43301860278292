<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.925 40.52">
    <path
      d="M7.312 30.69h12.3l.261-1.257a18.361 18.361 0 0 1 3.706-7.816 13 13 0 1 0-19.686.634 11.537 11.537 0 0 1 2.806 5.473Z"
      fill="#fff"></path>
    <path
      d="M19.613 31.155H7.312a.466.466 0 0 1-.456-.371l-.614-2.965a11.076 11.076 0 0 0-2.692-5.252A13.461 13.461 0 0 1 22.915 3.876a13.457 13.457 0 0 1 1.026 18.034 17.974 17.974 0 0 0-3.612 7.618l-.26 1.256a.465.465 0 0 1-.456.371m-11.922-.931h11.543l.183-.884a18.911 18.911 0 0 1 3.8-8.014A12.529 12.529 0 0 0 13.463.931h-.18a12.528 12.528 0 0 0-9.048 21 12.005 12.005 0 0 1 2.919 5.694Z"
      fill="currentColor"></path>
    <path fill="#fff" d="M7.246 30.736h12.433v2.596H7.246z"></path>
    <path
      d="M19.679 33.795H7.246a.466.466 0 0 1-.466-.466v-2.6a.466.466 0 0 1 .466-.465h12.433a.466.466 0 0 1 .466.465v2.6a.466.466 0 0 1-.466.466m-11.968-.931h11.5v-1.665h-11.5Z"
      fill="currentColor"></path>
    <path fill="#fff" d="M7.246 34.097h12.433v2.596H7.246z"></path>
    <path
      d="M19.679 37.158H7.246a.466.466 0 0 1-.466-.466v-2.6a.466.466 0 0 1 .466-.465h12.433a.466.466 0 0 1 .466.465v2.6a.466.466 0 0 1-.466.466m-11.968-.931h11.5v-1.665h-11.5Z"
      fill="currentColor"></path>
    <path fill="#fff" d="M7.246 37.458h12.433v2.596H7.246z"></path>
    <path
      d="M19.679 40.52H7.246a.466.466 0 0 1-.466-.466v-2.6a.466.466 0 0 1 .466-.466h12.433a.466.466 0 0 1 .466.466v2.6a.466.466 0 0 1-.466.466m-11.968-.931h11.5v-1.665h-11.5Z"
      fill="currentColor"></path>
    <path d="m15.188 11.296-1.725-4.313-1.726 4.313Z" fill="#fff"></path>
    <path
      d="M15.192 11.763h-3.45a.466.466 0 0 1-.432-.638l1.725-4.313a.466.466 0 0 1 .865 0l1.725 4.313a.466.466 0 0 1-.432.638m-2.763-.931h2.075l-1.043-2.596Z"
      fill="currentColor"></path>
    <path
      d="M15.188 30.557V11.296l-1.725-.815-1.726.815v19.261Z"
      fill="#fff"></path>
    <path
      d="M15.193 31.022h-3.45a.466.466 0 0 1-.466-.465V11.295a.466.466 0 0 1 .267-.421l1.726-.815a.466.466 0 0 1 .4 0l1.725.815a.465.465 0 0 1 .267.421v19.262a.466.466 0 0 1-.466.465m-2.993-.931h2.519v-18.5l-1.259-.595-1.26.595Z"
      fill="currentColor"></path>
    <path fill="#fff" d="M13.463 6.982V5.216"></path>
    <path
      d="M13.463 7.447a.466.466 0 0 1-.466-.466V5.217a.466.466 0 1 1 .931 0v1.766a.466.466 0 0 1-.466.466"
      fill="currentColor"></path>
    <path fill="#fff" d="M13.463 10.481v20.076"></path>
    <path
      d="M13.463 31.022a.466.466 0 0 1-.466-.466V10.48a.466.466 0 0 1 .931 0v20.076a.466.466 0 0 1-.466.466"
      fill="currentColor"></path>
  </svg>
</template>

<script>
export default { name: 'av-light-bulb-outline-icon' };
</script>
