<template>
  <v-card
    v-on="$listeners"
    v-bind="pick($attrs, 'to', 'outlined', 'tag')"
    :ripple="false"
    flat
    class="av-card">
    <slot></slot>
  </v-card>
</template>

<script>
import pick from 'lodash/pick';
import { VCard } from 'vuetify/lib';

export default {
  name: 'av-card',
  methods: { pick },
  components: { VCard }
};
</script>
