<template>
  <collapse-transition>
    <slot></slot>
  </collapse-transition>
</template>

<script>
import { CollapseTransition } from '@ivanv/vue-collapse-transition';

// TODO: Replace this package after extracting the custom
// collapse transition that's used in accordion component
export default {
  name: 'av-collapse-transition',
  components: { CollapseTransition }
};
</script>
