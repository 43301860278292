import { extractData } from '@/common/api/helpers';
import request from '@/common/api/request';
import urljoin from 'url-join';

const urls = {
  base: 'profile',
  achievements: () => urljoin(urls.base, 'achievements'),
  inProgress: () => urljoin(urls.base, 'in-progress'),
  interactions: () => urljoin(urls.base, 'interactions'),
  bookmarks: () => urljoin(urls.base, 'bookmarks'),
  systemStreaks: () => urljoin(urls.base, 'system-streaks')
};

function get() {
  return request.get(urls.base).then(extractData);
}

function update(payload) {
  return request.patch(urls.base, payload).then(extractData);
}

function fetchAchievements() {
  return request.get(urls.achievements()).then(extractData);
}

function fetchInProgress() {
  return request.get(urls.inProgress()).then(extractData);
}

function fetchInteractions(params) {
  return request.get(urls.interactions(), { params }).then(extractData);
}

function getSystemStreak() {
  return request.get(urls.systemStreaks()).then(extractData);
}

function createBookmark(catalogItemId) {
  return request.post(urls.bookmarks(), { catalogItemId }).then(extractData);
}

function deleteBookmark(catalogItemId) {
  const params = { catalogItemId };
  return request.delete(urls.bookmarks(), { params }).then(extractData);
}

export default {
  get,
  update,
  fetchAchievements,
  fetchInProgress,
  fetchInteractions,
  getSystemStreak,
  createBookmark,
  deleteBookmark
};
