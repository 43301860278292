<template>
  <livestream-hero v-bind="livestream" :loading="loading">
    <template v-if="livestream" #actions="{ isLive }">
      <av-btn
        v-if="isLive && livestream.meetingLink"
        @click="handleWatchLivestreamBtnClick($event)"
        :href="livestream.meetingLink"
        target="_blank"
        color="white"
        class="mr-3 mr-md-6">
        Join Now
      </av-btn>
      <av-btn
        :to="{ name: 'livestream', params: { publicId: livestream.publicId } }"
        color="white"
        variant="secondary">
        More Info
      </av-btn>
    </template>
  </livestream-hero>
</template>

<script>
import LivestreamHero from '@/main/components/common/Livestreams/Hero';
import { mapGetters } from 'vuex';

export default {
  name: 'featured-livestream',
  props: {
    livestream: { type: Object, default: null },
    loading: { type: Boolean, default: false }
  },
  computed: mapGetters('auth', ['isDemoUser']),
  methods: {
    handleWatchLivestreamBtnClick(event) {
      if (this.isDemoUser) {
        event.preventDefault();
        this.$emit('toggle-dialog');
      }
    }
  },
  components: { LivestreamHero }
};
</script>
