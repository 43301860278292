<template>
  <av-controlled-accordion
    @open="isOpen = true"
    @close="isOpen = false"
    v-bind="$attrs"
    :is-open="isOpen">
    <slot v-for="(_, name) in $slots" :name="name" :slot="name"></slot>
  </av-controlled-accordion>
</template>

<script>
export default {
  name: 'av-accordion',
  data: () => ({ isOpen: false })
};
</script>
