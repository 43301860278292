import catalogApi from './catalog';
import { extractData } from '@/common/api/helpers';
import request from '@/common/api/request';
import urljoin from 'url-join';

const urls = {
  base: urljoin(catalogApi.urls.base, 'recommendations'),
  featured: () => urljoin(urls.base, 'featured'),
  recommended: () => urljoin(urls.base, 'recommended'),
  recommendedPlaylists: () => urljoin(urls.base, 'recommended-playlists'),
  new: () => urljoin(urls.base, 'new'),
  inProgress: () => urljoin(urls.base, 'in-progress')
};

function fetchFeatured() {
  return request.get(urls.featured()).then(extractData);
}

function fetchRecommended() {
  return request.get(urls.recommended()).then(extractData);
}

function fetchRecommendedPlaylists(params = {}) {
  return request.get(urls.recommendedPlaylists(), params).then(extractData);
}

function fetchNew() {
  return request.get(urls.new()).then(extractData);
}

function fetchInProgress() {
  return request.get(urls.inProgress()).then(extractData);
}

export default {
  fetchInProgress,
  fetchFeatured,
  fetchRecommended,
  fetchRecommendedPlaylists,
  fetchNew
};
