<template>
  <div v-if="tasks">
    {{ completedTasksCount }}/{{ tasksCount }} Tasks complete
  </div>
</template>

<script setup>
import useProgressTasks from '@/main/composables/useProgressTasks';
const props = defineProps({
  id: { type: Number, required: true }
});

const { tasks, tasksCount, completedTasksCount } = useProgressTasks(props.id);
</script>
