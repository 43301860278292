<!-- eslint-disable vue/no-v-html -->
<template>
  <div ref="expansionPanel" class="expansion-panel">
    <card-actions
      v-on="$listeners"
      v-bind="{
        publicId,
        progress,
        isBookmarked,
        title,
        primaryActionOptions
      }" />
    <div @click.stop class="description" v-html="markedDescription"></div>
    <div class="nasot-section">
      <nasot-link v-if="strand" v-bind="strand" />
      <nasot-link v-if="element" v-bind="element" />
    </div>
  </div>
</template>

<script>
import CardActions from './Actions';
import { convertHyperlinkMarkdownToHTML } from '@/common/utils/formatters';
import { NasotItemType } from '@/common/models/nasot-item';
import NasotLink from '@/main/components/common/NasotLink';

export default {
  name: 'expansion-panel',
  props: {
    publicId: { type: String, required: true },
    title: { type: String, required: true },
    description: { type: String, required: true },
    nasotItems: { type: Array, default: () => [] },
    progress: { type: Number, default: 0 },
    isBookmarked: { type: Boolean, default: false },
    primaryActionOptions: { type: Object, required: true }
  },
  computed: {
    strand: vm => vm.nasotItems.find(it => it.type === NasotItemType.STRAND),
    element: vm => vm.nasotItems.find(it => it.type === NasotItemType.ELEMENT),
    markedDescription: vm => convertHyperlinkMarkdownToHTML(vm.description)
  },
  mounted() {
    this.$refs.expansionPanel.addEventListener(
      'blur',
      $event => {
        this.$emit('blur', $event);
      },
      true
    );
  },
  components: { CardActions, NasotLink }
};
</script>

<style lang="scss" scoped>
@import '../common.scss';
@import '~@/common/assets/stylesheets/utils/line-clamp';

.expansion-panel {
  padding: calculate-scaled-size(1rem) calculate-scaled-size(1.5rem);
  background-color: var(--v-white-base);

  .description {
    @include line-clamp(4, 100%);

    margin-top: calculate-scaled-size(1rem);
    margin-bottom: calculate-scaled-size(1rem);
    font-size: calculate-scaled-size(1.125rem);
    font-weight: normal;
    line-height: calculate-scaled-size(1.625rem);
  }

  .nasot-section :deep(.nasot) {
    font-size: calculate-scaled-size(1rem);
    line-height: calculate-scaled-size(1.125rem);

    &:first-child {
      margin-bottom: calculate-scaled-size(0.25rem);
    }

    .label {
      margin-right: calculate-scaled-size(0.25rem) !important;
    }
  }
}
</style>
