<template>
  <div class="av-tabs-container">
    <div
      :class="{
        'flex-column flex-md-row d-sm-flex justify-space-between mb-10':
          prefixContent
      }">
      <slot name="prefix-content"></slot>
      <v-tabs
        v-model="currentTab"
        v-bind="{
          centered,
          height,
          right
        }"
        slider-size="2"
        class="av-tabs white">
        <v-tab
          v-for="{
            id,
            name,
            label,
            prependIcon,
            prependIconStyle,
            iconSize
          } in internalTabs"
          :key="id"
          :id="id"
          :style="tabWidth && { width: `${tabWidth}px` }"
          :to="nestedRoutes ? { name } : undefined"
          active-class="primary--text"
          class="av-tab px-4">
          <div class="d-flex align-center my-4">
            <av-icon
              v-if="prependIcon"
              :name="prependIcon"
              :icon-style="prependIconStyle"
              :size="iconSize || 16"
              class="mr-2" />
            <span class="body-1 text-capitalize">{{ label }}</span>
          </div>
          <av-divider :border-width="1" class="grey lighten-5" />
        </v-tab>
      </v-tabs>
    </div>
    <div
      v-if="nestedRoutes"
      data-testid="scroll-tab"
      class="tab-content-wrapper">
      <router-view class="tab-content"></router-view>
    </div>
    <v-tabs-items v-else v-model="currentTab">
      <v-tab-item
        v-for="{ id, component, ...data } in internalTabs"
        :key="id"
        :aria-labelledby="id"
        role="tabpanel">
        <component :is="component" v-bind="{ id, ...data }" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { VTab, VTabs } from 'vuetify/lib';
import cuid from 'cuid';

const validateTabs = tabs => tabs.every(({ label, name }) => name && label);
const validatePosition = pos => ['start', 'center', 'end'].includes(pos);

export default {
  name: 'av-tabs',
  props: {
    tabs: { type: Array, required: true, validator: validateTabs },
    position: { type: String, default: 'center', validator: validatePosition },
    tabWidth: { type: Number, default: undefined },
    height: { type: [Number, String], default: null },
    centered: { type: Boolean, default: false },
    right: { type: Boolean, default: false },
    nestedRoutes: { type: Boolean, default: false },
    prefixContent: { type: Boolean, default: false }
  },
  data: () => ({
    currentTab: 0
  }),
  computed: {
    internalTabs: vm =>
      vm.tabs.map(it => ({ ...it, id: `${it.name}-${cuid()}` }))
  },
  components: { VTabs, VTab }
};
</script>

<style lang="scss" scoped>
.av-tabs-container {
  display: flex;
  position: relative;
  flex-flow: column;
  height: 100%;

  .av-tabs {
    .av-divider {
      position: absolute;
      bottom: 0;
      border-radius: 0;
    }

    :deep(.v-item-group) {
      height: inherit;
    }

    .av-tab {
      width: 100%;
      max-width: unset;
    }

    .av-tab:not(.v-tab--active),
    a.av-tab:not(.v-tab--active) {
      color: var(--v-grey-lighten3) !important;

      .v-icon {
        opacity: 0.1;
      }
    }
  }
}
</style>
