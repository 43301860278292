<template>
  <div class="footer-links">
    <upgrade-dialog
      v-if="isDemoUser"
      v-model="isDialogActive"
      @close="toggleDialog"
      variant="community" />
    <router-link
      v-for="{ name, label, disableForDemo } in routes"
      :key="name"
      @click.native.capture="handleClick($event, disableForDemo)"
      :to="{ name }"
      class="text-h4 white--text mr-12">
      {{ label }}
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'footer-routes',
  data: () => ({
    isDialogActive: false
  }),
  computed: {
    ...mapGetters('auth', ['isDemoUser']),
    routes: () => [
      { name: 'dashboard', label: 'Home', disableForDemo: false },
      { name: 'catalog', label: 'Catalog', disableForDemo: false },
      { name: 'community', label: 'Community', disableForDemo: true },
      { name: 'livestreams', label: 'Livestreams', disableForDemo: false }
    ]
  },
  methods: {
    toggleDialog() {
      this.isDialogActive = !this.isDialogActive;
    },
    handleClick(event, disableForDemo) {
      if (this.isDemoUser && disableForDemo) {
        event.preventDefault();
        this.toggleDialog();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-links a {
  text-decoration: none;
}
</style>
