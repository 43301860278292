<template>
  <v-banner :min-height="minHeight" class="highlight-banner" color="secondary">
    <div
      :class="flexClasses"
      class="d-flex justify-md-space-around justify-center">
      <div :class="{ 'align-center': mdAndUp }" class="d-flex pl-4 pr-8">
        <av-icon
          v-if="iconName"
          :size="iconSize"
          :name="iconName"
          icon-style="solid"
          color="white"
          class="highlight-banner-icon mr-4" />
        <span class="highlight-banner-text font-weight-medium white--text">
          <slot></slot>
        </span>
      </div>
      <banner-action
        @click:banner-button="$emit('click:banner-button')"
        :redirect-button-config="redirectButtonConfig"
        class="pl-4" />
    </div>
  </v-banner>
</template>

<script>
import BannerAction from './BannerAction.vue';

export default {
  name: 'av-highlight-banner',
  props: {
    minHeight: { type: String, default: '84px' },
    iconName: { type: String, default: '' },
    redirectButtonConfig: { type: Object, default: () => ({}) }
  },
  computed: {
    mdAndUp: vm => vm.$vuetify.breakpoint.mdAndUp,
    iconSize: vm => (vm.mdAndUp ? '24' : '16'),
    flexClasses: vm => {
      if (vm.mdAndUp) return 'align-center';
      return vm.redirectButtonConfig.hasButton ? ' flex-column' : '';
    },
    buttonMarginLeft: vm => (!vm.mdAndUp && vm.iconName ? '1.8rem' : '0')
  },
  components: { BannerAction }
};
</script>

<style lang="scss" scoped>
.highlight-banner {
  box-shadow: 0 0.1875rem 0.1875rem rgba(0, 0, 0, 0.25) !important;

  :deep(.v-banner__wrapper) {
    border-bottom: none !important;
    padding: 1.25rem 0.5rem 1rem 1.5rem;
  }

  &.v-banner--is-mobile:deep(.v-banner__wrapper) {
    padding-top: 1.25rem;
  }

  &-icon {
    margin-top: 0.6rem;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      margin-top: 0;
    }
  }

  &-text {
    line-height: 1.5rem;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      padding: 0 2rem 0 1rem;
      font-size: 1.25rem;
      line-height: 2rem;
    }
  }

  &-button {
    margin-left: v-bind(buttonMarginLeft);

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      margin-left: 0;
    }
  }
}
</style>
