<template>
  <catalog-item-card
    :size="size"
    v-bind="{
      ...learningObject,
      isDisabled,
      primaryActionOptions
    }"
    :data-testid="`learning-object-card ${learningObject.title}`">
    <template #thumbnail>
      <learning-object-thumbnail
        :img-src="learningObject.instructorImg.url"
        :color="learningObject.thumbnailColor" />
    </template>
    <template #tasks>
      <card-tasks :id="learningObject.id" />
    </template>
    <template #footer>
      <card-progress
        :progress="progress"
        :duration="learningObject.video.duration"
        :size="size" />
    </template>
  </catalog-item-card>
</template>

<script>
import CardProgress from './Progress';
import CardTasks from './Tasks';
import CatalogItemCard from '@/main/components/common/cards/CatalogItemCard';
import LearningObjectThumbnail from '@/common/components/Thumbnail';
import { mapState } from 'vuex';
import Size from '@/main/components/common/cards/CatalogItemCard/size';

export default {
  name: 'learning-object-card',
  props: {
    learningObject: { type: Object, required: true },
    size: { type: String, default: Size.SM },
    isDisabled: { type: Boolean, default: false }
  },
  computed: {
    ...mapState('profile', ['bookmarks']),
    hasProgress: vm => !!vm.learningObject.progress,
    progress: vm => (vm.hasProgress ? vm.learningObject.progress : 0),
    primaryActionOptions() {
      const { publicId, progress } = this.learningObject;
      const ariaLabel = `${progress ? 'Resume' : 'Play'} video`;
      const to = {
        name: 'learningObject',
        params: { publicId },
        query: { playVideo: true, ...(progress && { progress }) }
      };
      return { ariaLabel, to, icon: 'play' };
    }
  },
  components: {
    CardProgress,
    CardTasks,
    CatalogItemCard,
    LearningObjectThumbnail
  }
};
</script>
