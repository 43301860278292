<template>
  <div class="livestream-hero">
    <div class="layer"></div>
    <av-btn
      v-if="showBackBtn"
      @click="$router.go(-1)"
      variant="text"
      color="white"
      size="medium"
      prepend-icon="chevron-left"
      class="back-btn float-left ml-md-15 mt-1 mt-md-4">
      Back
    </av-btn>
    <av-container class="content-container">
      <div v-if="!loading" class="content">
        <div class="mb-6 mb-md-8">
          <live-now-section v-if="isLive" :end-date="endDate">
            <template #default="{ timeLeft }">
              <span
                v-if="timeLeft"
                :class="isMobile ? 'text-caption-title' : 'text-h5'"
                class="white--text">
                {{ timeLeft }} left
              </span>
            </template>
          </live-now-section>
          <starting-soon-label
            v-else-if="isStartingSoon"
            :start-date="startDate"
            :now="now" />
          <div
            v-else-if="isUpcoming"
            class="upcoming-label-container px-2 py-1 white">
            <upcoming-label
              class="text-caption-title error--text"
              :start-date="startDate"
              :end-date="endDate" />
          </div>
          <recording-info-section
            v-else-if="hasEnded"
            :start-date="startDate"
            :end-date="endDate" />
        </div>
        <h1
          :class="isMobile ? 'text-h5' : 'text-video-title-giant'"
          class="hero-title mb-6 mb-md-2 white--text">
          {{ title }}
        </h1>
        <div v-if="hostName" class="hostname text-uppercase white--text">
          {{ hostName }}
        </div>
        <div class="d-flex mt-6 mt-md-8">
          <slot name="actions" v-bind="{ isLive, hasEnded }"></slot>
        </div>
      </div>
      <hero-skeleton-loader v-else class="content" />
    </av-container>
  </div>
</template>

<script>
import { hasEnded, isLive, isStartingSoon, isUpcoming } from '../utils.js';
import AvContainer from '@/common/components/base/Container';
import HeroSkeletonLoader from './SkeletonLoader.vue';
import LiveNowSection from '../LiveNow';
import RecordingInfoSection from '../RecordingInfo.vue';
import StartingSoonLabel from '../StartingSoonLabel.vue';
import UpcomingLabel from '../UpcomingLabel.vue';

export default {
  name: 'livestream-hero',
  props: {
    title: { type: String, default: null },
    hostName: { type: String, default: null },
    startDate: { type: Date, default: null },
    endDate: { type: Date, default: null },
    showBackBtn: { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },
  data: () => ({
    counter: null,
    now: new Date()
  }),
  computed: {
    isMobile: vm => vm.$vuetify.breakpoint.smAndDown,
    isLive: vm => isLive(vm.startDate, vm.endDate, vm.now),
    isStartingSoon: vm => isStartingSoon(vm.startDate, vm.now),
    isUpcoming: vm => isUpcoming(vm.startDate, vm.now),
    hasEnded: vm => hasEnded(vm.endDate, vm.now)
  },
  created() {
    this.counter = setInterval(() => {
      this.now = new Date();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.counter);
  },
  components: {
    AvContainer,
    LiveNowSection,
    StartingSoonLabel,
    UpcomingLabel,
    HeroSkeletonLoader,
    RecordingInfoSection
  }
};
</script>

<style lang="scss" scoped>
@import '~@/common/assets/stylesheets/utils/z-index';

$background-image: 'https://avanti-storage-prod-20240822143136704900000001.s3.amazonaws.com/thumbnails/lm-background.png';

.livestream-hero {
  position: relative;
  background-image: url($background-image);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  .layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 68, 124, 0.6);
  }

  .back-btn {
    position: relative;
    z-index: z-index(content) + 1;
  }

  .content-container {
    position: relative;
    z-index: z-index(content);

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      padding: 4.5rem 2rem 3.5rem !important;
    }
  }

  .content {
    max-width: map-get($container-max-widths, 'xl');
    margin: auto;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      .hero-title {
        max-width: 60%;
      }
    }

    .upcoming-label-container {
      border-radius: 3px;
      width: fit-content;
    }

    .hostname {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 2rem;
    }
  }
}
</style>
