import { render, staticRenderFns } from "./Chip.vue?vue&type=template&id=088e3485&scoped=true&"
import script from "./Chip.vue?vue&type=script&lang=js&"
export * from "./Chip.vue?vue&type=script&lang=js&"
import style0 from "./Chip.vue?vue&type=style&index=0&id=088e3485&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "088e3485",
  null
  
)

export default component.exports