<template>
  <div>
    <slot v-bind="{ timeLeft }"></slot>
  </div>
</template>

<script>
import useTimer from '@/main/composables/useTimer';

export default {
  name: 'time-left-container',
  props: {
    endDate: { type: Date, required: true }
  },
  setup(props) {
    const timeLeft = useTimer(props.endDate);
    return { timeLeft };
  }
};
</script>
