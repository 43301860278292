<template>
  <svg
    fill="currentColor"
    viewBox="0 0 40 39"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.7182 18.1773H23.1507c-.4851-2.4525-2.5135-4.2798-4.8947-4.2798s-4.4097 1.8273-4.8947 4.2798H1.10241C.485058 18.1773 0 18.7063 0 19.3796c0 .6732.485058 1.2022 1.10241 1.2022H13.3613c.485 2.4525 2.5135 4.2799 4.8947 4.2799s4.4096-1.8274 4.8947-4.2799h12.5675c.6174 0 1.1024-.529 1.1024-1.2022 0-.6252-.5291-1.2023-1.1024-1.2023Zm-17.4613 4.2805c-1.5433 0-2.8221-1.3946-2.8221-3.0777s1.2788-3.0777 2.8221-3.0777c1.5434 0 2.8222 1.3946 2.8222 3.0777s-1.2788 3.0777-2.8222 3.0777ZM5.07192 10.9642c2.38122 0 4.40967-1.82737 4.89472-4.27989H35.6749c.6174 0 1.1025-.52897 1.1025-1.20221s-.4851-1.20222-1.1025-1.20222H9.96664C9.48159 1.82735 7.45314 0 5.07192 0 2.29384 0 .0449219 2.50062.0449219 5.4821c0 2.9815 2.2489281 5.4821 5.0269981 5.4821Zm.00014-8.55921c1.54338 0 2.82218 1.39457 2.82218 3.07767 0 1.6831-1.2788 3.07767-2.82218 3.07767-1.54337 0-2.82217-1.39457-2.82217-3.07767 0-1.6831 1.2788-3.07767 2.82217-3.07767ZM31.7495 27.8916c-2.3812 0-4.3655 1.8274-4.8947 4.2318H1.10241C.485058 32.1234 0 32.6524 0 33.3256c0 .6733.485058 1.2022 1.10241 1.2022H26.8548c.4851 2.4526 2.5135 4.328 4.8947 4.328 2.7781 0 5.027-2.4525 5.027-5.4821s-2.293-5.4821-5.027-5.4821Zm-.0013 8.5115c-1.5434 0-2.8222-1.3945-2.8222-3.0777 0-1.6831 1.2788-3.0776 2.8222-3.0776 1.5433 0 2.8221 1.3945 2.8221 3.0776 0 1.6832-1.2788 3.0777-2.8221 3.0777Z"></path>
  </svg>
</template>

<script>
export default { name: 'av-filter-outline-icon' };
</script>
