import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
  hidden: false,
  expired: false,
  bannerStateKey: null
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
