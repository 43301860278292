import { computed } from 'vue';

const useFontawesomeIcon = (icon, style, hasErrors) => {
  const faStyleKey = computed(() => {
    if (style.value === 'light') return 'fal';
    if (style.value === 'regular') return 'far';
    if (style.value === 'solid') return 'fas';
    return null;
  });

  const innerIcon = computed(() => {
    if (!icon.value) return;
    return (
      '$vuetify.icons.' +
      (hasErrors && hasErrors.value
        ? 'far-circle-exclamation'
        : formatIcon(icon.value, faStyleKey.value))
    );
  });

  function formatIcon(icon, style) {
    if (!style) return icon;
    return `${style}-${icon}`;
  }

  return { innerIcon, faStyleKey };
};

export default useFontawesomeIcon;
