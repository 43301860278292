<template>
  <div :class="[`status-info`, `status-info-${size}`]">
    <live-now v-if="isLive" :end-date="endDate" />
    <starting-soon-label
      v-else-if="isStartingSoon"
      :start-date="startDate"
      :now="now" />
    <upcoming-label
      v-else-if="isUpcoming"
      :start-date="startDate"
      :end-date="endDate" />
    <recording-label
      v-else
      :size="size"
      :start-date="startDate"
      :end-date="endDate" />
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, ref } from 'vue';
import {
  isLive as getIsLive,
  isStartingSoon as getIsStartingSoon,
  isUpcoming as getIsUpcoming
} from '@/main/components/common/Livestreams/utils.js';
// TODO: consolidate info component designs with the components/common/Livestreams ones
import LiveNow from './LiveNow';
import RecordingLabel from './RecordingLabel';
import StartingSoonLabel from './StartingSoonLabel';
import UpcomingLabel from '@/main/components/common/Livestreams/UpcomingLabel';

const props = defineProps({
  size: { type: String, required: true },
  startDate: { type: Date, required: true },
  endDate: { type: Date, required: true }
});

const now = ref(new Date());
const counter = setInterval(() => {
  now.value = new Date();
}, 1000);

onBeforeUnmount(() => {
  clearInterval(counter);
});

const isLive = computed(() =>
  getIsLive(props.startDate, props.endDate, now.value)
);
const isStartingSoon = computed(() =>
  getIsStartingSoon(props.startDate, now.value)
);
const isUpcoming = computed(() => getIsUpcoming(props.startDate, now.value));
</script>

<style scoped lang="scss">
.status-info {
  padding: 0 0 0.875rem 1.3rem;
  font-size: 0.875rem;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    &-lg,
    &-md {
      font-size: 1rem;
    }
  }
}
</style>
