<template>
  <div>
    <div class="title-container">
      <h1 class="text-h4 mb-5 mb-md-3 mt-12 mt-md-0">
        Start with this strategy:
        <av-divider v-if="!isMobile" />
      </h1>
    </div>
    <av-divider v-if="isMobile" />
    <p class="text-h3 mt-8">
      {{ learningObject.title }}
    </p>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <p class="learning-object-description" v-html="markedDescription" />
    <video-duration :duration="learningObject.video.duration" :size="size" />
    <actions-footer
      :learning-object="learningObject"
      :icon-size="size"
      :is-mobile="isMobile"
      video-button-text="Play Now" />
  </div>
</template>

<script setup>
import ActionsFooter from './ActionsFooter';
import { computed } from 'vue';
import { convertHyperlinkMarkdownToHTML } from '@/common/utils/formatters';
import { useVuetify } from '@/main/composables/useVuetify.js';
import VideoDuration from '@/main/components/common/VideoDuration';

const props = defineProps({
  learningObject: { type: Object, required: true },
  isMobile: { type: Boolean, default: true }
});

const vuetify = useVuetify();

const isMobile = computed(() => !vuetify.breakpoint.smAndUp);
const size = computed(() => (isMobile.value ? 14 : 16));
const markedDescription = computed(() =>
  convertHyperlinkMarkdownToHTML(props.learningObject.description)
);
</script>

<style lang="scss" scoped>
@import '~@/common/assets/stylesheets/utils/line-clamp';

.title-container {
  max-width: max-content;
}

.learning-object-description {
  @include line-clamp(3, 100%);

  margin-top: 1.5rem;

  :deep(a.markdown-link) {
    text-decoration: underline !important;
    color: var(--v-accent-secondary-lighten4) !important;
  }

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-top: 3rem;
  }
}
</style>
