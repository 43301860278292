<template>
  <div class="card-actions">
    <av-btn
      v-bind="primaryActionOptions"
      variant="secondary"
      icon
      class="action-btn mr-2">
      <av-icon size="14" :name="primaryActionOptions.icon" icon-style="solid" />
    </av-btn>
    <bookmark-btn
      @click.prevent="updateBookmark"
      :label="bookmarkLabel"
      :is-bookmarked="isBookmarked"
      class="action-btn" />
  </div>
</template>

<script>
import BookmarkBtn from '@/main/components/common/BookmarkBtn';

export default {
  name: 'card-actions',
  props: {
    publicId: { type: String, required: true },
    title: { type: String, required: true },
    progress: { type: Number, default: 0 },
    isBookmarked: { type: Boolean, default: false },
    primaryActionOptions: { type: Object, required: true }
  },
  computed: {
    bookmarkLabel() {
      return this.isBookmarked
        ? `Remove ${this.title} from bookmarks`
        : `Bookmark ${this.title}`;
    }
  },
  methods: {
    updateBookmark() {
      this.$emit(this.isBookmarked ? 'delete:bookmark' : 'create:bookmark');
    }
  },
  components: { BookmarkBtn }
};
</script>

<style lang="scss" scoped>
@import '../common.scss';

.card-actions .action-btn {
  width: calculate-scaled-size(2.75rem) !important;
  height: calculate-scaled-size(2.75rem) !important;
  padding: calculate-scaled-size(0.5rem) !important;

  .av-icon svg {
    height: calculate-scaled-size(0.75rem);
  }
}
</style>
