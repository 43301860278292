import { extractData } from '@/common/api/helpers';
import request from '@/common/api/request';

const urls = {
  progress: 'progress'
};

function getProgress() {
  return request.get(urls.progress).then(extractData).then(convertArrayToMap);
}

export default { getProgress };

function convertArrayToMap({ catalogItemsProgress }) {
  return catalogItemsProgress.reduce(
    (acc, { catalogItemId, ...catalogItemProgress }) => ({
      ...acc,
      [catalogItemId]: catalogItemProgress
    }),
    {}
  );
}
