import * as actions from './actions';
import * as getters from './getters';
import * as mutations from './mutations';

const state = {
  initialized: false,
  firstName: '',
  lastName: '',
  fullName: '',
  email: '',
  country: '',
  state: '',
  interestIds: [],
  audienceId: null,
  completedOnboarding: false,
  experienceLevel: null,
  isOrganizationMember: false,
  bookmarks: [],
  subscriptionStart: '',
  subscriptionEnd: ''
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
