<template>
  <av-highlight-banner
    @click:banner-button="hideFeatureBanner"
    :redirect-button-config="redirectButtonConfig"
    icon-name="sparkles"
    class="feature-banner">
    {{ bannerText }}
  </av-highlight-banner>
</template>

<script>
import AvHighlightBanner from '@/common/components/base/HighlightBanner';
import { mapActions } from 'vuex';

export default {
  name: 'feature-banner',
  props: {
    bannerText: { type: String, required: true },
    featureRoute: { type: String, required: true },
    redirectButtonText: { type: String, default: '' }
  },
  computed: {
    hasRedirectButton: vm => !!vm.featureRoute,
    redirectButtonConfig: vm => ({
      text: vm.redirectButtonText,
      hasButton: vm.hasRedirectButton
    })
  },
  methods: {
    ...mapActions('featureBanner', ['hideBanner']),
    hideFeatureBanner() {
      this.hideBanner();
      if (this.hasRedirectButton) this.$router.push(this.featureRoute);
    }
  },
  components: { AvHighlightBanner }
};
</script>

<style lang="scss" scoped>
.feature-banner {
  margin-top: var(--navbar-height);
}
</style>
