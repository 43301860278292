<template>
  <av-highlight-banner
    @click:banner-button="loginDemoUser"
    v-bind="bannerProps"
    class="upgrade-banner">
    You're currently in Avanti Reveal Mode with limited access.
  </av-highlight-banner>
</template>

<script>
import AvHighlightBanner from '@/common/components/base/HighlightBanner';
import { mapActions } from 'vuex';

export default {
  name: 'upgrade-banner',
  computed: {
    minHeight() {
      if (this.$vuetify.breakpoint.lgAndUp) return '84px';
      if (this.$vuetify.breakpoint.md) return '100px';
      return '120px';
    },
    buttonSize() {
      if (this.$vuetify.breakpoint.mdAndUp) return 'large';
      return 'small';
    },
    bannerProps() {
      return {
        minHeight: this.minHeight,
        redirectButtonConfig: {
          hasButton: true,
          text: 'Join Now for 7-days of Free Access'
        }
      };
    }
  },
  methods: mapActions('auth', ['loginDemoUser']),
  components: { AvHighlightBanner }
};
</script>

<style lang="scss" scoped>
@import '~@/common/assets/stylesheets/utils/z-index';

.upgrade-banner {
  z-index: z-index(app-bar);
  position: fixed;
  top: 0;
  width: 100%;
}
</style>
