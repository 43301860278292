<template>
  <time :datetime="datetime" :aria-label="a11yDate">{{ formattedDate }}</time>
</template>

<script>
import { formatDate } from '@/common/utils/formatters';

const A11Y_DATE_FORMAT = 'MMMM Do, YYYY';

export default {
  name: 'av-date',
  props: {
    date: { type: String, required: true }
  },
  computed: {
    datetime: vm => new Date(vm.date).toISOString(),
    formattedDate: vm => formatDate(vm.date),
    a11yDate: vm => formatDate(vm.date, A11Y_DATE_FORMAT)
  }
};
</script>
