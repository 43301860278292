import api from '@/main/api/progress';

export const fetch = async ({ commit }) => {
  const progress = await api.getProgress();
  commit('set', progress);
};

export const updateCatalogItemProgress = (
  { state, commit },
  { catalogItemId, ...catalogItemProgress }
) => {
  const progress = {
    ...state.progress,
    [catalogItemId]: {
      ...state.progress[catalogItemId],
      ...catalogItemProgress
    }
  };
  commit('set', progress);
};
