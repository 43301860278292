<template>
  <div>
    {{ startDatelabel }}<br />
    {{ timeRange }}
  </div>
</template>

<script>
import { format } from 'date-fns';

export default {
  name: 'upcoming-label',
  props: {
    startDate: { type: Date, required: true },
    endDate: { type: Date, required: true }
  },
  computed: {
    startDatelabel() {
      return format(this.startDate, 'MMMM do');
    },
    timeRange() {
      const timeFormat = 'h:mmaaa';
      return `${format(this.startDate, timeFormat)} to ${format(
        this.endDate,
        timeFormat
      )}`;
    }
  }
};
</script>
