<template>
  <v-chip
    v-on="$listeners"
    v-bind="{ ...internalSize, color, textColor }"
    :class="{ 'no-chip-hover': !hoverable }"
    class="av-chip">
    <av-icon
      v-if="hasIcon"
      :name="icon.name"
      :icon-style="icon.style"
      :size="icon.size" />
    <slot></slot>
  </v-chip>
</template>

<script>
export const Size = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg'
};

const VuetifyChipSize = {
  [Size.SM]: 'small',
  [Size.MD]: 'medium',
  [Size.LG]: 'large'
};

const validate = (val, allowedMap) => Object.values(allowedMap).includes(val);

export default {
  name: 'av-chip',
  props: {
    color: { type: String, required: true },
    textColor: { type: String, required: true },
    icon: { type: Object, default: () => ({}) },
    hoverable: { type: Boolean, default: false },
    size: {
      type: String,
      default: Size.MD,
      validator: value => validate(value, Size)
    }
  },
  computed: {
    hasIcon() {
      return Object.keys(this.icon).length;
    },
    internalSize() {
      const size = VuetifyChipSize[this.size];
      return { [size]: true };
    }
  }
};
</script>

<style lang="scss" scoped>
.av-chip {
  :deep(.v-chip__content) {
    gap: 0.25rem;
  }

  &.no-chip-hover::before {
    display: none;
  }

  &.v-size--small {
    height: 1.3rem;
    padding: 0.4rem;
    font-weight: 500;
  }
}
</style>
