<template>
  <fieldset class="av-pill-checkbox-group">
    <v-input :error-messages="errorMessages">
      <v-chip-group
        v-on="$listeners"
        :value="$attrs.value"
        :multiple="multiple">
        <av-pill-checkbox
          v-for="{ value, label } in items"
          :key="value"
          :value="value"
          :label="label"
          :readonly="readonly"
          :disabled="disabled"
          hide-details />
      </v-chip-group>
    </v-input>
  </fieldset>
</template>

<script>
import { VChipGroup, VInput } from 'vuetify/lib';

export default {
  name: 'av-pill-checkbox-group',
  model: { event: 'change' },
  props: {
    multiple: { type: Boolean, default: true },
    items: { type: Array, required: true },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    errorMessages: { type: [Array, String], default: () => [] }
  },
  components: { VChipGroup, VInput }
};
</script>

<style lang="scss" scoped>
.av-pill-checkbox-group {
  border: none;

  :deep(.v-input) {
    .v-chip-group {
      width: 100%;
    }

    .v-slide-group__content {
      display: flex;
      flex-flow: column;
      width: 100%;
      padding: 0;
    }

    .av-pill-checkbox {
      margin: 0.5rem 0 0 0;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        margin: 1rem 0 0 0;
      }
    }

    .v-messages {
      display: none;

      &.error--text {
        display: block;
        margin-top: 0.25rem;
        font-size: 0.875rem;
        line-height: 1rem;
      }
    }
  }
}
</style>
