<template>
  <v-checkbox
    v-model="value"
    v-bind="
      pick($attrs, [
        'label',
        'value',
        'error-messages',
        'multiple',
        'readonly',
        'disabled',
        'hide-details'
      ])
    "
    :ripple="false"
    :on-icon="circleCheckIcon"
    :off-icon="circleIcon"
    :indeterminate-icon="circleMinusIcon"
    class="av-checkbox">
    <template #label>
      <slot name="label"></slot>
    </template>
  </v-checkbox>
</template>

<script>
import pick from 'lodash/pick';
import { VCheckbox } from 'vuetify/lib';

export default {
  name: 'av-checkbox',
  model: {
    prop: 'inputValue'
  },
  props: {
    alt: { type: Boolean, default: false }
  },
  computed: {
    value: {
      get: vm => vm.$attrs.inputValue,
      set(value) {
        return this.$emit('input', value);
      }
    },
    circleCheckIcon() {
      const faStyleKey = this.alt ? 'fal' : 'fas';
      return `$vuetify.icons.${faStyleKey}-circle-check`;
    },
    circleIcon() {
      return '$vuetify.icons.fal-circle';
    },
    circleMinusIcon() {
      return '$vuetify.icons.fas-circle-minus';
    }
  },
  methods: { pick },
  components: { VCheckbox }
};
</script>

<style lang="scss" scoped>
@mixin colorize-checkbox(
  $color,
  $bgColor: none,
  $labelColor: var(--v-grey-base)
) {
  :deep(label) {
    color: $labelColor !important;
  }

  :deep(.v-icon) {
    color: $color !important;
  }

  :deep(.v-input--selection-controls__input::before) {
    background: $bgColor !important;
  }
}

.av-checkbox.theme--light.v-input--selection-controls.v-input {
  :deep(.v-input__control) {
    .v-input--selection-controls__input {
      width: 1.375rem;
      height: 1.375rem;

      &::before {
        content: '';
        position: absolute;
        top: 0.0625rem;
        left: 0.0625rem;
        width: calc(100% - 0.125rem);
        height: calc(100% - 0.125rem);
        border-radius: 50%;
      }
    }

    .v-icon svg {
      height: 1.25rem;
    }

    .av-checkbox--label {
      font-size: 0.875rem;
    }
  }

  :deep(.v-messages) .v-messages__wrapper .v-messages__message {
    margin-left: 0;
    font-size: 0.875rem;
    line-height: initial;
  }

  @include colorize-checkbox(var(--v-grey-base));

  &--is-focused:not(&--is-label-active) {
    @include colorize-checkbox(
      var(--v-primary-base),
      var(--v-primary-lighten5)
    );

    :deep(.v-input--selection-controls__input)::before {
      width: 100%;
      height: 100%;
    }
  }

  &--is-label-active {
    @include colorize-checkbox(var(--v-primary-base));
  }

  &--is-disabled {
    @include colorize-checkbox(
      var(--v-grey-lighten5),
      var(--v-grey-lighten6),
      var(--v-grey-lighten5)
    );
  }
}
</style>
