<template>
  <div class="starts-in-label d-flex align-center px-2 py-1 error white--text">
    <av-icon
      name="video"
      icon-style="solid"
      color="white"
      size="12"
      class="mr-2" />
    <span class="text-caption-title">Starts in {{ startsIn }} minutes</span>
  </div>
</template>

<script>
import { millisecondsToMinutes } from 'date-fns';

export default {
  name: 'starts-in-label',
  props: {
    startDate: { type: Date, required: true },
    now: { type: Date, default: () => new Date() }
  },
  computed: {
    startsIn() {
      const diffInMilliseconds = this.startDate - this.now;
      return millisecondsToMinutes(diffInMilliseconds) + 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.starts-in-label {
  width: fit-content;
  border-radius: 3px;
}
</style>
