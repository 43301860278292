<template>
  <av-snackbar v-model="snackbar" v-bind="context">
    <template #content>
      <div class="d-flex align-center">
        <av-icon
          v-if="prependIcon"
          v-bind="prependIcon"
          size="32"
          class="prepend-icon mr-4" />
        <span class="body-1 grey--text">{{ message }}</span>
      </div>
    </template>
    <template #action>
      <av-btn v-if="closable" @click="close" color="black" icon class="mr-2">
        <av-icon size="24" name="close" color="grey lighten-2" />
      </av-btn>
    </template>
  </av-snackbar>
</template>

<script>
import AvSnackbar, { Variant } from '@/common/components/base/Snackbar';
import AvBtn from '@/common/components/base/Btn';
import AvIcon from '@/common/components/base/Icon';

const Icon = {
  SUCCESS: { name: 'circle-check', iconStyle: 'regular' },
  INFO: { name: 'circle-info', iconStyle: 'regular' },
  ERROR: { name: 'triangle-exclamation', iconStyle: 'regular' },
  WARNING: { name: 'triangle-exclamation', iconStyle: 'regular' }
};
export default {
  name: 'av-snackbar-base',
  data: () => ({
    snackbar: false,
    context: {},
    message: '',
    variant: null,
    prependIcon: null,
    closable: false
  }),
  methods: {
    show(message, options = {}) {
      const { variant = Variant.INFO, closable, timeout = 2000 } = options;
      Object.assign(this.context, { variant, timeout });
      this.closable = closable;
      this.prependIcon = this.getPrependIcon(variant);
      this.message = message;
      this.snackbar = true;
    },
    close() {
      this.snackbar = false;
    },
    getPrependIcon(variant) {
      if (variant === Variant.SUCCESS) return Icon.SUCCESS;
      if (variant === Variant.ERROR) return Icon.ERROR;
      if (variant === Variant.WARNING) return Icon.WARNING;
      return Icon.INFO;
    }
  },
  components: { AvSnackbar, AvIcon, AvBtn }
};
</script>
