<template>
  <!-- TODO: consolidate all non theme colors -->
  <av-chip color="#d9342b" text-color="white" :icon="icon" :size="ChipSize.SM">
    Live Now, {{ timeLeft }} left
  </av-chip>
</template>

<script setup>
import { Size as ChipSize } from '@/common/components/base/Chip.vue';
import useTimer from '@/main/composables/useTimer';

const props = defineProps({ endDate: { type: Date, required: true } });
const timeLeft = useTimer(props.endDate);
const icon = { name: 'livestream', size: 14 };
</script>
