<template>
  <div>
    <av-skeleton-loader
      type="text"
      height="30"
      width="250"
      class="mb-6 mb-md-8" />
    <av-skeleton-loader
      :height="isMobile ? 20 : 46"
      type="heading"
      class="mb-6 mb-md-2" />
    <av-skeleton-loader
      type="button"
      width="110"
      height="44"
      class="mt-6 mt-md-8" />
  </div>
</template>

<script>
export default {
  name: 'hero-skeleton-loader',
  computed: {
    isMobile: vm => vm.$vuetify.breakpoint.smAndDown
  }
};
</script>
