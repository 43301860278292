class LocalStorageWithExpiry {
  #localStorage = window.localStorage;

  setItem(key, value, ttl) {
    const now = new Date();
    const wrapper = {
      value,
      expiry: now.getTime() + ttl
    };
    this.#localStorage.setItem(key, JSON.stringify(wrapper));
  }

  updateItem(key, value) {
    const item = JSON.parse(this.#localStorage.getItem(key));
    const updated = { ...item, value };
    this.#localStorage.setItem(key, JSON.stringify(updated));
  }

  getItem(key) {
    const item = JSON.parse(this.#localStorage.getItem(key));
    if (!item) return null;

    const now = new Date();
    if (now.getTime() > item.expiry) {
      return { expired: true };
    }
    return item.value;
  }
}

export default LocalStorageWithExpiry;
