import { GrowthBook } from '@growthbook/growthbook';

const getFeaturesJson = async featuresEndpoint => {
  const response = await fetch(featuresEndpoint);
  return await response.json();
};

export const ABTestingVuePlugin = {
  install: function (Vue, { clientKey, enableDevMode = false }) {
    let growthBook = null;
    const featuresEndpoint = `https://cdn.growthbook.io/api/features/${clientKey}`;

    Vue.prototype.initABTesting = async function initABTesting() {
      if (growthBook) return Promise.resolve(growthBook);

      try {
        const json = await getFeaturesJson(featuresEndpoint);
        growthBook = new GrowthBook({ enableDevMode });
        growthBook.setFeatures(json.features);
        return growthBook;
      } catch (error) {
        console.error('GrowthBook Vue plugin initialization error', error);
        return null;
      }
    };
  }
};
