import initVuetify from '@/common/plugins/vuetify';
import Snackbar from './Snackbar';
import themeConfig from './theme';
import Vue from 'vue';
import { VueMaskDirective } from 'v-mask';

Vue.directive('mask', VueMaskDirective);

const config = { snackbar: { component: Snackbar }, ...themeConfig };

export default initVuetify(Vue, config);
