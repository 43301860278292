<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="32"
    height="32"
    viewBox="0 -3 32 32"
    fill="currentColor">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.15224 2.13565C2.24143 2.04079 2.34876 2 2.44665 2H4.82987V7.7091H2V2.53855C2 2.37652 2.06109 2.23259 2.15224 2.13565ZM2 9.7091V16.7983H4.82987V9.7091H2ZM2 18.7983V24.0782C2 24.2402 2.06109 24.3842 2.15224 24.4811C2.24143 24.576 2.34876 24.6168 2.44665 24.6168H4.82987V18.7983H2ZM6.82987 24.6168H22.9338V2H15.4665H6.82987V24.6168ZM24.9338 2V7.7091H28.9331V2.53855C28.9331 2.37652 28.872 2.23259 28.7808 2.13565C28.6916 2.04079 28.5843 2 28.4864 2H24.9338ZM30.9331 6.92942V2.53855C30.9331 1.88448 30.6893 1.24574 30.2379 0.765616C29.7845 0.283402 29.1559 0 28.4864 0H15.4665H2.44665C1.77719 0 1.1486 0.283402 0.695186 0.765616C0.243736 1.24574 0 1.88448 0 2.53855V24.0782C0 24.7323 0.243736 25.371 0.695186 25.8512C1.1486 26.3334 1.77719 26.6168 2.44665 26.6168H28.4864C29.1559 26.6168 29.7845 26.3334 30.2379 25.8512C30.6893 25.371 30.9331 24.7323 30.9331 24.0782V19.578C30.9562 19.4936 30.9685 19.4048 30.9685 19.3132V17.7983V16.2834C30.9685 16.1918 30.9562 16.103 30.9331 16.0186V10.4888C30.9562 10.4044 30.9685 10.3156 30.9685 10.224V8.7091V7.19423C30.9685 7.10256 30.9562 7.01377 30.9331 6.92942ZM28.9331 9.7091H24.9338V16.7983H28.9331V9.7091ZM28.9331 18.7983H24.9338V24.6168H28.4864C28.5843 24.6168 28.6916 24.576 28.7808 24.4811C28.872 24.3842 28.9331 24.2403 28.9331 24.0782V18.7983ZM12.1305 8.39511C12.4462 8.21825 12.8329 8.22581 13.1415 8.41487L19.1762 12.1119C19.4729 12.2936 19.6538 12.6166 19.6538 12.9646C19.6538 13.3125 19.4729 13.6355 19.1762 13.8173L13.1415 17.5143C12.8329 17.7033 12.4462 17.7109 12.1305 17.534C11.8147 17.3572 11.6191 17.0235 11.6191 16.6616V9.26758C11.6191 8.90565 11.8147 8.57198 12.1305 8.39511ZM13.6191 14.8762L16.7395 12.9646L13.6191 11.0529V14.8762Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.15224 2.13565C2.24143 2.04079 2.34876 2 2.44665 2H4.82987V7.7091H2V2.53855C2 2.37652 2.06109 2.23259 2.15224 2.13565ZM2 9.7091V16.7983H4.82987V9.7091H2ZM2 18.7983V24.0782C2 24.2402 2.06109 24.3842 2.15224 24.4811C2.24143 24.576 2.34876 24.6168 2.44665 24.6168H4.82987V18.7983H2ZM6.82987 24.6168H22.9338V2H15.4665H6.82987V24.6168ZM24.9338 2V7.7091H28.9331V2.53855C28.9331 2.37652 28.872 2.23259 28.7808 2.13565C28.6916 2.04079 28.5843 2 28.4864 2H24.9338ZM30.9331 6.92942V2.53855C30.9331 1.88448 30.6893 1.24574 30.2379 0.765616C29.7845 0.283402 29.1559 0 28.4864 0H15.4665H2.44665C1.77719 0 1.1486 0.283402 0.695186 0.765616C0.243736 1.24574 0 1.88448 0 2.53855V24.0782C0 24.7323 0.243736 25.371 0.695186 25.8512C1.1486 26.3334 1.77719 26.6168 2.44665 26.6168H28.4864C29.1559 26.6168 29.7845 26.3334 30.2379 25.8512C30.6893 25.371 30.9331 24.7323 30.9331 24.0782V19.578C30.9562 19.4936 30.9685 19.4048 30.9685 19.3132V17.7983V16.2834C30.9685 16.1918 30.9562 16.103 30.9331 16.0186V10.4888C30.9562 10.4044 30.9685 10.3156 30.9685 10.224V8.7091V7.19423C30.9685 7.10256 30.9562 7.01377 30.9331 6.92942ZM28.9331 9.7091H24.9338V16.7983H28.9331V9.7091ZM28.9331 18.7983H24.9338V24.6168H28.4864C28.5843 24.6168 28.6916 24.576 28.7808 24.4811C28.872 24.3842 28.9331 24.2403 28.9331 24.0782V18.7983ZM12.1305 8.39511C12.4462 8.21825 12.8329 8.22581 13.1415 8.41487L19.1762 12.1119C19.4729 12.2936 19.6538 12.6166 19.6538 12.9646C19.6538 13.3125 19.4729 13.6355 19.1762 13.8173L13.1415 17.5143C12.8329 17.7033 12.4462 17.7109 12.1305 17.534C11.8147 17.3572 11.6191 17.0235 11.6191 16.6616V9.26758C11.6191 8.90565 11.8147 8.57198 12.1305 8.39511ZM13.6191 14.8762L16.7395 12.9646L13.6191 11.0529V14.8762Z"
      fill="url(#paint0_linear_4267_8072)"
      style="mix-blend-mode: overlay" />
    <defs>
      <linearGradient
        id="paint0_linear_4267_8072"
        x1="7.43244"
        y1="8.51737"
        x2="22.3201"
        y2="21.6915"
        gradientUnits="userSpaceOnUse">
        <stop stop-color="#C27AEE" />
        <stop offset="1" stop-color="#63D5EA" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'av-film-gradient-icon'
};
</script>
