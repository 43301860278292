import icons from './icons';

export default {
  icons: {
    iconfont: 'faSvg',
    values: icons
  },
  theme: {
    themes: {
      light: {
        success: {
          base: '#569673',
          lighten5: '#e4fcec'
        },
        error: {
          base: '#b30000',
          lighten5: '#ffe4e4',
          darken1: '8f0000'
        },
        warning: '#f5eda5',
        white: '#ffffff',
        black: '#000000',
        grey: {
          base: '#212121',
          lighten1: '#313131',
          lighten2: '#414141',
          lighten3: '#717171',
          lighten4: '#919191',
          lighten5: '#c1c1c1',
          lighten6: '#f6f6f6'
        },
        primary: {
          base: '#00447c',
          lighten1: '#1360a0',
          lighten2: '#5089cd',
          lighten3: '#96c9ff',
          lighten4: '#addfff',
          lighten5: '#ebf5ff',
          lighten6: '#f5faff'
        },
        secondary: {
          base: '#80bb3d',
          lighten1: '#86c143',
          lighten2: '#9dd858',
          lighten3: '#bbe987',
          lighten4: '#def9bf',
          lighten5: '#fafff5'
        },
        'accent-primary': {
          base: '#98ded9',
          lighten1: '#f5fffe',
          darken1: '#9de3de',
          darken2: '#81c7c2',
          darken3: '#66aba6',
          darken4: '#4b908c'
        },
        'accent-secondary': {
          base: '#00a8e2',
          lighten1: '#39bcf7',
          lighten2: '#5ad2ff',
          lighten3: '#8ddaf4',
          lighten4: '#c2eefd',
          lighten5: '#f5fcff'
        },
        'accent-tertiary': {
          base: '#9587c5'
        }
      }
    },
    options: {
      customProperties: true
    }
  }
};
