import catalogApi from '@/main/api/catalog';
import { parseLivestream } from '@/main/components/common/Livestreams/utils';
import recommendationApi from '@/main/api/recommendation';
import { ResourceType } from '@/main/models/catalog-item';

const fetch = async (store, options = {}) => {
  const { rootState, dispatch, rootGetters } = store;
  const [result] = await Promise.all([
    catalogApi.fetch(options),
    fetchNasotItems(rootState, dispatch),
    fetchAudiences(rootState, dispatch)
  ]);
  const nasotItems = rootGetters['nasot/nasotItems'];
  const audiences = rootGetters['audiences/audiences'];
  const items = result.items.map(it =>
    parseCatalogItem(it, nasotItems, audiences)
  );
  return { ...result, items };
};

const get = async (store, { publicId } = {}) => {
  const { rootState, dispatch, rootGetters } = store;
  const [data] = await Promise.all([
    catalogApi.get(publicId),
    fetchNasotItems(rootState, dispatch),
    fetchAudiences(rootState, dispatch)
  ]);
  const nasotItems = rootGetters['nasot/nasotItems'];
  const audiences = rootGetters['audiences/audiences'];
  return parseCatalogItem(data, nasotItems, audiences);
};

const fetchRecommended = async store => {
  const { rootState, dispatch, rootGetters } = store;
  const [result] = await Promise.all([
    recommendationApi.fetchRecommended(),
    fetchNasotItems(rootState, dispatch),
    fetchAudiences(rootState, dispatch)
  ]);
  const nasotItems = rootGetters['nasot/nasotItems'];
  const audiences = rootGetters['audiences/audiences'];
  return result.map(it => parseCatalogItem(it, nasotItems, audiences));
};

const fetchRecommendedPlaylists = async (store, options = {}) => {
  const { rootState, dispatch, rootGetters } = store;
  const [result] = await Promise.all([
    recommendationApi.fetchRecommendedPlaylists(options),
    fetchNasotItems(rootState, dispatch),
    fetchAudiences(rootState, dispatch)
  ]);
  const nasotItems = rootGetters['nasot/nasotItems'];
  const audiences = rootGetters['audiences/audiences'];
  return result.map(it => parseCatalogItem(it, nasotItems, audiences));
};

const fetchNew = async store => {
  const { rootState, dispatch, rootGetters } = store;
  const [result] = await Promise.all([
    recommendationApi.fetchNew(),
    fetchNasotItems(rootState, dispatch),
    fetchAudiences(rootState, dispatch)
  ]);
  const nasotItems = rootGetters['nasot/nasotItems'];
  const audiences = rootGetters['audiences/audiences'];
  const items = result.map(it => parseCatalogItem(it, nasotItems, audiences));
  return items;
};

// TODO: this is not being used primarily, just in cases there is not strategy
// in progress for the currently logged user.
const fetchFeatured = async store => {
  const { rootState, dispatch, rootGetters } = store;
  const [result] = await Promise.all([
    recommendationApi.fetchFeatured(),
    fetchNasotItems(rootState, dispatch),
    fetchAudiences(rootState, dispatch)
  ]);
  const nasotItems = rootGetters['nasot/nasotItems'];
  const audiences = rootGetters['audiences/audiences'];
  if (!result) return;
  return parseCatalogItem(result, nasotItems, audiences);
};

const fetchInProgress = async store => {
  const { rootState, dispatch, rootGetters } = store;
  const [result] = await Promise.all([
    recommendationApi.fetchInProgress(),
    fetchNasotItems(rootState, dispatch),
    fetchAudiences(rootState, dispatch)
  ]);
  const nasotItems = rootGetters['nasot/nasotItems'];
  const audiences = rootGetters['audiences/audiences'];
  if (!result) return;
  return result.map(it => parseCatalogItem(it, nasotItems, audiences));
};

export {
  fetch,
  get,
  fetchFeatured,
  fetchRecommended,
  fetchRecommendedPlaylists,
  fetchNew,
  fetchInProgress
};

function parseCatalogItem(data, nasotItems, audiences) {
  const {
    data: typeSpecificFields,
    createdAt,
    nasotItemIds,
    audienceIds,
    catalogItems,
    ...catalogItemFields
  } = data;

  const parsedCatalogItem = {
    ...typeSpecificFields,
    ...catalogItemFields,
    nasotItems: nasotItems.filter(it => nasotItemIds.includes(it.id)),
    audiences: audiences.filter(it => audienceIds.includes(it.id)),
    createdAt: new Date(createdAt)
  };

  if (parsedCatalogItem.resourceType === ResourceType.PLAYLIST) {
    parsedCatalogItem.catalogItems = catalogItems.map(({ item, position }) => ({
      ...parseCatalogItem(item, nasotItems, audiences),
      position
    }));
  }

  return parsedCatalogItem.resourceType === ResourceType.LIVESTREAM
    ? parseLivestream(parsedCatalogItem)
    : parsedCatalogItem;
}

function fetchNasotItems(rootState, dispatch) {
  return !rootState.nasot.initialized
    ? dispatch('nasot/fetch', null, { root: true })
    : Promise.resolve();
}

function fetchAudiences(rootState, dispatch) {
  return !rootState.audiences.initialized
    ? dispatch('audiences/fetch', null, { root: true })
    : Promise.resolve();
}
