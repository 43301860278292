import filter from 'lodash/filter';
import find from 'lodash/find';
import { NasotItemType } from '@/common/models/nasot-item';
import sortBy from 'lodash/sortBy';

export function getParent(nasotItems, nasotItem) {
  const id = nasotItem?.parentId;
  return id && find(nasotItems, { id });
}

export function getAncestors(nasotItems, nasotItem, ancestors = []) {
  const parent = getParent(nasotItems, nasotItem);
  if (!parent) return ancestors;
  return getAncestors(nasotItems, parent, [...ancestors, parent]);
}

export function getChildren(nasotItems, parent) {
  const children = filter(nasotItems, { parentId: parent.id });
  return sortBy(children, 'position');
}

export function toTreeFormat(nasotItems, parentId = null) {
  const sortedNasotItems = sortBy(nasotItems, 'position');
  const parentNasotItems = filter(sortedNasotItems, { parentId });
  return parentNasotItems.map(nasotItem => {
    const children = toTreeFormat(sortedNasotItems, nasotItem.id);
    const locked = nasotItem.type !== NasotItemType.STRATEGY;
    const data = { ...nasotItem, locked };
    return children.length ? { ...data, children } : data;
  });
}
