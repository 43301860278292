<template>
  <!-- `eager` prop ensures that image is in the DOM for e2e tests -->
  <v-img
    v-bind="pick($attrs, ['src', 'contain', 'transition', 'position'])"
    eager
    class="av-img">
    <slot></slot>
  </v-img>
</template>

<script>
import pick from 'lodash/pick';
import { VImg } from 'vuetify/lib';

export default {
  name: 'av-img',
  methods: { pick },
  components: { VImg }
};
</script>
