import {
  faArrowRightFromBracket as farArrowRightFromBracket,
  faArrowUp as farArrowUp,
  faBookmark as farBookmark,
  faBullseyePointer as farBullseyePointer,
  faCameraWeb as farCameraWeb,
  faCheck as farCheck,
  faCircleCheck as farCircleCheck,
  faCircleExclamation as farCircleExclamation,
  faCircleInfo as farCircleInfo,
  faCircleUser as farCircleUser,
  faClock as farClock,
  faClose as farClose,
  faCommentsQuestionCheck as farCommentsQuestionCheck,
  faEllipsisVertical as farEllipsisVertical,
  faEnvelope as farEnvelope,
  faFaceSadTear as farFaceSadTear,
  faGear as farGear,
  faHouseChimney as farHouseChimney,
  faListUl as farListUl,
  faLockKeyhole as farLockKeyhole,
  faReply as farReply,
  faStar as farStar,
  faTableList as farTableList,
  faThumbsUp as farThumbsUp,
  faTriangleExclamation as farTriangleExclamation,
  faVideo as farVideo
} from '@fortawesome/pro-regular-svg-icons';
import {
  faAngleRight as fasAngleRight,
  faArrowLeft as fasArrowLeft,
  faArrowUpRightFromSquare as fasArrowUpRightFromSquare,
  faBars as fasBars,
  faBookmark as fasBookmark,
  faCaretDown as fasCaretDown,
  faCheck as fasCheck,
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faChevronUp as fasChevronUp,
  faCircle as fasCircle,
  faCircleCheck as fasCircleCheck,
  faCircleMinus as fasCircleMinus,
  faCircleUser as fasCircleUser,
  faClose as fasClose,
  faCloudArrowDown as fasCloudArrowDown,
  faCommentDots as fasCommentDots,
  faCreditCard as fasCreditCard,
  faEnvelopeCircleCheck as fasEnvelopeCircleCheck,
  faEnvelopeOpenText as fasEnvelopeOpenText,
  faEye as fasEye,
  faListCheck as fasListCheck,
  faMagnifyingGlass as fasMagnifyingGlass,
  faPlay as fasPlay,
  faSparkles as fasSparkles,
  faStar as fasStar,
  faThumbsUp as fasThumbsUp,
  faTriangle as fasTriangle,
  faUser as fasUser,
  faUsers as fasUsers,
  faVideo as fasVideo,
  faXmark as fasXmark
} from '@fortawesome/pro-solid-svg-icons';
import Book from '@/common/components/icons/Book';
import Chart from '@/common/components/icons/Chart';
import Comment from '@/common/components/icons/Comment';
import Confetti from '@/common/components/icons/Confetti';
import { faCircle as falCircle } from '@fortawesome/pro-light-svg-icons';
import Film from '@/common/components/icons/Film';
import Filter from '@/common/components/icons/Filter';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Gift from '@/common/components/icons/Gift';
import { library } from '@fortawesome/fontawesome-svg-core';
import LightBulb from '@/common/components/icons/LightBulb';
import List from '@/common/components/icons/List';
import Livestream from '@/common/components/icons/Livestream.vue';
import Playlist from '@/common/components/icons/Playlist';
import Saved from '@/common/components/icons/Saved';
import Star from '@/common/components/icons/Star';
import transform from 'lodash/transform';
import User from '@/common/components/icons/User';

const avantiIcons = {
  Book,
  Chart,
  Comment,
  Confetti,
  Film,
  Filter,
  Gift,
  LightBulb,
  List,
  Livestream,
  Playlist,
  Saved,
  Star,
  User
};

const fal = {
  falCircle
};

const far = {
  farArrowRightFromBracket,
  farArrowUp,
  farBookmark,
  farBullseyePointer,
  farCameraWeb,
  farCheck,
  farCircleCheck,
  farCircleExclamation,
  farCircleInfo,
  farCircleUser,
  farClock,
  farClose,
  farCommentsQuestionCheck,
  farEllipsisVertical,
  farEnvelope,
  farFaceSadTear,
  farGear,
  farHouseChimney,
  farListUl,
  farLockKeyhole,
  farReply,
  farStar,
  farTableList,
  farThumbsUp,
  farTriangleExclamation,
  farVideo
};

const fas = {
  fasAngleRight,
  fasArrowLeft,
  fasArrowUpRightFromSquare,
  fasBars,
  fasBookmark,
  fasCaretDown,
  fasCheck,
  fasChevronDown,
  fasChevronLeft,
  fasChevronRight,
  fasChevronUp,
  fasCircle,
  fasCircleCheck,
  fasCircleMinus,
  fasCircleUser,
  fasClose,
  fasCloudArrowDown,
  fasCommentDots,
  fasCreditCard,
  fasEnvelopeOpenText,
  fasEye,
  fasListCheck,
  fasMagnifyingGlass,
  fasPlay,
  fasSparkles,
  fasStar,
  fasThumbsUp,
  fasTriangle,
  fasUser,
  fasUsers,
  fasVideo,
  fasXmark,
  fasEnvelopeCircleCheck
};

library.add(fal, far, fas);

const falSvgIcons = transform(
  fal,
  (acc, component) => {
    acc[`fal-${component.iconName}`] = {
      component: FontAwesomeIcon,
      props: {
        icon: [component.prefix, component.iconName]
      }
    };
  },
  {}
);

const farSvgIcons = transform(
  far,
  (acc, component) => {
    acc[`far-${component.iconName}`] = {
      component: FontAwesomeIcon,
      props: {
        icon: [component.prefix, component.iconName]
      }
    };
  },
  {}
);

const fasSvgIcons = transform(
  fas,
  (acc, component) => {
    acc[`fas-${component.iconName}`] = {
      component: FontAwesomeIcon,
      props: {
        icon: [component.prefix, component.iconName]
      }
    };
  },
  {}
);

const avIcons = transform(
  avantiIcons,
  (acc, component) => {
    acc[component.name] = { component };
  },
  {}
);

export default { ...falSvgIcons, ...farSvgIcons, ...fasSvgIcons, ...avIcons };
