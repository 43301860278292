<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
    <path
      d="M32.959 34.477H2.041a1.518 1.518 0 0 1-1.518-1.518V2.041A1.518 1.518 0 0 1 2.041.523h30.918a1.518 1.518 0 0 1 1.518 1.518v30.918a1.518 1.518 0 0 1-1.518 1.518"
      fill="#fff"></path>
    <path
      d="M32.959 35H2.041A2.043 2.043 0 0 1 0 32.959V2.041A2.043 2.043 0 0 1 2.041 0h30.918A2.043 2.043 0 0 1 35 2.041v30.918A2.043 2.043 0 0 1 32.959 35M2.041 1.046a1 1 0 0 0-1 1v30.913a1 1 0 0 0 1 1h30.918a1 1 0 0 0 1-1V2.041a1 1 0 0 0-1-1Z"
      fill="currentColor"></path>
    <path fill="#fff" d="M.523 6.254h33.954"></path>
    <path
      d="M34.477 6.777H.523a.523.523 0 1 1 0-1.046h33.954a.523.523 0 1 1 0 1.046"
      fill="currentColor"></path>
    <path
      d="M5.078 3.352a1.035 1.035 0 1 1-1.035-1.035 1.035 1.035 0 0 1 1.035 1.035"
      fill="currentColor"></path>
    <path
      d="M8.046 3.352a1.035 1.035 0 1 1-1.035-1.035 1.035 1.035 0 0 1 1.035 1.035"
      fill="currentColor"></path>
    <path
      d="M11.151 3.352a1.035 1.035 0 1 1-1.035-1.035 1.035 1.035 0 0 1 1.035 1.035"
      fill="currentColor"></path>
    <path fill="#fff" d="M5.078 10.812h3.405v3.405H5.078z"></path>
    <path
      d="M8.482 14.739h-3.4a.523.523 0 0 1-.523-.523v-3.4a.523.523 0 0 1 .523-.523h3.4a.523.523 0 0 1 .523.523v3.4a.523.523 0 0 1-.523.523M5.6 13.693h2.359v-2.358H5.601Z"
      fill="currentColor"></path>
    <path fill="#fff" d="M5.078 17.5h3.405v3.405H5.078z"></path>
    <path
      d="M8.482 21.43h-3.4a.523.523 0 0 1-.523-.523v-3.4a.523.523 0 0 1 .523-.523h3.4a.523.523 0 0 1 .523.523v3.4a.523.523 0 0 1-.523.523M5.6 20.384h2.359v-2.361H5.601Z"
      fill="currentColor"></path>
    <path fill="#fff" d="M5.078 24.188h3.405v3.405H5.078z"></path>
    <path
      d="M8.482 28.115h-3.4a.523.523 0 0 1-.523-.523v-3.4a.523.523 0 0 1 .523-.523h3.4a.523.523 0 0 1 .523.523v3.4a.523.523 0 0 1-.523.523M5.6 27.069h2.359V24.71H5.601Z"
      fill="currentColor"></path>
    <path fill="#fff" d="M12.876 10.812h15.459"></path>
    <path
      d="M28.336 11.335h-15.46a.523.523 0 0 1 0-1.046h15.46a.523.523 0 1 1 0 1.046"
      fill="currentColor"></path>
    <path fill="#fff" d="M12.674 6.254h15.459"></path>
    <path
      d="M28.135 6.777H12.674a.523.523 0 1 1 0-1.046h15.461a.523.523 0 1 1 0 1.046"
      fill="currentColor"></path>
    <path fill="#fff" d="M12.876 19.202h15.459"></path>
    <path
      d="M28.336 19.726h-15.46a.523.523 0 0 1 0-1.046h15.46a.523.523 0 1 1 0 1.046"
      fill="currentColor"></path>
    <path fill="#fff" d="M12.876 25.89h15.459"></path>
    <path
      d="M28.336 26.413h-15.46a.523.523 0 0 1 0-1.046h15.46a.523.523 0 1 1 0 1.046"
      fill="currentColor"></path>
  </svg>
</template>

<script>
export default { name: 'av-list-outline-icon' };
</script>
