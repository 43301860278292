import isString from 'lodash/isString';
import SCHEMA from './config';

export function getNasotItemConfig(typeOrInstance) {
  const type = isString(typeOrInstance) ? typeOrInstance : typeOrInstance.type;
  return SCHEMA.find(it => it.type === type);
}

export function getChildrenTypes(typeOrInstance) {
  const config = getNasotItemConfig(typeOrInstance);
  return config.subLevels || [];
}

export function getChildrenConfigs(typeOrInstance) {
  const subLevels = getChildrenTypes(typeOrInstance);
  return SCHEMA.filter(it => subLevels.includes(it.type));
}
