<template>
  <div class="mr-4 mt-md-0">
    <av-btn
      v-if="redirectButtonConfig.hasButton"
      @click="$emit('click:banner-button')"
      :size="mdAndUp ? 'large' : 'small'"
      color="white"
      append-icon="chevron-right">
      {{ redirectButtonConfig.text }}
    </av-btn>
    <av-btn
      v-else
      @click="$emit('click:banner-button')"
      :size="mdAndUp ? 'medium' : 'small'"
      class="mt-1"
      color="white"
      variant="secondary"
      icon>
      <av-icon size="24" name="close" color="white" />
    </av-btn>
  </div>
</template>

<script>
export default {
  name: 'banner-action',
  props: {
    redirectButtonConfig: { type: Object, default: () => ({}) }
  },
  computed: {
    mdAndUp: vm => vm.$vuetify.breakpoint.mdAndUp
  }
};
</script>
