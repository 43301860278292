export const Variant = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TEXT: 'text',
  LINK: 'link'
};
export const Color = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  ACCENT_PRIMARY: 'accent-primary',
  WHITE: 'white',
  BLACK: 'black',
  GREY_LIGHTEN_3: 'grey lighten-3'
};

export const Size = {
  X_SMALL: 'x-small',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  X_LARGE: 'x-large'
};
