<template>
  <v-footer
    :height="appSettings.footerHeight"
    color="primary"
    class="d-flex align-center justify-space-between">
    <footer-logo />
    <footer-routes v-if="smAndUpBreakpoint" />
  </v-footer>
</template>

<script>
import FooterLogo from './Logo';
import FooterRoutes from './Routes';
import { VFooter } from 'vuetify/lib';

export default {
  name: 'app-footer',
  inject: {
    appSettings: {
      from: 'appSettings',
      default: { footerHeight: 80 }
    }
  },
  computed: {
    smAndUpBreakpoint: vm => vm.$vuetify.breakpoint.smAndUp
  },
  components: { FooterLogo, FooterRoutes, VFooter }
};
</script>
