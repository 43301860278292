<template>
  <v-input :hide-details="$attrs.hideDetails">
    <v-chip
      v-model="value"
      :value="$attrs.value"
      :disabled="$attrs.disabled"
      :ripple="false"
      :aria-label="label"
      :aria-checked="checked"
      tag="div"
      role="checkbox"
      pill
      outlined
      label
      filter
      class="av-pill-checkbox">
      <label aria-hidden="true">{{ label }}</label>
    </v-chip>
  </v-input>
</template>

<script>
import { VChip, VInput } from 'vuetify/lib';

export default {
  name: 'av-pill-checkbox',
  model: {
    prop: 'inputValue'
  },
  props: {
    label: { type: String, default: '' }
  },
  data: () => ({ checked: false }),
  computed: {
    value: {
      get: vm => vm.$attrs.inputValue,
      set(value) {
        this.checked = value;
        return this.$emit('input', value);
      }
    }
  },
  watch: {
    '$props.inputValue'(val) {
      this.checked = val;
    }
  },
  components: { VChip, VInput }
};
</script>

<style lang="scss" scoped>
.av-pill-checkbox.v-chip--pill.v-chip {
  width: 100%;
  min-height: 3rem;
  border-color: var(--v-grey-lighten5);
  white-space: initial;

  &::before {
    opacity: 0;
  }

  :deep(.v-chip__content) {
    color: var(--v-grey-lighten1);
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1rem;
    text-align: left;

    .v-chip__filter.v-icon {
      width: 1.5rem;
      margin: 0;
      font-size: 1.125rem;

      &::after {
        position: relative;
        padding-right: 1.5rem - 1.125rem;
      }
    }

    .v-chip__filter.v-icon svg {
      height: 1.125rem;
    }

    label {
      cursor: pointer;
    }
  }

  &.v-chip:not(.v-chip--active) {
    background-color: var(--v-white-base) !important;
  }

  &.v-chip:not(.v-chip--active):hover {
    background-color: var(--v-primary-lighten6) !important;
  }

  &.v-chip--active {
    background-color: var(--v-primary-lighten5) !important;
  }

  &:focus,
  &:hover,
  &.v-chip--active {
    border-color: var(--v-primary-base);

    :deep(.v-chip__content) {
      color: var(--v-primary-base);
    }
  }

  &:focus {
    box-shadow: 0 0 0.375rem rgba(0, 68, 124, 0.5);
  }
}
</style>
