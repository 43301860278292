import api from '@/main/api/customer';
import { NOT_FOUND } from 'http-status';

export const fetchSubscriptions = ({ state, commit }, params) => {
  if (!state.currentFetch) {
    commit('startFetch', fetch(commit, params));
  }
  return state.currentFetch;
};

function fetch(commit, params) {
  return api
    .getSubscriptions(params)
    .then(subscriptions => commit('setSubscriptions', subscriptions))
    .catch(err => {
      if (err?.response?.status !== NOT_FOUND) return;
      commit('setSubscriptions', []);
    });
}
