<template>
  <v-avatar
    v-bind="{ size, color }"
    :class="{ 'has-elevation': hasElevation }"
    class="av-avatar">
    <av-img v-if="imgUrl" :src="imgUrl" alt="Profile photo" />
    <av-icon
      v-else
      name="user"
      icon-style="outline"
      size="100%"
      color="grey lighten-4"
      class="pt-2" />
  </v-avatar>
</template>

<script>
import { VAvatar } from 'vuetify/lib';

export default {
  name: 'av-avatar',
  props: {
    imgUrl: { type: String, default: null },
    color: { type: String, default: 'grey lighten-4' },
    size: { type: Number, default: null },
    hasElevation: { type: Boolean, default: false }
  },
  components: { VAvatar }
};
</script>

<style lang="scss">
.av-avatar.has-elevation {
  box-shadow: $elevation-15;
  border: 2px solid var(--v-white-base);

  .v-image {
    background-color: var(--v-white-base);
  }
}
</style>
