export const set = (state, payload = {}) => {
  const {
    firstName = '',
    lastName = '',
    fullName = '',
    email = '',
    country = '',
    state: usState = '',
    completedOnboarding = false,
    isOrganizationMember = false,
    organization = null,
    interestIds = [],
    audienceId = null,
    bookmarks = [],
    experienceLevel = null,
    subscriptionStart = '',
    subscriptionEnd = ''
  } = payload;
  state.firstName = firstName;
  state.lastName = lastName;
  state.fullName = fullName;
  state.email = email;
  state.country = country;
  state.state = usState;
  state.completedOnboarding = completedOnboarding;
  state.isOrganizationMember = isOrganizationMember;
  state.organization = organization;
  state.interestIds = interestIds;
  state.audienceId = audienceId;
  state.experienceLevel = experienceLevel;
  state.bookmarks = bookmarks;
  state.subscriptionStart = subscriptionStart;
  state.subscriptionEnd = subscriptionEnd;
  state.initialized = true;
};

export const createBookmark = (state, bookmark) => {
  state.bookmarks = [...state.bookmarks, bookmark];
};

export const deleteBookmark = (state, catalogItemId) => {
  const findByCatalogItemId = it => it.catalogItemId !== catalogItemId;
  state.bookmarks = state.bookmarks.filter(findByCatalogItemId);
};
