<template>
  <div
    :class="{
      small,
      'is-focused': isFocused,
      'has-error': hasErrors,
      'is-disabled': disabled
    }"
    class="av-textarea">
    <label v-if="label" :for="id" class="label">{{ label }}</label>
    <v-textarea
      @focus="isFocused = true"
      @blur="isFocused = false"
      v-on="$listeners"
      v-bind="{ id, value, name, placeholder, disabled, errorMessages }"
      :append-icon="innerIcon"
      :autofocus="autofocus"
      :class="{ 'error-visible': errorMessages.length }"
      outlined
      no-resize
      persistent-placeholder
      class="textarea" />
  </div>
</template>

<script>
import { computed, toRefs } from 'vue';
import cuid from 'cuid';
import useFontawesomeIcon from '@/common/composables/useFontawesomeIcon';
import { VTextarea } from 'vuetify/lib';

export default {
  name: 'av-textarea',
  props: {
    value: { type: String, default: undefined },
    label: { type: String, default: undefined },
    name: { type: String, default: undefined },
    placeholder: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    errorMessages: { type: [Array, String], default: () => [] },
    appendIcon: { type: String, default: undefined },
    appendIconStyle: { type: String, default: 'solid' },
    autofocus: { type: Boolean, default: false },
    small: { type: Boolean, default: false }
  },
  setup(props) {
    const { appendIcon, appendIconStyle, errorMessages } = toRefs(props);
    const hasErrors = computed(() => errorMessages.value.length);
    const { innerIcon } = useFontawesomeIcon(
      appendIcon,
      appendIconStyle,
      hasErrors
    );
    return { hasErrors, innerIcon };
  },
  data: () => ({ isFocused: false }),
  computed: {
    id: () => `input-${cuid()}`
  },
  components: { VTextarea }
};
</script>

<style lang="scss" scoped>
@import './input';

.av-textarea {
  :deep(.v-input__control) {
    @extend %av-input;

    .v-input__slot {
      height: 11.625rem;
      margin-bottom: 0;
      padding: 0 0.75rem 0 0 !important;

      textarea {
        margin: 0.75rem;
        padding: 0;
        color: var(--v-grey-base);
      }

      textarea::placeholder {
        color: var(--v-grey-lighten4);
        opacity: 1;
      }
    }

    .v-text-field__details {
      display: none;
      margin-top: 0.5rem;
      margin-bottom: 0;
    }
  }

  .label {
    display: block;
    margin-bottom: 0.375rem;

    @extend %av-input-label;
  }

  &.is-focused {
    .label {
      color: var(--v-primary-lighten1);
    }

    :deep(.v-input__slot) {
      @extend %av-input-focus;
    }
  }

  &.has-error {
    .label {
      color: var(--v-error-base);
    }

    :deep(.v-input__slot) {
      @extend %av-input-error-text;
    }
  }

  &.is-disabled {
    .label {
      color: var(--v-grey-lighten5);
    }

    :deep(.v-input__control) .v-input__slot {
      @extend %av-input-disabled;
    }
  }

  &.small :deep(.v-input__control) .v-input__slot {
    height: 8rem;
  }

  .textarea {
    &.error-visible :deep(.v-text-field__details) {
      display: block;
    }

    :deep(.v-input__control) .v-input__slot:hover {
      background: var(--v-primary-lighten6);
    }
  }
}
</style>
