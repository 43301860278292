<template>
  <v-app-bar
    :height="appSettings.navbarHeight"
    color="primary"
    app
    fixed
    dark
    class="app-bar">
    <div class="navbar-container">
      <slot></slot>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: 'base-navbar',
  inject: {
    appSettings: {
      from: 'appSettings',
      default: { navbarHeight: 80 }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/common/assets/stylesheets/utils/z-index';

.app-bar {
  @include z-index(app-bar);
}

.navbar-container {
  width: 100%;
}
</style>
