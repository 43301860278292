<template>
  <div class="d-flex align-center white--text">
    <av-icon
      :size="iconSize"
      name="clock"
      icon-style="regular"
      color="white"
      class="mr-2" />
    {{ humanizedDuration }}
  </div>
</template>

<script>
import { humanizeDuration } from '@/common/utils/formatters';

export default {
  name: 'video-duration',
  props: {
    duration: { type: Number, default: null },
    size: { type: Number, default: 20 }
  },
  computed: {
    iconSize: vm => (vm.$vuetify.breakpoint.smAndUp ? vm.size : 14),
    humanizedDuration: vm =>
      vm.duration ? humanizeDuration(vm.duration) : '--/--'
  }
};
</script>
