var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"av-tabs-container"},[_c('div',{class:{
      'flex-column flex-md-row d-sm-flex justify-space-between mb-10':
        _vm.prefixContent
    }},[_vm._t("prefix-content"),_c('v-tabs',_vm._b({staticClass:"av-tabs white",attrs:{"slider-size":"2"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},'v-tabs',{
        centered: _vm.centered,
        height: _vm.height,
        right: _vm.right
      },false),_vm._l((_vm.internalTabs),function({
          id,
          name,
          label,
          prependIcon,
          prependIconStyle,
          iconSize
        }){return _c('v-tab',{key:id,staticClass:"av-tab px-4",style:(_vm.tabWidth && { width: `${_vm.tabWidth}px` }),attrs:{"id":id,"to":_vm.nestedRoutes ? { name } : undefined,"active-class":"primary--text"}},[_c('div',{staticClass:"d-flex align-center my-4"},[(prependIcon)?_c('av-icon',{staticClass:"mr-2",attrs:{"name":prependIcon,"icon-style":prependIconStyle,"size":iconSize || 16}}):_vm._e(),_c('span',{staticClass:"body-1 text-capitalize"},[_vm._v(_vm._s(label))])],1),_c('av-divider',{staticClass:"grey lighten-5",attrs:{"border-width":1}})],1)}),1)],2),(_vm.nestedRoutes)?_c('div',{staticClass:"tab-content-wrapper",attrs:{"data-testid":"scroll-tab"}},[_c('router-view',{staticClass:"tab-content"})],1):_c('v-tabs-items',{model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.internalTabs),function({ id, component, ...data }){return _c('v-tab-item',{key:id,attrs:{"aria-labelledby":id,"role":"tabpanel"}},[_c(component,_vm._b({tag:"component"},'component',{ id, ...data },false))],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }