import Dashboard from '@/main/components/Dashboard';
import get from 'lodash/get';
import Home from '@/main/components';
import LivestreamDashboard from '@/main/components/LivestreamDashboard';
import MyAccount from '@/main/components/MyAccount';
import NotFound from '@/common/components/NotFound';
import { numeric as numericParser } from '@/common/utils/params-parser';
import { restoreScrollPosition } from '@/common/navigation';
import { Role } from '@/common/models/user';
import Router from 'vue-router';
import store from './store';
import Vue from 'vue';
import yn from 'yn';

const { VUE_APP_TEST_MODE } = process.env;
const isTestMode = yn(VUE_APP_TEST_MODE);

Vue.use(Router);

// Handle 404
const fallbackRoute = { path: '*', name: 'notFound', component: NotFound };

const parsePlayVideoQuery = ({ query }) => ({ playVideo: yn(query.playVideo) });

const scrollBehavior = (to, from, savedPosition) => {
  if (to.path !== from.path) return restoreScrollPosition(savedPosition);
};

const router = new Router({
  mode: 'history',
  scrollBehavior,
  routes: [
    ...(isTestMode
      ? [
          {
            path: '/login',
            component: () => import('@/main/components/Login'),
            meta: { anonOnly: true }
          }
        ]
      : []),
    {
      path: '/register',
      name: 'register',
      redirect: { path: '/' },
      meta: { anonOnly: true }
    },
    {
      path: '/reveal',
      name: 'explore',
      component: () => import('@/main/components/Explore'),
      meta: { anonOnly: true }
    },
    {
      path: '/personalize',
      name: 'personalize',
      component: () => import('@/main/components/Personalize'),
      meta: { auth: true, authorize: [Role.DEMO] }
    },
    {
      path: '/email-verification',
      name: 'emailVerification',
      component: () => import('@/main/components/EmailVerification'),
      props: ({ query }) => ({ token: query['verification-token'] })
    },
    {
      path: '/payment',
      name: 'payment',
      component: () => import('@/main/components/Payment'),
      meta: { auth: true, authorize: [Role.USER] }
    },
    {
      path: '/expired-organization-subscription',
      name: 'expiredOrgSubscription',
      component: () =>
        import('@/main/components/ExpiredOrganizationSubscription'),
      meta: { auth: true, authorize: [Role.USER] }
    },
    {
      path: '/onboarding',
      name: 'onboarding',
      component: () => import('@/main/components/Onboarding'),
      meta: { auth: true, authorize: [Role.USER] }
    },
    {
      path: '/',
      // TODO Rename Home component to something more appropriate
      component: Home,
      meta: { auth: true },
      props: parsePlayVideoQuery,
      children: [
        {
          path: '',
          name: 'dashboard',
          component: Dashboard,
          meta: { authorize: [Role.USER, Role.DEMO], featureBanner: true }
        },
        {
          path: '/community',
          name: 'community',
          beforeEnter(_to, _from, _next) {
            window.open(process.env.VUE_APP_COMMUNITY_URL, '_blank');
          }
        },
        {
          path: '/livestreams',
          name: 'livestreams',
          component: LivestreamDashboard,
          meta: { authorize: [Role.USER, Role.DEMO], featureBanner: true }
        },
        {
          path: '/livestreams/:publicId',
          name: 'livestream',
          component: () =>
            import(
              /* webpackPrefetch: true */
              '@/main/components/Livestream'
            ),
          props: route => ({
            ...numericParser.params(route),
            publicId: route.params.publicId
          }),
          meta: { authorize: [Role.USER, Role.DEMO], featureBanner: true }
        },
        {
          path: '/catalog',
          name: 'catalog',
          component: () =>
            import(
              /* webpackPrefetch: true */
              '@/main/components/Catalog'
            ),
          meta: { authorize: [Role.USER, Role.DEMO], featureBanner: true }
        },
        {
          path: '/learning-objects/:publicId',
          name: 'learningObject',
          component: () =>
            import(
              /* webpackPrefetch: true */
              '@/main/components/LearningObject'
            ),
          props: route => ({
            ...parsePlayVideoQuery(route),
            ...numericParser.params(route),
            publicId: route.params.publicId
          }),
          meta: { authorize: [Role.USER, Role.DEMO], featureBanner: true }
        },
        {
          path: '/learning-objects/:id/preview',
          name: 'learningObjectPreview',
          component: () => import('@/main/components/LearningObject/Preview'),
          props: route => ({
            ...parsePlayVideoQuery(route),
            ...numericParser.params(route)
          }),
          meta: { authorize: [Role.ADMIN] }
        },
        {
          path: '/playlists/:publicId',
          name: 'playlist',
          component: () =>
            import(
              /* webpackPrefetch: true */
              '@/main/components/Playlist'
            ),
          props: route => ({
            ...numericParser.params(route),
            publicId: route.params.publicId
          }),
          meta: { authorize: [Role.USER, Role.DEMO] }
        },
        {
          path: '/my-account',
          name: 'myAccount',
          component: MyAccount,
          children: [
            {
              path: 'profile',
              name: 'profile',
              component: () =>
                import('@/main/components/MyAccount/ManageProfile'),
              meta: { authorize: [Role.USER] }
            },
            {
              path: 'my-learning',
              name: 'myLearning',
              component: () => import('@/main/components/MyAccount/MyLearning'),
              props: route => ({
                queryParams: {
                  ...route.query,
                  limit: Number(route.query.limit),
                  offset: Number(route.query.offset)
                }
              }),
              meta: { authorize: [Role.USER] }
            },
            {
              path: 'billing',
              name: 'billing',
              component: () =>
                import('@/main/components/MyAccount/ManageSubscription'),
              meta: { authorize: [Role.USER] }
            }
          ]
        }
      ]
    },
    fallbackRoute
  ]
});

router.beforeEach(async (to, from, next) => {
  if (!Vue.$auth.loading) return guard(to, from, next);
  Vue.$auth.$watch('loading', loading => !loading && guard(to, from, next));
});

export default router;

async function guard(to, from, next) {
  const user = get(store.state, 'auth.user');
  const isAdmin = user?.role === Role.ADMIN;
  const isDemoUser = user?.role === Role.DEMO;
  const isAnonOnlyRoute = to.matched.some(it => it.meta.anonOnly);

  if (!user) return beforeAnonNavigation(to, from, next);
  if (isAnonOnlyRoute) return preventNavigation(to, from, next);
  if (isAdmin) return beforeAdminNavigation(to, from, next, user);
  if (isDemoUser) return beforeDemoNavigation(to, from, next, user);
  return beforeUserNavigation(to, from, next, user);
}

function beforeAnonNavigation(to, _, next) {
  const isEmailVerificationRoute = to.name === 'emailVerification';
  if (isEmailVerificationRoute) {
    const verificationToken = to.query['verification-token'];
    if (!verificationToken) return next({ name: 'notFound' });
  }
  const isAuthRoute = to.matched.some(it => it.meta.auth);
  const login = () => {
    if (Vue.$auth.isAuthenticated) return next();
    Vue.$auth.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  if (isAuthRoute) return login();
  return next();
}

function beforeAdminNavigation(to, _, next, user) {
  if (!isAuthorized(to, user)) return window.location.assign('/admin');
  return next();
}

async function beforeDemoNavigation(to, from, next, user) {
  const isEmailVerificationRoute = to.name === 'emailVerification';
  if (isEmailVerificationRoute) return next();
  if (!isAuthorized(to, user)) return preventNavigation(to, from, next);
  const { profile } = store.state;
  if (!profile.initialized) await store.dispatch('profile/fetch');
  const isPersonalizeRoute = to.name === 'personalize';
  const redirectToPersonalize = () => next({ name: 'personalize' });
  if (!isPersonalizeRoute && !isOnboardingCompleted())
    return redirectToPersonalize();
  return next();
}

async function beforeUserNavigation(to, from, next, user) {
  const { hasAccess, isOrgMember } = await checkAccess(user);
  if (isPaywallRoute(to))
    return handlePaywallNavigation(to, next, hasAccess, isOrgMember);
  if (!hasAccess) return redirectToPaywall(next, isOrgMember);
  if (!isOnboardingRoute(to) && !isOnboardingCompleted())
    return redirectToOnboarding(next);
  if (!isAuthorized(to, user)) return preventNavigation(to, from, next);
  return next();
}

async function checkAccess(user) {
  const { profile, payment } = store.state;
  if (!profile.initialized) await store.dispatch('profile/fetch');
  if (user.hasFreeAccess) return { isOrgMember: false, hasAccess: true };
  if (!payment.initialized) {
    await store.dispatch('payment/fetchSubscriptions');
  }
  const activeSubscription = store.getters['payment/activeSubscription'];
  return {
    isOrgMember: !!profile.isOrganizationMember,
    hasAccess: !!activeSubscription
  };
}

function isPaywallRoute(to) {
  return ['payment', 'expiredOrgSubscription'].includes(to.name);
}

function redirectToPaywall(next, isOrgMember) {
  return isOrgMember
    ? next({ name: 'expiredOrgSubscription' })
    : next({ name: 'payment' });
}

function handlePaywallNavigation(to, next, hasAccess, isOrgMember) {
  if (hasAccess) return next({ name: 'dashboard' });
  if (isOrgMember && to.name === 'expiredOrgSubscription') return next();
  if (!isOrgMember && to.name === 'payment') return next();
  return next({ name: isOrgMember ? 'expiredOrgSubscription' : 'payment' });
}

function isOnboardingCompleted() {
  const { profile } = store.state;
  return profile.completedOnboarding;
}

function isOnboardingRoute(to) {
  return to.name === 'onboarding';
}

function redirectToOnboarding(next) {
  return next({ name: 'onboarding' });
}

// TODO: Redirect the user to the 404 page instead of preventing
// navigation and showing no feedback to the user
function preventNavigation(_, from, next) {
  return next({ path: from.fullPath });
}

function isAuthorized(route, user) {
  return route.matched.every(
    it => !it.meta?.authorize || it.meta.authorize.includes(user.role)
  );
}
