<template>
  <div class="d-flex flex-column flex-grow-1 ml-3">
    <div class="d-flex justify-space-between align-start">
      <item-info v-if="!isMobile" v-bind="itemInfoData.strand" class="mr-4" />
      <item-info v-bind="itemInfoData.audience" class="mr-4" />
      <video-duration
        :duration="learningObject.video.duration"
        :size="iconSize" />
    </div>
    <div
      class="d-flex mt-2 flex-column flex-md-row align-start align-md-center">
      <v-progress-linear
        :value="learningObject.progress"
        aria-label="Strategy progress"
        class="d-flex flex-shrink-1 mr-5"
        background-color="primary lighten-5"
        color="secondary"
        height="6"
        rounded />
      <div class="task-completion">
        <item-info v-bind="itemInfoData.tasks" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import ItemInfo from './ItemInfo';
import { NasotItemType } from '@/common/models/nasot-item';
import useTasksProgress from '@/main/composables/useProgressTasks';
import VideoDuration from '@/main/components/common/VideoDuration';

const props = defineProps({
  learningObject: { type: Object, required: true },
  iconSize: { type: Number, required: true },
  isMobile: { type: Boolean, default: false }
});

const { completedTasksCount, tasksCount } = useTasksProgress(
  props.learningObject.id
);

const tasksCompleted = computed(
  () => `${completedTasksCount.value}/${tasksCount.value} tasks completed`
);
const strand = computed(() =>
  props.learningObject.nasotItems.find(it => it.type === NasotItemType.STRAND)
);
const audience = computed(() => {
  if (!props.learningObject.audiences.length) return 'All Audiences';
  return props.learningObject.audiences[0].name;
});
const itemInfoData = computed(() => ({
  strand: {
    text: strand.value.name,
    icon: {
      name: 'bullseye-pointer',
      'icon-style': 'regular',
      color: 'white',
      size: props.iconSize
    }
  },
  audience: {
    text: audience.value,
    icon: {
      name: 'circle-user',
      'icon-style': 'solid',
      color: 'white',
      size: props.iconSize
    }
  },
  tasks: {
    text: tasksCompleted.value,
    icon: {
      name: 'list-check',
      'icon-style': 'solid',
      color: 'rgba(255, 255, 255, 0.70)',
      size: props.iconSize
    }
  }
}));
</script>

<style lang="scss" scoped>
.task-completion {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  align-items: center;
  color: var(--v-white-base);
  opacity: 50%;
}
</style>
