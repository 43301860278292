<template>
  <div class="d-flex flex-column flex-grow-1">
    <upgrade-banner v-if="isDemoUser" />
    <av-navigation
      v-if="isNavigationShown"
      :class="{ 'navigation-demo': isDemoUser }" />
    <feature-banner v-if="showFeatureBanner" v-bind="featureBannerProps" />
    <v-container
      :class="{
        'pt-0': !isNavigationShown || showFeatureBanner,
        'main-container-demo': isDemoUser
      }"
      class="main-container d-flex flex-column flex-grow-1 pb-0 px-0"
      fluid>
      <router-view />
    </v-container>
    <app-footer />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import AppFooter from '@/main/components/common/Footer';
import AvNavigation from './common/Navigation';
import FeatureBanner from '@/main/components/common/FeatureBanner';
import UpgradeBanner from '@/main/components/common/UpgradeBanner';
import useFeatureBanner from '@/main/composables/useFeatureBanner';

export default {
  name: 'home',
  props: {
    playVideo: { type: Boolean, default: false }
  },
  setup() {
    const { showBanner, featureBannerProps, initFeatureBannerState } =
      useFeatureBanner();

    initFeatureBannerState();

    return { showBanner, featureBannerProps };
  },
  data: () => ({ showDialog: false }),
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('profile', ['isOrganizationMember']),
    ...mapGetters('auth', ['isDemoUser']),
    isNavigationShown: vm => !vm.playVideo,
    isMyAccountPage: vm => vm.$route.path.startsWith('/my-account'),
    showPaymentDialog: vm => vm.showDialog && !vm.isMyAccountPage,
    showUpgradeBanner: vm => !vm.playVideo && vm.isDemoUser,
    showFeatureBanner() {
      const isOnRoute = this.$route.meta.featureBanner;
      return isOnRoute && this.showBanner && !this.playVideo;
    }
  },
  components: {
    AppFooter,
    AvNavigation,
    FeatureBanner,
    UpgradeBanner
  }
};
</script>

<style lang="scss" scoped>
.navigation-demo {
  :deep(.v-app-bar.v-app-bar--fixed) {
    top: var(--upgrade-banner-height);
  }

  :deep(.av-sidebar .v-navigation-drawer__content .sidebar-list) {
    margin-top: 8.125rem !important;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      margin-top: 6.25rem !important;
    }
  }
}

.main-container {
  padding-top: var(--navbar-height);
  background-color: var(--v-grey-lighten6);

  &-demo {
    padding-top: calc(var(--navbar-height) + var(--upgrade-banner-height));
  }
}
</style>
