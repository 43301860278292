import '@/polyfills';
import '@/common/validation';
import {
  AvAccordion,
  AvAccordionGroup,
  AvControlledAccordion
} from '@/common/components/base/Accordion';
import {
  setInteractionMode,
  ValidationObserver,
  ValidationProvider
} from 'vee-validate';
import { ABTestingVuePlugin } from '@/common/plugins/abTesting';
import App from './App';
import { AuthPlugin } from '@/common/plugins/auth';
import AvAvatar from '@/common/components/base/Avatar';
import AvBtn from '@/common/components/base/Btn';
import AvCard from '@/common/components/base/Card';
import AvCheckbox from '@/common/components/base/inputs/Checkbox';
import AvChip from '@/common/components/base/Chip';
import AvCircularProgress from '@/common/components/base/CircularProgress';
import AvCollapseTransition from '@/common/components/base/CollapseTransition';
import AvConfirmationDialog from '@/common/components/base/ConfirmationDialog';
import AvDate from '@/common/components/base/Date';
import AvDialog from '@/common/components/base/Dialog';
import AvDivider from '@/common/components/base/Divider';
import AvIcon from '@/common/components/base/Icon';
import AvImg from '@/common/components/base/Img';
import AvLinearProgress from '@/common/components/base/LinearProgress';
import AvLoading from '@/common/components/base/Loading';
import AvMenu from '@/common/components/base/Menu';
import AvOverlay from '@/common/components/base/Overlay';
import AvPillCheckbox from '@/common/components/base/inputs/PillCheckbox';
import AvPillCheckboxGroup from '@/common/components/base/inputs/PillCheckboxGroup';
import AvRating from '@/common/components/base/Rating';
import AvSelect from '@/common/components/base/inputs/Select';
import AvSkeletonLoader from '@/common/components/base/SkeletonLoader';
import AvSnackbar from '@/common/components/base/Snackbar';
import AvTabs from '@/common/components/base/Tabs';
import AvTextarea from '@/common/components/base/inputs/Textarea';
import AvTextField from '@/common/components/base/inputs/TextField';
import FocusLoop from '@vue-a11y/focus-loop';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import LoadScript from 'vue-plugin-load-script';
import logger from '@/common/api/log';
import PrimaryLogo from '@/common/components/icons/PrimaryLogo';
import router from './router';
import store from './store';
import UpgradeDialog from '@/main/components/common/UpgradeDialog';
import VBodyScrollLock from 'v-body-scroll-lock';
import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import VueHead from 'vue-head';
import vuetify from '@/main/vuetify';
import VueVisible from 'vue-visible';

Vue.component(AvMenu.name, AvMenu);
Vue.component(AvSnackbar.name, AvSnackbar);
Vue.component(AvSkeletonLoader.name, AvSkeletonLoader);
Vue.component(AvCollapseTransition.name, AvCollapseTransition);
Vue.component(AvRating.name, AvRating);
Vue.component(AvOverlay.name, AvOverlay);
Vue.component(AvDate.name, AvDate);
Vue.component(AvTabs.name, AvTabs);
Vue.component(AvDialog.name, AvDialog);
Vue.component(AvConfirmationDialog.name, AvConfirmationDialog);
Vue.component(AvCircularProgress.name, AvCircularProgress);
Vue.component(AvLoading.name, AvLoading);
Vue.component(AvLinearProgress.name, AvLinearProgress);
Vue.component(AvAvatar.name, AvAvatar);
Vue.component(AvBtn.name, AvBtn);
Vue.component(AvCard.name, AvCard);
Vue.component(AvDivider.name, AvDivider);
Vue.component(AvCheckbox.name, AvCheckbox);
Vue.component(AvChip.name, AvChip);
Vue.component(AvIcon.name, AvIcon);
Vue.component(AvImg.name, AvImg);
Vue.component(AvSelect.name, AvSelect);
Vue.component(AvTextField.name, AvTextField);
Vue.component(AvTextarea.name, AvTextarea);
Vue.component(AvPillCheckbox.name, AvPillCheckbox);
Vue.component(AvPillCheckboxGroup.name, AvPillCheckboxGroup);
Vue.component(AvControlledAccordion.name, AvControlledAccordion);
Vue.component(AvAccordion.name, AvAccordion);
Vue.component(AvAccordionGroup.name, AvAccordionGroup);
Vue.component(PrimaryLogo.name, PrimaryLogo);
Vue.component(UpgradeDialog.name, UpgradeDialog);
Vue.component('validation-observer', ValidationObserver);
Vue.component('validation-provider', ValidationProvider);
Vue.component('font-awesome-icon', FontAwesomeIcon);

setInteractionMode('passive');
const trackedViews = ['register', 'verify-account'];
const ignoredViews = router
  .getRoutes()
  .map(route => route.name)
  .filter(name => !trackedViews.includes(name));
const { VUE_APP_GTM_ID } = process.env;

Vue.use(LoadScript);

const { VUE_APP_AUTH0_LEARNER_CLIENT_ID } = process.env;

Vue.use(AuthPlugin, {
  store,
  router,
  clientId: VUE_APP_AUTH0_LEARNER_CLIENT_ID
});

// TODO Is this used anywhere?
Vue.use(VueVisible);
Vue.use(VBodyScrollLock);
Vue.use(FocusLoop);
Vue.use(VueHead);
if (VUE_APP_GTM_ID) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    vueRouter: router,
    ignoredViews
  });
}

const { VUE_APP_AB_TESTING_CLIENT_KEY } = process.env;
if (VUE_APP_AB_TESTING_CLIENT_KEY) {
  Vue.use(ABTestingVuePlugin, { clientKey: VUE_APP_AB_TESTING_CLIENT_KEY });
}

// eslint-disable-next-line no-new
new Vue({
  router,
  store,
  vuetify,
  el: '#app',
  render: h => h(App),
  provide() {
    return {
      $logger: logger
    };
  }
});
