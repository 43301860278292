<template>
  <section class="upcoming-livestreams">
    <h2 class="mb-9 mb-md-12 text-h3 text-center">Upcoming Livestreams</h2>
    <div :class="{ 'justify-center': items.length > 2 }" class="grid container">
      <livestream-card
        v-for="item in items"
        :key="item.id"
        :livestream="item" />
    </div>
  </section>
</template>

<script>
import LivestreamCard from '@/main/components/common/cards/LivestreamCard';

export default {
  name: 'upcoming-livestreams',
  props: {
    items: { type: Array, default: () => [] }
  },
  components: { LivestreamCard }
};
</script>

<style lang="scss" scoped>
.upcoming-livestreams {
  padding: 1.75rem 1.75rem;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    padding: 3.5rem 5.5rem;
  }

  .container {
    max-width: map-get($container-max-widths, 'xl');
    margin: auto;
  }

  .grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      grid-template-columns: repeat(auto-fit, minmax(min-content, 25rem));
      grid-gap: 2rem;
    }
  }
}
</style>
